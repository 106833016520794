import React from 'react';
import styled from 'styled-components';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Section } from '@components';
import {
    cat_1,
    cat_2,
    cat_3,
    cat_4,
    cat_5,
    cat_6,
    cat_7,
    cat_8,
} from '@assets';
import { useNavigate } from 'react-router-dom';
import { devices } from '@styles';

const StyledMasonry = styled(Masonry)`
gap: 40px !important;

    >div {
        gap: 40px !important;
    }

@media screen and (max-width: ${devices.laptopL}) {
    gap: 30px !important;

    >div {
        gap: 30px !important;
    }
}

@media screen and (max-width: ${devices.laptop}) {
    gap: 30px !important;

    >div {
        gap: 30px !important;
    }
}

@media screen and (max-width: ${devices.tablet}) {
    gap: 20px !important;

    >div {
        gap: 20px !important;
    }
}

@media screen and (max-width: ${devices.mobileM}) {
    gap: 20px !important;

    >div {
        gap: 20px !important;
    }
}

@media screen and (max-width: ${devices.mobileS}) {
    gap: 10px !important;

    >div {
        gap: 10px !important;
    }
}
`;

const Title = styled.div`
padding: 30px;
width: ${props => props.mode === 'home' ? '100%' : 'calc(100% - 60px)'};

position: absolute;
bottom: 0;
background: linear-gradient(360deg, black, transparent);

font-size: 24px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #fff;
text-align: left;

@media screen and (max-width: ${devices.laptopL}) {
width: ${props => props.mode === 'home' ? '100%' : 'calc(100% - 48px)'};
padding: 24px;
font-size: 18px;
}

@media screen and (max-width: ${devices.laptop}) {
width: ${props => props.mode === 'home' ? '100%' : 'calc(100% - 36px)'};
padding: 18px;
font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
width: ${props => props.mode === 'home' ? '100%' : 'calc(100% - 20px)'};
padding: 10px;
font-size: 14px;
}

@media screen and (max-width: ${devices.mobileM}) {
width: ${props => props.mode === 'home' ? '100%' : 'calc(100% - 20px)'};
padding: 10px;
font-size: 14px;
}
`;

const Image = styled.img.attrs({
    alt: 'img',
})`
width: 100%;
object-fit: cover;
aspect-ratio: ${props => props.size === 'small' ? 1 / 0.8 : 1};
transition: transform 0.5s ease;

:hover {
    transform: scale(1.3);
}
`;

const ImageWrapper = styled.div`
position: relative;
overflow: hidden;
cursor: pointer;
background: #000;
transition: transform 0.5s ease;

:hover {

    ${Title} {
        text-decoration: underline;
    }

    ${Image} {
        transform: scale(1.3);
    }
}
`;

const data = [
    {
        key: '3',
        image: cat_1,
        title: 'Ancient Beads',
        is_small: 0,
    },
    {
        key: '7',
        image: cat_8,
        title: 'Private Collections',
        is_small: 1,
    },
    {
        key: '1',
        image: cat_7,
        title: 'Painting Arts',
        is_small: 0,
    },
    {
        key: '5',
        image: cat_6,
        title: 'Handbags & Accessories',
        is_small: 1,
    },
    {
        key: '2',
        image: cat_4,
        title: 'Gems & Jewellery Arts',
        is_small: 1,
    },
    {
        key: '4',
        image: cat_3,
        title: 'Decorative Arts & Objects',
        is_small: 0,
    },
    {
        key: '8',
        image: cat_5,
        title: 'Handicraft Arts',
        is_small: 1,
    },
    {
        key: '6',
        image: cat_2,
        title: 'Books & Manuscripts',
        is_small: 0,
    },
];

const breakpoints = {
    0: 2,
    577: 4,
};

export const HomeCategories = ({
    mode,
    handleExtraAction = () => { },
}) => {
    const navigate = useNavigate();

    const handleClicked = item => {
        navigate(`/products/${item.key}`);
        handleExtraAction();
    }

    return (
        <ResponsiveMasonry columnsCountBreakPoints={breakpoints}>
            <StyledMasonry>
                {data.map((item, key) => (
                    <ImageWrapper
                        key={key}
                        onClick={() => handleClicked(item)}
                    >
                        <Image
                            src={item.image}
                            size={item.is_small && 'small'}
                        />
                        <Title mode={mode}>
                            {item.title}
                        </Title>
                    </ImageWrapper>
                ))}
            </StyledMasonry>
        </ResponsiveMasonry>
    );
}

const Categories = () => {

    return (
        <Section
            title='Our Categories'
            showMore={false}
        >
            <HomeCategories mode='home' />
        </Section>
    )
}

export default Categories;