import React from 'react';
import {
    Col,
    Row,
} from 'antd';
import {
    Topbar,
    ItemCard,
    Pagination,
} from '@components';
import {
    RootContainer,
    Bottom,
    EmptyCard,
} from './ProductList.style';
import Loader from './Loader/Loader';

const responsive = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 40,
};

const ProductList = ({
    title,
    data,
    loading,
    pagination,
}) => {

    const renderTop = () => (
        <Topbar title={title} />
    );

    const renderBottom = () => (
        <Bottom>
            <Pagination {...pagination} />
        </Bottom>
    );

    if (!loading && data.length === 0) {
        return (
            <>
                {renderTop()}
                <RootContainer>
                    <EmptyCard>
                        No Product Yet.
                    </EmptyCard>
                </RootContainer>
            </>
        );
    }

    return (
        <>
            {renderTop()}
            <RootContainer>
                <Row>
                    <Col xs={1} sm={2} />
                    <Col xs={22} sm={20}>
                        <Row gutter={[responsive, responsive]}>
                            {loading && <Loader />}
                            {!loading &&
                                data.map((item, key) => (
                                    <Col key={key} xs={12} md={8} lg={6}>
                                        <ItemCard
                                            key={key}
                                            product_id={item.id}
                                            item_code={item.productCode}
                                            image={item.firstImage}
                                            title={item.title}
                                            price={item.price}
                                            tag={item.period}
                                            product_status={item.productStatus}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    <Col xs={1} sm={2} />
                </Row>
                <Row>
                    <Col xs={1} />
                    <Col xs={22}>
                        {data.length !== 0 && renderBottom()}
                    </Col>
                    <Col xs={1} />
                </Row>
            </RootContainer>
        </>
    );
}

export default ProductList;