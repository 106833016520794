import React from 'react';
import {
    BackText,
    Title,
    TitleWrapper,
} from '../styles';
import { Logo } from '@styles';
import {
    Button,
    Form,
    Input,
} from 'antd';

const ForgotPassword = ({
    goToOtp,
    goToLogin,
}) => {

    const onFinish = () => {
        goToOtp('forgot-password');
    }

    const renderTop = () => (
        <TitleWrapper>
            <Logo />
            <Title>
                Forgot Password?
            </Title>
        </TitleWrapper>
    );

    return (
        <>
            {renderTop()}
            <Form onFinish={onFinish}>
                <Form.Item
                    name='email'
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                        {
                            type: 'email',
                            message: 'Invalid email format!'
                        }
                    ]}
                >
                    <Input placeholder='Email' />
                </Form.Item>
                <Form.Item>
                    <Button
                        type='primary'
                        size='large'
                        htmlType='submit'
                    >
                        Continue
                    </Button>
                    <BackText onClick={goToLogin}>
                        Back
                    </BackText>
                </Form.Item>
            </Form>
        </>
    );
}

export default ForgotPassword;