import React from 'react';
import {
    RootContainer,
    Loader,
    TitleText,
} from './styles';
import { Col, Row, Spin } from 'antd';
import MediaView from './MediaView/MediaView';
import ConditionReport from './ConditionReport/ConditionReport';
import ProductInfo from './ProductInfo/ProductInfo';
import ShippingInfo from './ShippingInfo/ShippingInfo';
import Section from './Section/Section';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_ROUTE } from '@router/route.constants';

const responsive = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 50,
};

const ProductDetails = ({
    data,
    loading,
}) => {
    const navigate = useNavigate();

    const goToArtistDetials = () => {
        data.artist?.id && navigate(`/${PUBLIC_ROUTE.ARTIST_DETAILS}/${data.artist?.id}`);
    }

    if (loading) {
        return (
            <Loader>
                <Spin />
            </Loader>
        )
    }

    const renderLeft = () => (
        <Row gutter={[responsive, responsive]}>
            <Col xs={24}>
                <MediaView images={data.images} />
            </Col>
            <Col xs={24}>
                <TitleText>
                    {data.title}
                </TitleText>
            </Col>
            <Col xs={24} id='small-col'>
                <ProductInfo
                    product_code={data.productCode}
                    price={data.price}
                    short_description={data.description}
                    product_id={data.id}
                    title={data.title}
                    image={data.images && data.images[0]}
                    sub_category={data.subCategories[0]?.name}
                    product_status={data.productStatus}
                />
            </Col>
            <Col xs={24}>
                <ConditionReport condition={data.condition} />
            </Col>
            <Col xs={24}>
                <Section
                    title={'Provenance'}
                    content={data.provenence}
                />
            </Col>
            <Col xs={24}>
                <Section
                    title={'Lot Essay'}
                    content={data.lotEssay}
                />
            </Col>
        </Row>
    );

    const renderRight = () => (
        <Row gutter={[responsive, responsive]}>
            <Col xs={24} id='lg-col'>
                <ProductInfo
                    product_code={data.productCode}
                    price={data.price}
                    short_description={data.description}
                    product_id={data.id}
                    title={data.title}
                    image={data.images && data.images[0]}
                    sub_category={data.subCategories[0]?.name}
                    product_status={data.productStatus}
                />
            </Col>
            {data.artist &&
                <Col xs={24}>
                    <Section
                        title={'Artist'}
                        content={data.artist?.name}
                        fontSize='large'
                        handleClick={goToArtistDetials}
                    />
                </Col>
            }
            {data.age &&
                <Col xs={24}>
                    <Section
                        title={'Age'}
                        content={data.age}
                        fontSize='large'
                    />
                </Col>
            }
            {data.shipping_info &&
                <Col xs={24}>
                    <ShippingInfo />
                </Col>
            }
        </Row >
    );

    return (
        <RootContainer>
            <Row gutter={[responsive, responsive]}>
                <Col
                    xs={24}
                    lg={15}
                    xl={14}
                >
                    {renderLeft()}
                </Col>
                <Col
                    xs={24}
                    lg={9}
                    xl={10}
                >
                    {renderRight()}
                </Col>
            </Row>
        </RootContainer>
    );
}

export default ProductDetails;