import store, { sessionExpiredAction } from '@redux';
import { localStorage } from '@utils';
import {
  saveToken,
  saveUsername,
  saveCartCount,
  saveWishListCount,
  setApiStatus,
  saveCategories,
} from '@redux/auth/authSlice';
import { api, Status, } from '@api';

const initializeApp = () => {
  api.init({ sessionExpiredAction });
};

const checkAuthorization = () => {
  const token = localStorage.getToken();
  const username = localStorage.getUsername();
  const cartCount = localStorage.getCartCount();
  const wishListCount = localStorage.getWishListCount();
  if (token) {
    // save token and username to redux
    store.dispatch(saveToken(token));
    store.dispatch(saveUsername(username));
    store.dispatch(saveCartCount(parseInt(cartCount)));
    store.dispatch(saveWishListCount(parseInt(wishListCount)));

    // set token to request obj
    api.setSessionToken(token);
  }
};

const getCategories = () => {
  setApiStatus(Status.LOADING);
  api
    .getCategories()
    .then((response) => {
      setApiStatus(Status.SUCCESS);
      saveCategories(response);
    })
    .catch((error) => {
      setApiStatus(Status.ERROR);
      console.log(error);
    });
}

const boot = () =>
  new Promise(() => {
    initializeApp();
    checkAuthorization();
    getCategories();
  });

export default boot;
