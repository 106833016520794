import React from 'react';
import styled from 'styled-components';
import { Title } from '@styles';
import {
    Menu as AntMenu,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { devices } from '@styles';

const Menu = styled(AntMenu)`
padding-top: 30px;
border: none !important;

.ant-menu-item {
    height: auto;
    padding: 5px 20px !important;
    margin-bottom: 16px;

    font-size: 22px;
    font-weight: normal;
    color: #000000;

    background: #F9F8EB;
    border-radius: 0;
}

.ant-menu-item-active {
    font-weight: 500;
    background: ${props => props.theme.secondaryColor} !important;
}

.ant-menu-item-selected {
    font-weight: 500;
    background: ${props => props.theme.secondaryColor} !important;

    ::after {
        width: 35px;
        height: 100%;
        background-color: #371A30;

        clip-path: polygon(100% 100%, 100% 0, 0 50%);
    }
}

@media screen and (max-width: ${devices.laptopL}) {
    padding-top: 20px;

    .ant-menu-item {
        height: auto;
        padding: 0 10px !important;
        margin-bottom: 10px;

        font-size: 16px;
    }

    .ant-menu-item-selected {
    
        ::after {
            width: 30px;
        }
    }
}
`;

const Sider = ({
    category_id,
    items,
}) => {
    const navigate = useNavigate();

    const handleChange = ({ key }) => {
        navigate(`/products/${key}`, {
            replace: true,
        });
    }

    return (
        <>
            <Title>
                Our Categories
            </Title>
            <Menu
                mode='inline'
                selectedKeys={[category_id]}
                items={items}
                onClick={handleChange}
            />
        </>
    );
}

export default Sider;