import React from 'react';
import {
    Title,
    TitleWrapper,
} from '../styles';
import { Logo } from '@styles';
import {
    Button,
    Form,
    Input,
} from 'antd';

const ChangePassword = ({
    goToSuccess,
}) => {

    const onFinish = () => {
        goToSuccess();
    }

    const renderTop = () => (
        <TitleWrapper>
            <Logo />
            <Title>
                Please create a new password
            </Title>
        </TitleWrapper>
    );

    return (
        <>
            {renderTop()}
            <Form onFinish={onFinish}>
                <Form.Item
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                >
                    <Input.Password placeholder='Password' />
                </Form.Item>
                <Form.Item
                    name='confirm-password'
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                >
                    <Input.Password placeholder='Confirm Password' />
                </Form.Item>
                <Form.Item>
                    <Button
                        type='primary'
                        size='large'
                        htmlType='submit'
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default ChangePassword;