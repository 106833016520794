import styled from "styled-components";
import {
    Modal as AntModal,
} from 'antd';
import { devices } from '@styles';

export const Modal = styled(AntModal)`

.ant-modal-content {
    padding: 32px 70px;
    border-radius: 0;
}

.ant-modal-close {
    width: 35px;
    height: 35px;

    svg {
        font-size: 35px;
        color: #371A30;
    }
}

.ant-input {
    height: 52px;
    font-size: 16px !important;
}

.ant-input-affix-wrapper {
    padding: 0 11px !important;
}

.ant-btn-primary {
    height: 52px;
    width: 100%;
    
    font-size: 18px;
    color: #fff;
}

.ant-form-item {
    margin-bottom: 32px;
}

@media screen and (max-width: ${devices.laptopL}) {
width: 500px !important;

    .ant-modal-content {
        padding: 30px 50px;
        border-radius: 0;
    }
    
    .ant-modal-close {
        width: 26px;
        height: 26px;

        svg {
            font-size: 26px;
            color: #371A30;
        }
    }

    .ant-input {
        height: 40px;
        font-size: 14px !important;
    }

    .ant-btn-primary {
        height: 40px;
        width: 100%;
        
        font-size: 15px;
        color: #fff;
    }

    .ant-form-item {
        margin-bottom: 20px;
    }
}
`;

export const TitleWrapper = styled.div`
padding-bottom: 52px;
text-align: center;

img {
    width: 225px;
}

@media screen and (max-width: ${devices.laptopL}) {
    padding-bottom: 30px;

    img {
        width: 200px;
    }
}
`;

export const Title = styled.div`
padding-top: 30px;

font-size: 24px;
font-weight: 500;
color: #371A30;

@media screen and (max-width: ${devices.laptopL}) {
padding-top: 20px;
font-size: 20px;
}
`;

export const RightText = styled.div`
padding-top: 16px;

font-size: 16px;
font-weight: normal;
color: #000;
text-align: right;

    span {
        cursor: pointer;
        display: inline;
    }

@media screen and (max-width: ${devices.laptopL}) {
    padding-top: 8px;
    font-size: 14px;
}
`;

export const Text = styled.span`
font-size: 16px;
font-weight: normal;
color: #000;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 14px;
}
`;

export const SuccessText = styled.div`
padding-top: 16px;
font-size: 20px;
font-weight: normal;
color: #000;
`;

export const Link = styled.span`
font-size: 16px;
font-weight: 600;
color: #371A30;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 14px;
}
`;

export const LinkWrapper = styled.div`
padding-top: 16px;
text-align: center;

@media screen and (max-width: ${devices.laptopL}) {
padding-top: 8px;
}
`;

export const BottomText = styled.div`
padding-top: 20px;

font-size: 16px;
font-weight: normal;
color: #707070;
text-align: center;

@media screen and (max-width: ${devices.laptopL}) {
padding-top: 16px;
font-size: 14px;
}
`;

export const BackText = styled.div`
padding-top: 8px;
font-size: 16px;
font-weight: 600;
color: #371A30;
cursor: pointer;
text-decoration: underline;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 14px;
}
`;