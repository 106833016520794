import styled from "styled-components";
import { devices } from '@styles';
import { DeleteOutlined } from '@assets';
import { Divider as AntDivider } from "antd";

export const RootContainer = styled.div`
padding: 80px 0;

@media screen and (max-width: ${devices.laptopL}) {
padding: 40px 0;
}

@media screen and (max-width: ${devices.laptop}) {
padding: 30px 0;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 25px 0;
}
`;

export const Title = styled.div`
padding-bottom: 12px;
text-align: center;
font-size: 18px;
font-weight: bold;
color: #000;
`;

export const SpinWrapper = styled.div`
text-align: center;
`;

export const Item = styled.div`
padding: 32px;
width: 100%;
display: flex;
align-items: center;
gap: 24px;
border-bottom: 1px solid #B5B5B5;

@media screen and (max-width: ${devices.laptopL}) {
  padding: 20px;
  margin-top: 12px;
  gap: 20px;
}
`;

export const Wrapper = styled.div`
display: flex;
flex: 0.6;
gap: 24px;
align-items: center;
`;

export const Image = styled.img.attrs({
    alt: 'img'
  })`
  width: 80px;
  height: 80px;
  object-fit: cover;
  `;
  
  export const ItemTitle = styled.div`
  font-size: 18px;
  font-weight: normal;
  color: #5A5A5A;
  
  @media screen and (max-width: ${devices.laptopL}) {
  font-size: 16px;
  }
  `;
  
  export const PriceText = styled.div`
  flex: 0.1;
  font-size: 18px;
  font-weight: normal;
  color: #000000;
  text-align: center;
  
  @media screen and (max-width: ${devices.laptopL}) {
  font-size: 16px;
  }
  `;
  
  export const DeleteIcon = styled(DeleteOutlined)`
  width: 26px;
  margin-right: 20px;
  cursor: pointer;
  position: absolute;
 right: 0;
margin-top: -5px;
  
  @media screen and (max-width: ${devices.laptopL}) {
  width: 20px;
  }
  `;
  
export const QtyBox = styled.div`
flex: 0.15;
text-align: center;
`;

export const TotalText = styled.div`
 flex: 0.15;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  
  @media screen and (max-width: ${devices.laptopL}) {
  font-size: 16px;
  }
`;

export const Bottom = styled.div`
width: 100%;
text-align: right;
padding-top: 32px;

.ant-space {
  gap: 0 !important;
}

.ant-btn {
  margin-top: 32px;
  background-color: rgb(241, 174, 86) !important;
  border: none !important;
  color: #000 !important;
  font-weight: bold;
  font-size: 14px !important;
}
`;

export const BottomItem = styled.div`
font-size: 17px;
font-weight: bold;
`;

export const Divider = styled(AntDivider)`
margin: 32px 0 !important;
border-block-start: 1px solid #B5B5B5 !important;
`;

export const TitleWrappr = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
`;

export const RedText = styled.div`
padding: 3px 6px;

background-color: red;
font-size: 14px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #fff;
text-transform: uppercase;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 13px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 13px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 12px;
}
`;