import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { api } from '@api';
import { LIMITS } from '@constants';
import {
    Col,
    Row,
} from 'antd';
import {
    Topbar,
    ItemCard,
    Pagination,
} from '@components';
import {
    RootContainer,
    Bottom,
    EmptyCard,
} from './News.style';
import moment from 'moment';

const {
    DEFAULT_PAGE,
    PAGE_SIZE,
} = LIMITS;

const responsive = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 40,
};

const Loader = () => {
    return (
        <>
            {new Array(PAGE_SIZE).fill(0).map((_, key) => (
                <Col key={key} xs={12} md={8} lg={6}>
                    <ItemCard loading={true} item_type='news' />
                </Col>
            ))}
        </>
    );
}

const News = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getAllNews(page);

        // eslint-disable-next-line
    }, [page]);

    const getAllNews = (page) => {
        setLoading(true);
        api
            .getAllNews({
                page,
                size: PAGE_SIZE,
            })
            .then(({ content, totalElements }) => {
                setLoading(false);
                setData(content);
                setTotal(totalElements);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const handlePageChange = page => {
        setPage(page);
    }

    const renderTop = () => (
        <Topbar title={'News'} />
    );

    const renderBottom = () => (
        <Bottom>
            <Pagination
                current={page}
                pageSize={PAGE_SIZE}
                total={total}
                onChange={handlePageChange}
            />
        </Bottom>
    );

    if (!loading && data.length === 0) {
        return (
            <>
                {renderTop()}
                <RootContainer>
                    <EmptyCard>
                        No News Yet.
                    </EmptyCard>
                </RootContainer>
            </>
        );
    }

    return (
        <>
            {renderTop()}
            <RootContainer>
                <Row>
                    <Col xs={1} sm={2} />
                    <Col xs={22} sm={20}>
                        <Row gutter={[responsive, responsive]}>
                            {loading && <Loader />}
                            {!loading &&
                                data.map((item, key) => (
                                    <Col key={key} xs={12} md={8} lg={6}>
                                        <ItemCard
                                            key={key}
                                            new_id={item.id}
                                            image={item.images}
                                            title={item.title}
                                            tag={`${moment(item.publishDate).format('DD MMMM YYYY')
                                                } | ${item.author}`}
                                            item_type='news'
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    <Col xs={1} sm={2} />
                </Row>
                <Row>
                    <Col xs={1} />
                    <Col xs={22}>
                        {data.length !== 0 && renderBottom()}
                    </Col>
                    <Col xs={1} />
                </Row>
            </RootContainer>
        </>
    );
}

export default News;