import React from 'react';
import { Title } from '@styles';
import {
    SectionWrapper,
    Text,
} from '../styles';

const Section = ({
    title,
    content,
    fontSize = 'normal',
    handleClick = () => { },
}) => {
    return (
        <SectionWrapper>
            <Title size='small'>
                {title}
            </Title>
            <Text fontSize={fontSize} onClick={handleClick}>
                {content}
            </Text>
        </SectionWrapper>
    );
}

export default Section;