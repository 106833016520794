import styled from "styled-components";
import { Steps as AntSteps, Space } from 'antd';
import { devices } from '@styles';

export const Loader = styled.div`
width: 100%;
height: 500px;
display: flex;
align-items: center;
justify-content: center;
`;

export const RootContainer = styled.div`
padding-top: 80px;

#small-col {
  display: none;
}

@media screen and (max-width: ${devices.laptopL}) {
  padding-top: 50px;
}

@media screen and (max-width: ${devices.laptop}) {
  padding-top: 35px;
}

@media screen and (max-width: ${devices.tablet}) {
  padding-top: 25px;

  #small-col {
    display: block;
  }

  #lg-col {
    display: none;
  }
}

@media screen and (max-width: ${devices.mobileM}) {
  padding-top: 20px;
}
`;

export const Text = styled.div`
font-size: ${props => props.fontSize === 'large' ? '20px' : '18px'};
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: 2;
white-space: pre-wrap;
text-align: justify;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: ${props => props.fontSize === 'large' ? '18px' : '16px'};
}

@media screen and (max-width: ${devices.laptop}) {
  font-size: ${props => props.fontSize === 'large' ? '16px' : '14px'};
}

@media screen and (max-width: ${devices.tablet}) {
  font-size: ${props => props.fontSize === 'large' ? '14px' : '13px'};
}

@media screen and (max-width: ${devices.mobileM}) {
  font-size: ${props => props.fontSize === 'large' ? '14px' : '13px'};
}
`;

export const RedText = styled.div`
padding: 6px;

background-color: red;
font-size: 16px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: 1;
color: #fff;
text-transform: uppercase;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 14px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 13px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 12px;
}
`;

export const InfoBox = styled.div`
display: flex;
flex-direction: column;
grid-gap: 30px;

.ant-btn {
    padding: 20px;
    height: 100%;
    
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    color: #000000;
}

.ant-btn-primary {
    background-color: ${props => props.theme.secondaryColor};

    :hover {
        background-color: ${props => props.theme.secondaryColor};
        color: #000000;
    }
}

@media screen and (max-width: ${devices.laptopL}) {
  grid-gap: 20px;

  .ant-btn {
    font-size: 20px;
    padding: 10px;
  }
}

@media screen and (max-width: ${devices.laptop}) {
  grid-gap: 18px;

  .ant-btn {
    font-size: 18px;
    padding: 8px;
  }
}

@media screen and (max-width: ${devices.tablet}) {
  grid-gap: 16px;

  .ant-btn {
    font-size: 16px;
    padding: 8px;
  }
}

@media screen and (max-width: ${devices.mobileM}) {
  grid-gap: 13px;

  .ant-btn {
    font-size: 16px;
    padding: 8px;
  }
}
`;

export const LightText = styled.div`
font-size: 20px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #F1AE56;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: 18px;
}

@media screen and (max-width: ${devices.laptop}) {
  font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
  font-size: 14px;
}

@media screen and (max-width: ${devices.mobileM}) {
  font-size: 13px;
}
`;

export const BoldText = styled.div`
font-size: 36px;
font-weight: bold;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #000000;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: 30px;
}

@media screen and (max-width: ${devices.laptop}) {
  font-size: 28px;
}

@media screen and (max-width: ${devices.tablet}) {
  font-size: 25px;
}

@media screen and (max-width: ${devices.mobileM}) {
  font-size: 20px;
}
`;

export const TitleText = styled.div`
font-size: 30px;
font-weight: bold;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #000;
white-space: pre-wrap;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: 28px;
}

@media screen and (max-width: ${devices.laptop}) {
  font-size: 27px;
}

@media screen and (max-width: ${devices.tablet}) {
  font-size: 24px;
}

@media screen and (max-width: ${devices.mobileM}) {
  font-size: 20px;
}
`;

export const GrayText = styled.div`
padding-bottom: 20px;

font-size: 24px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #707070;
white-space: pre-wrap;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: 20px;
  padding-bottom: 10px;
}

@media screen and (max-width: ${devices.laptop}) {
  font-size: 18px;
}

@media screen and (max-width: ${devices.tablet}) {
  font-size: 16px;
}

@media screen and (max-width: ${devices.mobileM}) {
  font-size: 14px;
}
`;

export const GrayTextSmall = styled(GrayText)`
padding-bottom: 0;
font-size: 18px;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: 16px;
}

@media screen and (max-width: ${devices.laptop}) {
  font-size: 14px;
}

@media screen and (max-width: ${devices.tablet}) {
  font-size: 13px;
}

@media screen and (max-width: ${devices.mobileM}) {
  font-size: 12px;
}
`;

export const SlideWrapper = styled.div`
.ant-image, img {
    object-fit: cover !important;
    aspect-ratio: 1 / 0.7;
}
`;

export const MediaViewContainer = styled.div`

.anticon {
   font-size: 60px;
   color: #fff;
   background-color: #000;
   border-radius: 50%;
   border: none;
}

.anticon-left-circle {
left: 16px !important;
}

.anticon-right-circle {
right: 16px !important;
}

@media screen and (max-width: ${devices.laptopL}) {
  .anticon {
    font-size: 40px;
  }
}

@media screen and (max-width: ${devices.laptop}) {
  .anticon {
    font-size: 30px;
  }
}
`;

export const ImagesWrapper = styled.div`
height: 100%;
display: grid;
grid-template-rows: repeat(5, 20%);
`;

export const ImageSm = styled.img.attrs({
  alt: 'simg',
  className: 'small-img'
})`
width: 100% !important;
height: calc(100% - 24px);
margin: 12px 0;
aspect-ratio: 1 / 0.7;
object-fit: cover;
cursor: pointer;
border: ${props => props.status === 'active' ? '4px solid #f1ae56' : 'none'};

@media screen and (max-width: ${devices.tablet}) {
height: calc(100% - 12px);
margin: 6px 0;
}

@media screen and (max-width: ${devices.mobileM}) {
height: calc(100% - 6px);
margin: 3px 0;
}
`;

export const Steps = styled(AntSteps)`
padding-top: 15px;

.ant-steps-item:not(.ant-steps-item-finish) {

  .ant-steps-item-icon{
    background-color: #C4C4C4 !important;
  }

  .ant-steps-icon {
    display: none;
  }
}

.ant-steps-item-tail::after {
  height: 4px;
  background-color: #C4C4C4 !important;
}

.anticon-star {
  font-size: 26px !important;
  color: ${props => props.theme.secondaryColor};
}

.ant-steps-item-finish .ant-steps-item-title {
  color: ${props => props.theme.secondaryColor} !important;
}

.ant-steps-item-finish .ant-steps-item-content {
  margin-top: 8px !important;
}

.ant-steps-item-title {
  font-size: 18px !important;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #371A30 !important;
}

@media screen and (max-width: ${devices.laptopL}) {
  .ant-steps-item-title {
    font-size: 16px !important;
  }

  .ant-steps-item-tail::after {
    height: 3px;
  }
}

@media screen and (max-width: ${devices.laptop}) {
  .ant-steps-item-title {
    font-size: 15px !important;
  }

  :not(.ant-steps-vertical) {
    .ant-steps-item-content {
      width: 80px !important;
    }

    .ant-steps-item-icon {
      margin-inline-start: 28px !important;
    }

    .ant-steps-item-tail {
      margin-inline-start: 40px !important;
    }
  }
}

@media screen and (max-width: ${devices.tablet}) {
  padding-top: 10px;

  .ant-steps-item-title {
    font-size: 14px !important;
  }
}

@media screen and (max-width: ${devices.mobileM}) {
  padding-top: 5px;

  .ant-steps-item-title {
    font-size: 13px !important;
  }
}
`;

export const ShippingInfoContainer = styled.div`
display: flex;
flex-direction: column;
grid-gap: 30px;


@media screen and (max-width: ${devices.laptopL}) {
  grid-gap: 25px;
}

@media screen and (max-width: ${devices.laptop}) {
  grid-gap: 20px;
}

@media screen and (max-width: ${devices.tablet}) {
  grid-gap: 15px;
}

@media screen and (max-width: ${devices.mobileM}) {
  grid-gap: 12px;
}
`;

export const VerticalSteps = styled(AntSteps)`

.ant-steps-item-title {
font-size: 20px !important;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  color: #000000 !important;
}

.ant-steps-item-description {
padding-bottom: 30px !important;

font-size: 18px !important;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #707070 !important;   
}


@media screen and (max-width: ${devices.laptopL}) {

  .ant-steps-item-title {
    font-size: 18px !important;
  }

  .ant-steps-item-description {
    padding-bottom: 22px !important;

    font-size: 16px !important;
  }
}

@media screen and (max-width: ${devices.laptop}) {
  .ant-steps-item-title {
    font-size: 16px !important;
  }

  .ant-steps-item-description {
    padding-bottom: 20px !important;

    font-size: 14px !important;
  }
}

@media screen and (max-width: ${devices.tablet}) {
  .ant-steps-item-title {
    font-size: 15px !important;
  }

  .ant-steps-item-description {
    padding-bottom: 18px !important;

    font-size: 13px !important;
  }
}

@media screen and (max-width: ${devices.mobileM}) {
  .ant-steps-item-icon {
    margin-inline-end: 8px !important;
  }

  .ant-steps-item-title {
    font-size: 14px !important;
  }

  .ant-steps-item-description {
    padding-bottom: 14px !important;

    font-size: 12px !important;
  }
}
`;

export const SectionWrapper = styled(Space).attrs({
  size: 'large',
  direction: 'vertical',
})`

@media screen and (max-width: ${devices.laptopL}) {
  gap: 16px !important;
}

@media screen and (max-width: ${devices.laptop}) {
  gap: 14px !important;
}
`;