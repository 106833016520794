import React from 'react';
import { Modal as AntModal, Descriptions, } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

const Modal = styled(AntModal)`

.ant-modal-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.ant-modal-content {
    border-radius: 0 !important;
}

.ant-modal-body {
    font-size: 16px;
    font-weight: normal;
    color: #5A5A5A;
    padding: 12px 0;
}
`;

const DetailsModal = ({
    item,
    open,
    handleClose,
}) => {

    return (
        <Modal
            centered
            destroyOnClose
            footer={false}
            open={open}
            title='Order Details'
            onCancel={handleClose}
        >
            <Descriptions bordered column={1}>
                <Descriptions.Item label='Order Date'>
                    {moment(item.orderDate).format('DD MMM, YYYY hh:mm A')}
                </Descriptions.Item>
                <Descriptions.Item label='Order Status'>
                    {item.orderStatus}
                </Descriptions.Item>
                <Descriptions.Item label='Total Amount'>
                    {`$${item.cartDTO?.totalPrice}`}
                </Descriptions.Item>
                <Descriptions.Item label='Total Items'>
                    {item.cartDTO?.cartItemCount}
                </Descriptions.Item>
                <Descriptions.Item label='Delivery Type'>
                    {item.shippingInfo?.delieverType}
                </Descriptions.Item>
                <Descriptions.Item label='Email'>
                    {item.shippingInfo?.email}
                </Descriptions.Item>
                <Descriptions.Item label='Phone Number'>
                    {item.shippingInfo?.phoneNumber}
                </Descriptions.Item>
                <Descriptions.Item label='Address'>
                    {item.shippingInfo?.address}
                </Descriptions.Item>
                <Descriptions.Item label='City'>
                    {item.shippingInfo?.city}
                </Descriptions.Item>
                <Descriptions.Item label='Country'>
                    {item.shippingInfo?.country}
                </Descriptions.Item>
                <Descriptions.Item label='Postal Code'>
                    {item.shippingInfo?.postalCode}
                </Descriptions.Item>
            </Descriptions>
        </Modal>
    );
}

export default DetailsModal;