import React, { useEffect, useState } from 'react';
import {
    Breadcrumb,
} from '@components';
import {
    RootContainer,
    Title,
    Image,
    DateText,
    Text,
    SmallDateText,
    SmallTitle,
    InfoWrapper,
    RightBox,
    Divider,
} from './NewDetails.style';
import {
    Row,
    Col,
    message,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '@api';
import { Title as RightTitle } from '@styles';
import moment from 'moment';
import RightLoader from './RightLoader/RightLoader';
import LeftLoader from './LeftLoader/LeftLoader';
import { PUBLIC_ROUTE } from '@router/route.constants';

const responsive = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 50,
};

const responsive_sm = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 40,
};

const ITEM_COUNT = 4;

const NewDetails = () => {
    const navigate = useNavigate();
    const { new_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [latest, setLatest] = useState([]);
    const [rightLoading, setRightLoading] = useState(true);

    useEffect(() => {
        getNewDetails();
        getLatestNews();

        // eslint-disable-next-line
    }, [new_id]);

    const getNewDetails = () => {
        setLoading(true);
        api
            .getNewDetails(new_id)
            .then((response) => {
                setLoading(false);
                setData(response);
            })
            .catch(error => {
                setLoading(false);
                message.error(error);
            });
    }

    const getLatestNews = () => {
        setRightLoading(true);
        api
            .getLatestNews(ITEM_COUNT)
            .then((response) => {
                setRightLoading(false);
                setLatest(response.filter(item => item.id !== parseInt(new_id)));
            })
            .catch(error => {
                message.error(error);
            });
    }

    const handleItemClicked = id => {
        navigate(`/${PUBLIC_ROUTE.NEW_DETAILS}/${id}`);
    }

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb
                items={[{
                    title: 'News',
                },
                {
                    title: 'Information',
                }]}
            />
        );
    };

    const Details = () => (
        <>
            <Title>{data.title}</Title>
            <Image src={`data:image/png;base64,${data.images}`} />
            <DateText>
                {moment(data.publishDate).format('DD MMMM YYYY')} | {data.author}
            </DateText>
            <Text>
                {data.description}
            </Text>
        </>
    );

    const Right = () => (
        <>
            <RightTitle size='small'>
                Recent News
            </RightTitle>
            <RightBox gutter={responsive_sm}>
                {latest.map((item) => (
                    <>
                        <Col xs={12}>
                            <Image
                                size='small'
                                src={`data:image/png;base64,${item.images}`}
                                onClick={() => handleItemClicked(item.id)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InfoWrapper onClick={() => handleItemClicked(item.id)}>
                                <SmallDateText>
                                    {moment(item.publishDate).format('DD MMMM YYYY')}
                                </SmallDateText>
                                <SmallTitle>
                                    {item.title}
                                </SmallTitle>
                            </InfoWrapper>
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                    </>
                ))}
            </RightBox>
        </>
    )

    return (
        <>
            {renderBreadcrumb()}
            <RootContainer>
                <Row>
                    <Col xs={1} sm={2} />
                    <Col xs={22} sm={20}>
                        <Row gutter={[responsive, responsive]}>
                            <Col xs={24} lg={15} xl={14}>
                                {loading && <LeftLoader />}
                                {!loading && <Details />}
                            </Col>
                            <Col xs={24} lg={9} xl={10}>
                                {rightLoading && <RightLoader responsive={responsive_sm} />}
                                {!rightLoading && <Right />}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1} sm={2} />
                </Row>
            </RootContainer>
        </>
    );
}

export default NewDetails;