import React, { useEffect, useState } from 'react';
import { Col, Row, message, } from 'antd';
import {
    Breadcrumb,
    Section,
    ItemCard,
    ProductDetails as Details,
    Carousel,
} from '@components';
import { api } from '@api';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from '@styles';

const RootContainer = styled.div`
padding-bottom: 80px;

#small-col {
  display: none;
}

@media screen and (max-width: ${devices.laptopL}) {
  padding-bottom: 50px;
}

@media screen and (max-width: ${devices.laptop}) {
  padding-bottom: 35px;
}

@media screen and (max-width: ${devices.tablet}) {
  padding-bottom: 25px;

  #small-col {
    display: block;
  }

  #lg-col {
    display: none;
  }
}
`;

const CarouselLoader = () => {
    return (
        <Carousel loading={true}>
            <ItemCard loading={true} />
            <ItemCard loading={true} />
            <ItemCard loading={true} />
            <ItemCard loading={true} />
        </Carousel>
    );
}

const ProductDetails = () => {
    const { product_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [relatedLoading, setRelatedLoading] = useState(true);
    const [relatedData, setRelatedData] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        getProductDetails();
        getRelatedProducts();

        // eslint-disable-next-line
    }, [product_id]);

    const getProductDetails = () => {
        setLoading(true);
        api
            .getProductDetails(product_id)
            .then((response) => {
                setLoading(false);
                setData(response);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const getRelatedProducts = () => {
        setRelatedLoading(true);
        api
            .getRelatedProducts(product_id)
            .then((response) => {
                setRelatedLoading(false);
                setRelatedData(response);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const renderBreadcrumb = () => (
        <Breadcrumb
            items={loading ? [] : [
                {
                    title: 'Products',
                },
                {
                    title: data.categories[0].name,
                },
            ]}
        />
    );

    const RelatedSection = () => (
        <Section
            title="You May Also Like"
            size='small'
            showMore={false}
        >
            {relatedLoading && <CarouselLoader />}
            {!relatedLoading &&
                <Carousel
                    loading={relatedLoading}
                    showArrows={false}
                >
                    {relatedData.map((item, key) => (
                        <ItemCard
                            key={key}
                            product_id={item.id}
                            item_code={item.productCode}
                            image={item.firstImage}
                            title={item.title}
                            price={item.price}
                            tag={item.artist || item.period}
                            artist_id={item.artistId}
                            product_status={item.productStatus}
                        />
                    ))}
                </Carousel>
            }
        </Section>
    );

    return (
        <RootContainer>
            {renderBreadcrumb()}
            <Row>
                <Col
                    xs={1}
                    sm={2}
                />
                <Col
                    xs={22}
                    sm={20}
                >
                    <Details
                        data={data}
                        loading={loading}
                    />
                    {!relatedLoading && relatedData.length !== 0 && <RelatedSection />}
                </Col>
                <Col
                    xs={1}
                    sm={2}
                />
            </Row>
        </RootContainer>
    );
}

export default ProductDetails;