import React from 'react';
import {
    Appbar,
    Footer,
    SessionExpiredDialog,
} from '@components';
import {
    Layout as AntLayout,
} from 'antd';
import styled from 'styled-components';
import { api } from '@api';
import { localStorage } from '@utils';
import {
  clearToken,
  clearUsername,
  clearCartCount,
  clearWishListCount,
} from '@redux/auth/authSlice';
import { useDispatch } from 'react-redux';

const { Content } = AntLayout;

export const RootLayout = styled(AntLayout)`
min-height: 100vh;
background: ${props => props.theme.backgroundColor};
`;

const Layout = ({ children }) => {
    const dispatch = useDispatch();

    const resetSessionState = () => {
        // clear token and info in redux
        dispatch(clearToken());
        dispatch(clearUsername());
        dispatch(clearCartCount());
        dispatch(clearWishListCount());

        // remove token and info from localstorage
        localStorage.clearToken();
        localStorage.clearUsername();
        localStorage.clearCartCount();
        localStorage.clearWishListCount();

        // update token in request obj
        api.setSessionToken('');
    }

    const handleSessionExpired = ()=>{
        resetSessionState();
        window.location.reload();
    }

    return (
        <>
            <RootLayout>
                <Appbar resetSessionState={resetSessionState} />
                <Content>
                    {children}
                </Content>
                <Footer />
            </RootLayout>
            <SessionExpiredDialog handleClick={handleSessionExpired} />
        </>
    );
}

export default Layout;