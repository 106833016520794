import { Button, Skeleton, Space } from "antd";
import styled from "styled-components";
import { devices } from '@styles';

export const Container = styled.div`
display: flex;
flex-direction: column;
grid-gap: 24px;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
    grid-gap: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
    grid-gap: 8px;
}
`;

export const ActionsWrapper = styled(Space).attrs({
    size: 'middle',
})`
transition: visibility 0s, opacity 0.5s linear;

position: absolute;
bottom: 20px;
width: 100%;
justify-content: center;
align-items: center;

@media screen and (max-width: ${devices.laptop}) {
bottom: 10px;
}
`;

export const ActionButton = styled(Button)`
width: 54px;
height: 54px;
display: flex;
align-items: center;
justify-content: center;
border: none !important;
background-color: ${props => props.theme.secondaryColor};
box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
cursor: pointer;

svg {
    font-size: 30px;
}

@media screen and (max-width: ${devices.laptopL}) {
width: 44px;
height: 44px;

    svg {
        font-size: 20px;
    }
}

@media screen and (max-width: ${devices.laptop}) {
width: 39px;
height: 39px;

    svg {
        font-size: 19px;
    }
}

@media screen and (max-width: ${devices.tablet}) {
width: 39px;
height: 39px;

    svg {
        font-size: 19px;
    }
}

@media screen and (max-width: ${devices.mobileM}) {
width: 34px;
height: 34px;

    svg {
        font-size: 18px;
    }
}
`;

export const ImageWrapper = styled.div`
background-color: #000;
display: flex;
position: relative;
cursor: pointer;
`;

export const Image = styled.img.attrs({
    alt: 'item-img',
})`
width: 100%;
object-fit: cover;
aspect-ratio: ${props => props.item_type === 'news' ? '1 / 0.6' : '1 / 1.05'};
`;

export const Title = styled.div`
font-size: 24px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #371A30;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 18px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 14px;
}
`;

export const Text = styled.div`
font-size: 22px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #FFA024;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 17px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 15px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 14px;
}
`;

export const TagWrapper = styled.div`
display: flex;
`;

export const LightText = styled.div`
font-size: 16px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #8F8F8F;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 14px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 13px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 12px;
}
`;

export const RedText = styled.div`
padding: 3px 6px;
margin-left: 8px;
margin-top: -3px;

background-color: red;
font-size: 14px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #fff;
text-transform: uppercase;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 13px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 13px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 12px;
}
`;

export const CodeBox = styled.div`
padding: 10px;
position: absolute;
top: 16px;
left: 10px;
background-color: ${props => props.theme.secondaryColor};
border-radius: 19px;

font-size: 16px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #000;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 14px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 12px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 12px;
padding: 5px 8px;
}
`;

export const Description = styled.div`
font-size: 18px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: 1.5;
color: #000;
cursor: pointer;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 6;
-webkit-box-orient: vertical;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 14px;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 13px;
}
`;

export const ImageLoader = styled(Skeleton.Image).attrs({
    active: true,
})`
width: 100% !important;
height: 100% !important;
aspect-ratio: ${props => props.item_type === 'news' ? '1 / 0.6' : '1 / 1.05'};

    svg {
        display: none;
    }

    .ant-skeleton-image {
        width: 100% !important;
        height: 100% !important;
        aspect-ratio: ${props => props.item_type === 'news' ? '1 / 0.6' : '1 / 1.05'};
    }
`;