import React, { useState } from 'react';
import { Modal as AntModal, message } from 'antd';
import styled from 'styled-components';
import { api } from '@api';

const Modal = styled(AntModal)`

.ant-modal-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.ant-modal-content {
    padding: 30px 24px;
    border-radius: 0 !important;
}

.ant-modal-body {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    color: #5A5A5A;
    padding: 12px 0;
}

.ant-modal-footer {
    text-align: center;

    .ant-btn {
        min-width: 130px;
        height: 42px;
    }

    .ant-btn-primary {
        background-color: #F1AE56 !important;
        color: #000000;

        :hover {
            background-color: #F1AE56 !important;
        }
    }
}
`;

const ConfirmModal = ({
    cartId,
    shippingInfoId,
    open,
    handleClose,
    successCallback,
}) => {
    const [loading, setLoading] = useState(false);

    const onCancel = () => {
        !loading && handleClose();
    }

    const onOk = () => {
        if (!loading) {
            setLoading(true);
            api
                .placeOrder({
                    cartId,
                    shippingInfoId,
                })
                .then(() => {
                    setLoading(false);
                    successCallback();
                })
                .catch((error) => {
                    setLoading(false);
                    message.error(error);
                });
        }
    }

    return (
        <Modal
            centered
            destroyOnClose
            closable={false}
            width={400}
            open={open}
            confirmLoading={loading}
            title='Place Order!'
            okText='Confirm'
            onCancel={onCancel}
            onOk={onOk}
        >
            Are you sure to place this order?
        </Modal>
    );
}

export default ConfirmModal;