export const devices = {
  mobileS: '576px',
  mobileM: '768px',
  tablet: '992px',
  laptop: '1200px',
  laptopM: '1400px',
  laptopL: '1600px',
  desktop: '1900px',
  desktopL: '2560px',
};
