import React, { useEffect, useState } from 'react';
import {
  AddNewCard,
  Button,
  CardActionBar,
  ItemCard,
  ItemTitle,
  Loader,
  NewIcon,
  RootContainer,
  Text,
  Title,
} from './ManageAddress.style';
import { Col, Row, Space, Spin, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CreateEditModal from './CreateEditModal/CreateEditModal';
import { api } from '@api';
import ConfirmModal from './ConfirmModal/ConfirmModal';

const ManageAddress = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    getAllAddresses();

    // eslint-disable-next-line
  }, []);

  const getAllAddresses = () => {
    setLoading(true);
    api
      .getAllAddresses()
      .then(response => {
        setLoading(false);
        setData(response);
      })
      .catch(error => {
        message.error(error);
      });
  }

  const deleteSuccessCallback = () => {
    setData(prev => prev.filter(item => item.id !== selectedItem.id));
    handleClose();
  }

  const successCallback = (item) => {
    if (isEdit) {
      setData(prev => prev.map(d => {
        if (d.id === item.id) {
          return item;
        }
        return d;
      }));
      message.success('Successfully updated.');
    }
    else {
      setData(prev => [...prev, item]);
      message.success('Successfully created.');
    }

    handleClose();
  }

  const handleDeleteClicked = item => {
    setSelectedItem(item);
    setDeleteOpen(true);
  }

  const handleEditClicked = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    setOpen(true);
  }

  const handleNewClicked = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setSelectedItem({});
    setDeleteOpen(false);
  }

  if (loading) {
    return <Loader><Spin /></Loader>
  }

  return (
    <RootContainer>
      <Title>Manage Your Address</Title>
      <Row gutter={[32, 32]}>
        {data.map((item, key) => (
          <Col key={key} xs={12}>
            <ItemCard>
              <ItemTitle>
                {item.delieverType}
              </ItemTitle>
              <Text>
                {item.email}
              </Text>
              <Text>
                {item.phoneNumber}
              </Text>
              <Text>
                {item.address}
              </Text>
              <Text>
                {`City: ${item.city}`}
              </Text>
              <Text>
                {`Country: ${item.country}`}
              </Text>
              <Text>
                {`Postal Code: ${item.postalCode}`}
              </Text>
            </ItemCard>
            <CardActionBar>
              <Space onClick={() => handleEditClicked(item)}>
                <EditOutlined />
                Edit
              </Space>
              <Space onClick={() => handleDeleteClicked(item)}>
                <DeleteOutlined />
                Delete
              </Space>
            </CardActionBar>
          </Col>
        ))}
        <Col xs={12}>
          <AddNewCard onClick={handleNewClicked}>
            <NewIcon />
            <div>Add New Address</div>
            <Button>Add</Button>
          </AddNewCard>
        </Col>
      </Row>

      <CreateEditModal
        isEdit={isEdit}
        item={selectedItem}
        open={open}
        handleClose={handleClose}
        successCallback={successCallback}
      />
      <ConfirmModal
        item={selectedItem}
        open={deleteOpen}
        handleClose={handleClose}
        successCallback={deleteSuccessCallback}
      />
    </RootContainer>
  )
}

export default ManageAddress