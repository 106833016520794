const PRIMARY_COLOR = '#49093A';
const SECONDARY_COLOR = '#F1AE56';
const BACKGROUND_COLOR = '#FFFFFF';
const PRIMARY_RGB = 'rgb(73 9 58)';

const lightTheme = {
    primaryColor: PRIMARY_COLOR,
    secondaryColor: SECONDARY_COLOR,
    backgroundColor: BACKGROUND_COLOR,
    primaryRgb: opacity => PRIMARY_RGB.replace(')', ` / ${opacity})`),
    appbarColor: '#FFFFFF',
}

export default lightTheme;