import React from 'react';
import {
    BackText,
    Title,
    TitleWrapper,
} from '../styles';
import { Logo } from '@styles';
import {
    Button,
    Form,
    Input,
    message,
} from 'antd';
import { api } from '@api';

const OTP = ({
    registerData,
    otpType,
    loading,
    setLoading,
    goToChangePassword,
    goToForgotPassword,
    goToRegister,
    successCallback,
}) => {

    const onFinish = ({ otpNumber }) => {
        if (otpType === 'register') {
            if (!loading) {
                setLoading(true);
                api
                    .register({
                        ...registerData,
                        otpNumber,
                    })
                    .then(response => {
                        setLoading(false);
                        message.success('Successfully registered!');
                        successCallback(response);
                    })
                    .catch(errorMsg => {
                        setLoading(false);
                        message.error(errorMsg);
                    });
            }
        }
        else {
            goToChangePassword();
        }
    }

    const back = () => {
        if (otpType === 'register') {
            goToRegister();
        }
        else {
            goToForgotPassword();
        }
    }

    const renderTop = () => (
        <TitleWrapper>
            <Logo />
            <Title>
                Enter the 6-digits code sent to your email
            </Title>
        </TitleWrapper>
    );

    return (
        <>
            {renderTop()}
            <Form onFinish={onFinish}>
                <Form.Item
                    name='otpNumber'
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                        {
                            min: 6,
                            max: 6,
                            message: ''
                        },
                    ]}
                >
                    <Input type='number' placeholder='6-digits' />
                </Form.Item>
                <Form.Item>
                    <Button
                        type='primary'
                        size='large'
                        htmlType='submit'
                    >
                        Continue
                    </Button>
                    <BackText onClick={back}>
                        Back
                    </BackText>
                </Form.Item>
            </Form>
        </>
    );
}

export default OTP;