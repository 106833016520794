import React from 'react';
import {
    Breadcrumb,
} from '@components';
import { Col, Row } from 'antd';
import { PRIVATE_ROUTE } from '@router/route.constants';
import Sider from './Sider/Sider';
import { RootContainer } from './AccountInfo.style';
import { Route, Routes } from 'react-router-dom';
import {
    PersonalInfo,
    Wishlist,
    MyOrders,
    ManageAddress,
} from '@pages';

const responsive = {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 25,
    xl: 25,
    xxl: 30,
};

const menu_items = [
    {
        key: PRIVATE_ROUTE.PERSONAL_INFO,
        label: "Account Information"
    },
    {
        key: PRIVATE_ROUTE.WISHLIST,
        label: "Wishlist",
    },
    {
        key: PRIVATE_ROUTE.MY_ORDERS,
        label: "Orders",
    },
    {
        key: 'logout',
        label: "Log out",
    },
];

const AccountInfo = () => {

    const logout = () => { };

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb
                items={[
                    {
                        title: 'My Account',
                    },
                    {
                        title: 'Information',
                    },
                ]}
            />
        );
    };

    return (
        <>
            {renderBreadcrumb()}
            <RootContainer>
                <Row>
                    <Col xs={1} sm={2} />
                    <Col xs={22} sm={20}>
                        <Row gutter={responsive}>
                            <Col xs={0} lg={6}>
                                <Sider
                                    items={menu_items}
                                    logout={logout}
                                />
                            </Col>
                            <Col xs={24} lg={18}>
                                <Row gutter={[responsive, responsive]}>
                                    <Routes>
                                        <Route exact path={`/${PRIVATE_ROUTE.PERSONAL_INFO}/*`} element={<PersonalInfo />} />
                                        <Route exact path={`/${PRIVATE_ROUTE.WISHLIST}`} element={<Wishlist />} />
                                        <Route exact path={`/${PRIVATE_ROUTE.MY_ORDERS}`} element={<MyOrders />} />
                                        <Route exact path={`/${PRIVATE_ROUTE.MANAGE_ADDRESS}`} element={<ManageAddress />} />
                                    </Routes>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1} sm={2} />
                </Row>
            </RootContainer>
        </>
    )
}

export default AccountInfo;