import React, { useEffect, useRef, useState } from 'react';
import {
  Row,
  Col,
  Image,
} from 'antd';
import {
  MediaViewContainer,
  ImageSm,
  ImagesWrapper,
  SlideWrapper,
} from '../styles';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {
  LeftCircleFilled,
  RightCircleFilled,
} from '@ant-design/icons';

const responsive = {
  xs: 10,
  sm: 20,
  md: 20,
  lg: 40,
  xl: 40,
  xxl: 50,
};

const MediaView = ({
  images = [],
}) => {
  const slideRef = useRef(null);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const width = document.getElementsByClassName('react-slideshow-fadezoom-images-wrap')[0]?.children[0]?.style?.width;
    const el = document.getElementsByClassName('ant-image');
    new Array(el.length).fill(0).forEach((_, index) => {
      el[index].style.width = width;
    });

    // eslint-disable-next-line
  }, []);

  const handleItemClicked = key => {
    setSelected(key);
    slideRef.current.goTo(key);
  }

  const handleChange = (from, to) => {
    setSelected(to);
  }

  return (
    <MediaViewContainer>
      <Row gutter={responsive}>
        <Col xs={4}>
          <ImagesWrapper>
            {images?.map((img, key) => (
              <ImageSm
                key={key}
                src={`data:image/png;base64,${img}`}
                onClick={() => handleItemClicked(key)}
                status={key === selected ? 'active' : 'default'}
              />
            ))}
          </ImagesWrapper>
        </Col>
        <Col xs={20}>
          <SlideWrapper>
            <Fade
              ref={slideRef}
              autoplay={false}
              transitionDuration={300}
              prevArrow={<LeftCircleFilled />}
              nextArrow={<RightCircleFilled />}
              onChange={handleChange}

            >
              {images?.map((img, key) => (
                <Image
                  id='my-star'
                  key={key}
                  src={`data:image/png;base64,${img}`}
                  alt='img'
                  preview={{
                    mask: false,
                  }}
                />
              ))}
            </Fade>
          </SlideWrapper>
        </Col>
      </Row>
    </MediaViewContainer>
  );
}

export default MediaView;