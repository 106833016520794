import React from 'react';
import styled from 'styled-components';
import { getFormattedNumber } from '@utils';

const Container = styled.div`
display: flex;
gap: 16px;
`;

const Image = styled.img.attrs({
    alt: 'img'
})`
width: 80px;
height: 80px;
aspect-ratio: 1;
object-fit: cover;
`;

const TitleWrapper = styled.div`
flex: 1;
display: flex;
flex-direction: column;
`;

const Title = styled.div`
flex: 1;
font-size: 18px;
font-weight: normal;
font-style: normal;
color: #000000;
`;

const QtyText = styled.div`
font-size: 16px;
font-weight: normal;
color: #5A5A5A;
`;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
`;

const PriceText = styled.div`
flex: 1;
font-size: 18px;
font-weight: normal;
color: #F1AE56;
`;

const Link = styled.div`
font-size: 16px;
font-weight: normal;
color: #371A30;
text-decoration: underline;
cursor: pointer;
`;

const AddToCartDescription = ({
    image,
    title,
    price,
    viewCart,
}) => {
    
    return (
        <Container>
            <Image src={`data:image/png;base64,${image}`} />
            <TitleWrapper>
                <Title>{title}</Title>
                <QtyText>Qty 1</QtyText>
            </TitleWrapper>
            <Wrapper>
                <PriceText>{`$${price}`}</PriceText>
                <Link onClick={viewCart}>View Cart</Link>
            </Wrapper>
        </Container>
    );
}

export default AddToCartDescription;