import React from 'react';
import Section from '../Section/Section';
import { StarFilled } from '@ant-design/icons';
import { Steps } from '../styles';
import {
  REVIEW_SK,
  FAIR_SK,
  GOOD_SK,
  VERY_GOOD_SK,
  LIKE_NEW_SK,
  NEW_SK,
} from '@constants';

const items = [
  {
    key: REVIEW_SK,
    title: 'Review',
  },
  {
    key: FAIR_SK,
    title: 'Fair',
  },
  {
    key: GOOD_SK,
    title: 'Good',
  },
  {
    key: VERY_GOOD_SK,
    title: 'Very Good',
  },
  {
    key: LIKE_NEW_SK,
    title: 'Like New',
  },
  {
    key: NEW_SK,
    title: 'New',
  },
];

const ConditionReport = ({
  condition,
}) => {

  const content = (
    <Steps
      items={items.map(item => {
        return {
          ...item,
          icon: item.key === condition ? <StarFilled /> : '',
          status: item.key === condition ? 'finish' : 'wait',
        }
      })}
      labelPlacement='vertical'
      size='small'
    />
  );

  return (
    <Section
      title={'Condition Report'}
      content={content}
    />
  );
}

export default ConditionReport;