import React from 'react';
import { Modal as AntModal, } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@router/route.constants';

const Modal = styled(AntModal)`

.ant-modal-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.ant-modal-content {
    padding: 30px 24px;
    border-radius: 0 !important;
}

.ant-modal-body {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    color: #5A5A5A;
    padding: 12px 0;
}

.ant-modal-footer {
    text-align: center;

    .ant-btn {
        min-width: 130px;
        height: 42px;
    }

    .ant-btn-default {
        display: none !important;
    }

    .ant-btn-primary {
        background-color: #F1AE56 !important;
        color: #000000;

        :hover {
            background-color: #F1AE56 !important;
        }
    }
}
`;

const SuccessModal = ({
    open,
    handleClose,
}) => {
    const navigate = useNavigate();

    const onOk = () => {
        handleClose();
        navigate(`/${PRIVATE_ROUTE.ACCOUNT_INFO}/${PRIVATE_ROUTE.MY_ORDERS}`);
    }

    return (
        <Modal
            centered
            destroyOnClose
            closable={false}
            width={400}
            open={open}
            title='Thank You!'
            okText='View Orders'
            onCancel={handleClose}
            onOk={onOk}
        >
            Your order has been received successfully.
        </Modal>
    );
}

export default SuccessModal;