import styled from "styled-components";
import { devices } from '@styles';

export const RootContainer = styled.div`
padding: 80px 0;

.ant-select {
  width: 100%;
}

@media screen and (max-width: ${devices.laptopL}) {
padding: 40px 0;
}

@media screen and (max-width: ${devices.laptop}) {
padding: 30px 0;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 25px 0;
}

@media screen and (max-width: ${devices.mobileS}) {
padding: 14px 0;
}
`;

export const Title = styled.div`
font-size: 24px;
font-weight: 600;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #371A30;

position: relative;
padding-bottom: 14px;

::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    border-bottom: 6px solid ${(props) => props.theme.secondaryColor};
    content: "";
}

@media screen and (max-width: ${devices.laptopL}) {
font-size: 20px;

  ::after {
    width: 50px;
  }
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 20px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 18px;
padding-bottom: 12px;

  ::after {
    width: 40px;
  }
}

@media screen and (max-width: ${devices.mobileM}) {
font-size: 18px;

  ::after {
    width: 30px;
  }
}
`;