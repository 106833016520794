import React from 'react';
import styled from 'styled-components';
import {
    back_icon,
    next_icon,
} from "@assets";
import { Pagination as AntPagination } from 'antd';

const Container = styled.div`
text-align: right;

ul li {
    font-size: ${props => props.size === 'small' ? '18px' : '20px'};
    font-weight: 500;
    font-style: normal;
    color: #371A30;    
}

.ant-pagination .ant-pagination-item-active {
    border: none !important;

    a {
        color: ${props => props.theme.secondaryColor} !important;
    }
}

.ant-pagination-item {
    margin-right: 0 !important;
}
`;

const Icon = styled.img`
height: auto;
object-fit: contain;
margin-bottom: -9px;
`;

const IconWrapper = styled.div`
padding-left: 8px;
display: flex;
align-items: center;
justify-content: center;
margin-top: ${props => props.size === 'small' ? '-1px' : '-7px'};

${Icon} {
    width: ${props => props.size === 'small' ? '42px' : '55px'};
    margin-top: ${props => props.size === 'small' ? '-3px' : '-3px'};
}
`;

const BackIcon = styled(Icon).attrs({
    alt: 'backicon',
    src: back_icon,
})``;

const NextIcon = styled(Icon).attrs({
    alt: 'nexticon',
    src: next_icon,
})``;

const Pagination = ({
    current,
    pageSize,
    total,
    onChange,
    size = 'default'
}) => {
    return (
        <Container size={size}>
            <AntPagination
                current={current}
                pageSize={pageSize}
                total={total}
                onChange={onChange}
                prevIcon={
                    <IconWrapper size={size}>
                        <BackIcon />
                        Previous
                    </IconWrapper>
                }
                nextIcon={
                    <IconWrapper size={size}>
                        Next
                        <NextIcon />
                    </IconWrapper>
                }
                hideOnSinglePage={true}
                showSizeChanger={false}
            />
        </Container>
    );
}

export default Pagination;