import React from 'react';
import styled from 'styled-components';
import {
    RightCircleFilled,
} from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import { devices } from '@styles';

const StyledButton = styled(AntButton)`
background-color: ${props => props.theme.secondaryColor} !important;
border-radius: 26px !important;
display: inline-flex;
flex-direction: row-reverse;
align-items: center;
justify-content: center;
grid-gap: 10px;
height: auto;
padding: 5px 5px 5px 15px !important;
overflow: hidden;
position: relative;
transition: all 0.3s;
z-index: 1;

font-size: 18px;
font-weight: 500;
font-style: normal;
color: #000;

    svg {
        font-size: 38px;
    }

    :after{
        content: '';
        position: absolute;
        z-index: -1;
        transition: all 0.3s;
        box-sizing: border-box;
        border-radius: 26px;
    }

    :after {
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        background: #F9F8EB;
    }

    :hover,
    :active {
        span:not(.anticon) {
            color: #371A30 !important;
        }

        .anticon {
            color: ${props => props.theme.secondaryColor};
        }
    }

    :hover:after,
    :active:after {
        width: 100%;
    }


@media screen and (max-width: ${devices.laptopL}) {
    font-size: 14px;
    padding: 3px 5px 3px 5px !important;
    
    svg {
        font-size: 28px;
    }
}

@media screen and (max-width: ${devices.laptop}) {
    svg {
        font-size: 26px;
    }
}

@media screen and (max-width: ${devices.mobileM}) {
    font-size: 13px;
    grid-gap: 6px;
    padding: 3px !important;
    
    svg {
        font-size: 24px;
    }
}
`;

const Button = ({
    children,
    onClick,
    ...props
}) => {
    return (
        <StyledButton
            {...props}
            type='primary'
            icon={<RightCircleFilled />}
            onClick={onClick}
        >
            {children}
        </StyledButton>
    );
}

export default Button;