import React, { useEffect, useState } from 'react';
import {
    Breadcrumb,
} from '@components';
import { Col, Row, message, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { api } from '@api';
import {
    RootContainer,
    Image,
    Title,
    SpinWrapper,
    Text,
    Paragraph,
} from './ArtistDetails.style';
import moment from 'moment/moment';

const ArtistDetails = () => {
    const { artistId } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        getArtistDetails();

        // eslint-disable-next-line
    }, []);

    const getArtistDetails = () => {
        setLoading(true);
        api
            .getArtistDetails(artistId)
            .then((response) => {
                setLoading(false);
                setData(response);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb
                items={[{
                    title: 'Artist',
                },
                {
                    title: 'Information',
                }]}
            />
        );
    };

    const Loader = () => (
        <SpinWrapper>
            <Spin />
        </SpinWrapper>
    );

    return (
        <>
            {renderBreadcrumb()}
            <RootContainer>
                {loading && <Loader />}
                {!loading && (
                    <Row>
                        <Col xs={1} sm={2} />
                        <Col xs={22} sm={20}>
                            <Row gutter={30}>
                                <Col xs={24} md={6} style={{ textAlign: 'center'}}>
                                    <Image src={`data:image/png;base64,${data.image}`} />
                                </Col>
                                <Col xs={24} md={18}>
                                    <Title>
                                        Artist Name
                                    </Title>
                                    <Text>
                                        {data.name}
                                    </Text>
                                    <Title>
                                        Date of Birth
                                    </Title>
                                    <Text>
                                        {moment(data.dob).format('DD MMMM YYYY')}
                                    </Text>
                                    <Title>
                                        Biogarphy
                                    </Title>
                                    <Paragraph>
                                        {data.bio}
                                    </Paragraph>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </RootContainer>
        </>
    );
}

export default ArtistDetails;