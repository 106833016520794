import React from 'react';
import {
  Banner,
  Categories,
  PrivateCollections,
  RareFine,
  Auctions,
  News,
  SpecialSection,
} from '@components';
import { Col, Row } from 'antd';

const HomePage = () => {
  return (
    <>
      <Banner />
      <Row>
        <Col
          xs={1}
          sm={2}
        />
        <Col
          xs={22}
          sm={20}
        >
          <Categories />
          <SpecialSection />
          <PrivateCollections />
        </Col>
        <Col
          xs={1}
          sm={2}
        />
      </Row>
      <RareFine />
      <Row>
        <Col
          xs={1}
          sm={2}
        />
        <Col
          xs={22}
          sm={20}
        >
          <Auctions />
          <SpecialSection />
          <News />
        </Col>
        <Col
          xs={1}
          sm={2}
        />
      </Row>
    </>
  );
}

export default HomePage;