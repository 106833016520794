import { Col, Skeleton } from 'antd';
import React from 'react';
import { ImageLoader, InfoWrapper, RightBox } from '../AuctionDetails.style';

const RightLoader = ({ responsive }) => {
    return (
        <>
            <Skeleton.Input />
            <RightBox gutter={[responsive, responsive]}>
                {new Array(3).fill(1).map(() => (
                    <>
                        <Col xs={12}>
                            <ImageLoader size='small' />
                        </Col>
                        <Col xs={12}>
                            <InfoWrapper>
                                <Skeleton paragraph={{ rows: 2 }} />
                            </InfoWrapper>
                        </Col>
                        <Col xs={24} />
                    </>
                ))}
            </RightBox>
        </>
    );
}

export default RightLoader;