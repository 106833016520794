import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import { devices } from '@styles';
import {
  back_icon,
  next_icon,
} from '@assets';

export const ReactCarousel = styled(Carousel)`
z-index: 20;
width: calc(100% + 40px);
margin-left: -20px;

  @media screen and (max-width: ${devices.laptopL}) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @media screen and (max-width: ${devices.laptop}) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @media screen and (max-width: ${devices.tablet}) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }

  @media screen and (max-width: ${devices.mobileM}) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }

  @media screen and (max-width: ${devices.mobileS}) {
    width: calc(100% + 10px);
    margin-left: -5px;
  }

  ul>li {
    margin: 0;
  }

  ul>li>div, ul>li>a>div {
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
    cursor: pointer;
    border: none;

      img {
        width: 100%;
      }

      @media screen and (max-width: ${devices.laptopL}) {
        margin-left: 15px;
        margin-right: 15px;
      }

      @media screen and (max-width: ${devices.laptop}) {
        margin-left: 15px;
        margin-right: 15px;
      }

      @media screen and (max-width: ${devices.tablet}) {
        margin-left: 10px;
        margin-right: 10px;
      }

      @media screen and (max-width: ${devices.mobileM}) {
        margin-left: 10px;
        margin-right: 10px;
      }

      @media screen and (max-width: ${devices.mobileS}) {
        margin-left: 5px;
        margin-right: 5px;
      }

  }

  ul>li>div>div {
    background-color: transparent !important;
  }
`;

export const ArrowsWrapper = styled.div`
display: ${({ loading }) => loading ? 'none' : 'flex'};
position: absolute;
z-index: 20;
width: 100%;
top: 40%;
left: 50%;
transform: translate(-50%, -50%);

@media screen and (max-width: ${devices.tablet}) {
  top: 40%;
}

@media screen and (max-width: ${devices.mobileS}) {
  top: 35%;
}
`;

const Arrow = styled.img`
width: 75px;
position: absolute;
cursor: ${props => props.enable ? 'pointer' : 'auto'};;
opacity: ${props => props.enable ? 1 : 0};
transition: opacity 0.7s;

@media screen and (max-width: ${devices.laptopL}) {
  width: 55px;
}

@media screen and (max-width: ${devices.laptop}) {
  width: 40px;
}
`;

export const ArrowLeft = styled(Arrow).attrs({
  alt: 'back-icon',
  src: back_icon,
})`
margin-left: -40px;

@media screen and (max-width: ${devices.laptopL}) {
  margin-left: -30px;
}

@media screen and (max-width: ${devices.laptop}) {
  margin-left: -22px;
}

@media screen and (max-width: ${devices.mobileM}) {
  margin-left: -15px;
}
`;

export const ArrowRight = styled(Arrow).attrs({
  alt: 'next-icon',
  src: next_icon,
})`
right: -40px;

@media screen and (max-width: ${devices.laptopL}) {
  right: -30px;
}

@media screen and (max-width: ${devices.laptop}) {
  right: -22px;
}

@media screen and (max-width: ${devices.mobileM}) {
  right: -15px;
}
`;
