import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { devices } from '@styles';
import { Col, Row, Button as AntButton, message, Spin } from 'antd';
import EditPersonalInfo from './EditPersonalInfo';
import { api } from '@api';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@router/route.constants';

const RootContainer = styled.div`
width: 100%;
padding: 32px;
border: 1px solid #B5B5B5;

@media screen and (max-width: ${devices.laptopL}) {
padding: 20px;
}
`;

const TitleWrapper = styled.div`
padding-bottom: 32px;
display: flex;
align-items: center;

@media screen and (max-width: ${devices.laptopL}) {
padding-bottom: 20px;
}
`;

const Title = styled.div`
flex: 1;
font-size: 20px;
font-weight: bold;
color: #000000;
text-decoration: underline;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 18px;
}
`;

const Title2 = styled(Title)`
padding: 32px 0;

@media screen and (max-width: ${devices.laptopL}) {
padding-top: 30px;
padding-bottom: 20px;
}
`;

const Text = styled.div`
font-size: 20px;
font-weight: normal;
color: #000000;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

const AddressBox = styled.div`
padding: 32px;
width: 100%;
border: 1px solid #B5B5B5;

@media screen and (max-width: ${devices.laptopL}) {
  padding: 20px;
  gap: 20px;
}
`;

const Button = styled(AntButton).attrs({
  type: 'primary',
  danger: true,
})`
background-color: #F1AE56 !important;
font-weight: normal;
color: #000 !important;

:hover {
background-color: #F1AE56 !important;
color: #000 !important;
}
`;

const AddressTitleWrapper = styled.div`
padding-bottom: 16px;
display: flex;
align-items: center;

@media screen and (max-width: ${devices.laptopL}) {
padding-bottom: 14px;
}
`;

const AddressTitle = styled.div`
flex: 1;
font-size: 18px;
font-weight: 500;
color: #5A5A5A;
text-transform: uppercase;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

const AddressText = styled(AddressTitle)`
color: #000000;
text-transform: none;
line-height: 1.5;
`;

const EmptyCard = styled.div`
width: 100%;
padding-top: 12px;
padding-left: 10px;
text-align: left;
`;

const responsive = {
  xs: 15,
  sm: 20,
  md: 20,
  lg: 20,
  xl: 20,
  xxl: 32,
};

const PersonalInfo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    getInfo();
    getAllAddresses();

    // eslint-disable-next-line
  }, []);

  const getInfo = () => {
    setLoading(true);
    api
      .getUserInfo()
      .then((response) => {
        setLoading(false);
        setUser(response);
      })
      .catch(error => {
        message.error(error);
      });
  }

  const getAllAddresses = () => {
    setLoading(true);
    api
      .getAllAddresses()
      .then(response => {
        setLoading(false);
        setAddresses(response);
      })
      .catch(error => {
        message.error(error);
      });
  }

  const handleEditClicked = () => {
    navigate('edit', {
      state: user,
    });
  }

  const goToManageAddress = () => {
    navigate(`/${PRIVATE_ROUTE.ACCOUNT_INFO}/${PRIVATE_ROUTE.MANAGE_ADDRESS}`);
  }

  const EmptyBox = () => (
    <EmptyCard>
      <Button onClick={goToManageAddress}>Manage</Button>
    </EmptyCard>
  );

  const InfoView = () => (
    <RootContainer>
      <TitleWrapper>
        <Title>Personal Info</Title>
        <Button
          onClick={handleEditClicked}
        >
          Edit
        </Button>
      </TitleWrapper>
      <Row gutter={[responsive, responsive]}>
        <Col xs={12}>
          <Text>First Name</Text>
        </Col>
        <Col xs={12}>
          <Text>{user.firstname}</Text>
        </Col>
        <Col xs={12}>
          <Text>Last Name</Text>
        </Col>
        <Col xs={12}>
          <Text>{user.lastname}</Text>
        </Col>
        <Col xs={12}>
          <Text>Mobile / Phone Number</Text>
        </Col>
        <Col xs={12}>
          <Text>{user.phoneNumber || '-'}</Text>
        </Col>
        <Col xs={12}>
          <Text>Email / Gmail Address</Text>
        </Col>
        <Col xs={12}>
          <Text>{user.email}</Text>
        </Col>
        <Col xs={12}>
          <Text>Address</Text>
        </Col>
        <Col xs={12}>
          <Text>{user.address || '-'}</Text>
        </Col>
        {/* <Col xs={12}>
          <Text>Since Member</Text>
        </Col>
        <Col xs={12}>
          <Text>25 Oct 2023</Text>
        </Col> */}
      </Row>
      <Title2>Billing & Shipping Address</Title2>
      <Row gutter={[responsive, responsive]}>
        {addresses.length === 0 && <EmptyBox />}
        {addresses.map((item, key) => (
          <Col key={key} xs={12}>
            <AddressBox>
              <AddressTitleWrapper>
                <AddressTitle>
                  {`${item.delieverType} Address`}
                </AddressTitle>
                <Button onClick={goToManageAddress}>
                  Edit
                </Button>
              </AddressTitleWrapper>
              <AddressText>
                {item.email}
              </AddressText>
              <AddressText>
                {item.phoneNumber}
              </AddressText>
              <AddressText>
                {item.address}
              </AddressText>
              <AddressText>
                {`City: ${item.city}`}
              </AddressText>
              <AddressText>
                {`Country: ${item.country}`}
              </AddressText>
              <AddressText>
                {`Postal Code: ${item.postalCode}`}
              </AddressText>
            </AddressBox>
          </Col>
        ))}
      </Row>
    </RootContainer>
  );

  if (loading) {
    return (
      <div style={{ paddingTop: '15px', width: '100%', textAlign: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <InfoView />
  );
}

const Entry = () => {
  return (
    <Routes>
      <Route exact path='' element={<PersonalInfo />} />
      <Route exact path='/edit' element={<EditPersonalInfo />} />
    </Routes>
  )
}
export default Entry;