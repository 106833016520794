import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import store from '@redux';
import Boot from './boot';
import {
  lightTheme,
  GlobalStyles,
} from '@styles';
import { Routes } from '@router';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </Provider>
  );
}

Boot()
  .then(() => { })
  .catch(error => console.error(error));

export default App;
