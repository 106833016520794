import React, { useEffect, useState } from 'react';
import {
    ItemCard,
    Section,
    Carousel,
} from '@components';
import { api } from '@api';
import { message } from 'antd';
import Loader from './Loader/Loader';

const PrivateCollections = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getHomePrivateCollections();

        // eslint-disable-next-line
    }, []);

    const getHomePrivateCollections = () => {
        setLoading(true);
        api
            .getHomePrivateCollections()
            .then((response) => {
                setLoading(false);
                setData(response);
            })
            .catch(error => {
                message.error(error);
            });
    }

    return (
        <Section
            title='Private Collections'
            path={`/private-collections`}
        >
            {loading && <Loader />}
            {!loading &&
                <Carousel loading={loading}>
                    {data.map((item, key) => (
                        <ItemCard
                            key={key}
                            product_id={item.id}
                            item_code={item.productCode}
                            image={item.firstImage}
                            title={item.title}
                            price={item.price}
                            tag={item.period}
                            product_status={item.productStatus}
                        />
                    ))}
                </Carousel>
            }
        </Section>
    );
}

export default PrivateCollections;