import React from 'react';
import { CardWrapper, ImageLoader } from '../AuctionDetails.style';
import { Skeleton } from 'antd';

const LeftLoader = () => {
    return (
        <CardWrapper>
            <Skeleton.Input />
            <ImageLoader />
            <Skeleton paragraph={{ rows: 5 }} />
        </CardWrapper>
    );
}

export default LeftLoader;