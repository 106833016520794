import styled, { css } from "styled-components";
import { Logo } from '@styles';
import {
  Input,
  Menu as AntMenu,
  Row as AntRow,
  Drawer as AntDrawer,
} from 'antd';
import {
  HeartFilled,
  MenuOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { devices } from '@styles';
import { SearchOutlined } from "@assets";

export const Row = styled(AntRow)`
background-color: ${props => props.theme.appbarColor};
box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
position: sticky;
top: -2px;
z-index: 100;

.ant-menu-horizontal {
  border: none;
}
`;

export const Topbar = styled.div`
z-index: 100;
height: 59px;
display: flex;
align-items: center;
justify-content: center;
background: ${props => props.theme.secondaryColor};

font-family: 'Courgette';
font-size: 18px;
font-weight: normal;
font-style: normal;
color: #371A30;
line-height: normal;
text-align: center;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: 16px;
  height: 45px;
}

@media screen and (max-width: ${devices.laptopM}) {
  font-size: 13px;
  height: 40px;
}

@media screen and (max-width: ${devices.laptop}) {
  font-size: 12px;
  height: 30px;
}

@media screen and (max-width: ${devices.tablet}) {
  font-size: 11px;
  height: auto;
  padding: 7px;
}
`;

export const Mainbar = styled.div`
padding: 24px 0;
background: ${props => props.theme.appbarColor};
display: flex;
align-items: center;
gap: 16px;

@media screen and (max-width: ${devices.laptopL}) {
  padding: 20px 0;
  gap: 8px;
}

@media screen and (max-width: ${devices.laptopM}) {
  padding: 15px 0;
}
`;

export const Box = styled.div`
flex: 1;
display: flex;
align-items: flex-end;
gap: 16px;
padding-bottom: 20px;

@media screen and (max-width: ${devices.laptopM}) {
padding-bottom: 10px;
align-items: center;
}

@media screen and (max-width: ${devices.laptop}) {
padding-bottom: 0;
}

@media screen and (max-width: ${devices.tablet}) {
display: none !important;
}
`;

export const AppLogo = styled(Logo)`
width: 225px;
height: auto;

@media screen and (max-width: ${devices.desktop}) {
  width: 200px;
}

@media screen and (max-width: ${devices.laptopL}) {
  width: 170px;
}

@media screen and (max-width: ${devices.laptopM}) {
  width: 130px;
}

@media screen and (max-width: ${devices.laptop}) {
  width: 105px;
}

@media screen and (max-width: ${devices.tablet}) {
  width: 95px;
}
`;

export const Menu = styled(AntMenu)`
flex: 1;
align-items: center;
justify-content: flex-end;
height: 52px;

.ant-menu-item {
  padding-inline: 35px;
}

.ant-menu-title-content {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  color: #000000;
}

.ant-menu-submenu-title .anticon {
  font-size: 20px;
}

.ant-menu-submenu:hover .ant-menu-title-content {
  color: ${(props) => props.theme.secondaryColor} !important;

  .anticon {
    color: #000 !important;
  }
}

.ant-menu-submenu::after {
  border: none !important;
}

.ant-menu-item-selected {
  background: transparent !important;

  .ant-menu-title-content {
    color: ${(props) => props.theme.secondaryColor} !important;
  }

  .ant-menu-submenu-title {
    color: ${(props) => props.theme.primaryColor} !important;
  }

  :hover {
    background: transparent !important;
  }

  :after {
    width: 35%;
    inset-inline: 35px !important;
    border-bottom-width: 6px !important;
    border-bottom-color: ${(props) => props.theme.secondaryColor} !important;
  }
}

.ant-menu-sub {
  background: ${(props) => props.theme.backgroundColor} !important;
}

.ant-menu-item:hover {
  .ant-menu-title-content {
    color: ${(props) => props.theme.secondaryColor} !important;
  }
}

.ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-menu-item:not(.ant-menu-item-selected):after,
.ant-menu-submenu-title:hover {
  background: transparent !important;
  border: none !important;
  border-bottom-width: 6px !important;
  border-bottom-color: ${(props) => props.theme.secondaryColor} !important;
}

.ant-menu-submenu-arrow {
  display: none !important;
}

@media screen and (max-width: ${devices.desktop}) {
  .ant-menu-item-selected::after {
    inset-inline: 25px !important;
  }
}

@media screen and (max-width: ${devices.laptopL}) {
  .ant-menu-item-selected::after {
    inset-inline: 20px !important;
  }
}

@media screen and (max-width: ${devices.laptopM}) {
  .ant-menu-item-selected::after {
    inset-inline: 15px !important;
  }
}

@media screen and (max-width: ${devices.laptop}) {
  .ant-menu-item-selected::after {
    inset-inline: 14px !important;
  }
}

@media screen and (max-width: ${devices.desktop}) {
  .ant-menu-item {
    padding-inline: 25px;
  }

  .ant-menu-title-content {
    font-size: 18px;
  }

  .ant-menu-submenu-title .anticon {
    font-size: 18px;
  }
}

@media screen and (max-width: ${devices.laptopL}) {
  .ant-menu-item {
    padding-inline: 20px;
  }

  .ant-menu-title-content {
    font-size: 16px;
  }

  .ant-menu-submenu-title .anticon {
    font-size: 16px;
  }
}

@media screen and (max-width: ${devices.laptopM}) {
  .ant-menu-item {
    padding-inline: 15px;
  }

  .ant-menu-title-content {
    font-size: 16px;
  }

  .ant-menu-submenu-title .anticon {
    font-size: 16px;
  }
}

@media screen and (max-width: ${devices.laptop}) {
  .ant-menu-item {
    padding-inline: 14px;
  }

  .ant-menu-title-content {
    font-size: 15px;
  }

  .ant-menu-submenu-title .anticon {
    font-size: 15px;
  }
}
`;

export const TopMenu = styled(AntMenu)`
align-items: center;
justify-content: flex-end;
height: 52px;

.ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-menu-item:not(.ant-menu-item-selected):after,
.ant-menu-submenu-title:hover {
  border: none !important;
}

.ant-menu-item-selected::after {
  width: 35%;
  inset-inline: 15px !important;
  border-bottom-width: 6px !important;
  border-bottom-color: ${(props) => props.theme.secondaryColor} !important;
}

.ant-menu-item:nth-child(2) {
  padding-inline-end: 0;
}

.ant-menu-title-content {
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  color: #371A30;
}

.ant-menu-submenu-title .anticon {
  font-size: 20px;
}

.ant-menu-submenu {
  padding-inline-end: 0 !important;

  ::after {
    border: none !important;
  }
}

@media screen and (max-width: ${devices.desktop}) {
  .ant-menu-item {
    padding-inline: 17px;
  }

  .ant-menu-item:nth-child(2) {
    padding-inline-end: 0;
  }

  .ant-menu-title-content {
    font-size: 16px;
  }
}

@media screen and (max-width: ${devices.laptopL}) {
height: 30px;
padding-bottom: 15px !important;

  .ant-menu-item {
    padding-inline: 15px;
  }

  .ant-menu-item:nth-child(2) {
    padding-inline-end: 0;
  }

  .ant-menu-title-content {
    font-size: 15px;
  }
}

@media screen and (max-width: ${devices.laptopM}) {
  padding-bottom: 10px !important;
}

@media screen and (max-width: ${devices.laptop}) {
  padding-bottom: 10px !important; 
}

@media screen and (max-width: ${devices.tablet}) {
  display: none;
}
`;

export const SubMenu = styled.div`
display: flex;
align-items: center;
gap: 10px;

#smallicon {
  display: none;
}

@media screen and (max-width: ${devices.laptopL}) {
gap: 5px;
}
`;

const Icon = css`
font-size: 25px;
cursor: pointer;

@media screen and (max-width: ${devices.laptop}) {
font-size: 23px;
}
`;

export const SearchIcon = styled(SearchOutlined)`
display: none;
${Icon}

@media screen and (max-width: ${devices.laptopM}) {
  display: block;
}
`;

export const SearchInput = styled(Input)`
flex: 1;

.ant-input {
  height: 38px;
  font-size: 16px !important;
}
  
.anticon svg {
  font-size: 24px;
}

@media screen and (max-width: ${devices.laptopM}) {
  display: none;
}
`;

export const SearchInputSm = styled(Input)`
flex: 1;
margin-left: 10%;

.ant-input {
  height: 38px;
  font-size: 16px !important;
}
  
.anticon svg {
  font-size: 24px;
}
`;

export const ShoppingCartIcon = styled(ShoppingCartOutlined)`
${Icon}
`;

export const FavouriteIcon = styled(HeartFilled)`
${Icon}
`;

export const ActionsWrapper = styled.div`
display: flex;
gap: 16px;
align-items: center;

@media screen and (max-width: ${devices.laptopM}) {
gap: 20px;
height: 48px;
justify-content: flex-end;
}

@media screen and (max-width: ${devices.laptop}) {
gap: 18px;
height: 45px;
justify-content: flex-end;
}

@media screen and (max-width: 1100px) {
min-width: auto;
}
`;

export const TopMenuWrapper = styled.div`

.ant-menu {
  padding-bottom: 24px;
  margin-left: -35px;

  .ant-menu-item:nth-child(3) {
    padding-right: 0;
  }
}
`;

export const MobileMenuIcon = styled(MenuOutlined)`
display: none;
flex: 1;
text-align: right;

svg {
    color: #000;
    font-size: 25px;
}

@media screen and (max-width: ${devices.tablet}) {
display: block;
}
`;

export const Drawer = styled(AntDrawer)`

.ant-drawer-body {
padding-left: 0 !important;
padding-right: 0 !important;
}

.ant-drawer-header-title {
    flex-direction: row-reverse;
}

.ant-drawer-header, .ant-drawer-body {
    padding: 16px 18px 10px 18px;
}

.ant-drawer-close {
    margin-inline-end: 0;
    
    .anticon-close {
        font-size: 25px;
    }
}

${AppLogo} {
    width: 100px;
}

.ant-menu {
  border: none !important;
}

.ant-menu-submenu:hover .ant-menu-title-content {
  color: #000 !important;
}

#smallicon {
  display: block;
}
`;

export const Right = styled.div`
flex: 1;
display: flex;
flex-direction: column;
`;