import React, { useEffect, useMemo, useState } from 'react';
import {
    Breadcrumb,
    ItemCard,
    Pagination,
} from '@components';
import {
    RootContainer,
    Text,
    Bottom,
} from './SearchResult.style';
import {
    Row,
    Col,
    message,
} from 'antd';
import { api } from '@api';
import { useLocation } from 'react-router-dom';
import { LIMITS } from '@constants';
import Loader from './Loader/Loader';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const {
    DEFAULT_PAGE,
    PAGE_SIZE,
} = LIMITS;

const responsive = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 40,
};

const SearchResult = () => {
    const searchQuery = useQuery().get('search');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getSearchResult();

        // eslint-disable-next-line
    }, [searchQuery]);

    const getSearchResult = (page = DEFAULT_PAGE) => {
        setLoading(true);
        api
            .getSearchResult({
                searchQuery,
                page,
                size: PAGE_SIZE,
            })
            .then(({ content, totalElements }) => {
                setLoading(false);
                setData(content);
                setTotal(totalElements);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const handlePageChange = page => {
        setPage(page);
        getSearchResult(page);
    }

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb
                items={[
                    {
                        title: 'Search Result',
                    }]}
            />
        );
    };

    const renderBottom = () => (
        <Bottom>
            <Pagination
                current={page}
                pageSize={PAGE_SIZE}
                total={total}
                onChange={handlePageChange}
            />
        </Bottom>
    );


    if (!loading && data.length === 0) {
        return (
            <>
                {renderBreadcrumb()}
                <RootContainer>
                    <Text>
                        No Search Result.
                    </Text>
                </RootContainer>
            </>
        );
    }

    return (
        <>
            {renderBreadcrumb()}
            <RootContainer>
                <Row>
                    <Col xs={1} sm={2} />
                    <Col xs={22} sm={20}>
                        <Row gutter={[responsive, responsive]}>
                            {loading && <Loader />}
                            {!loading &&
                                data.map((item, key) => (
                                    <Col key={key} xs={12} md={8} lg={6}>
                                        <ItemCard
                                            key={key}
                                            product_id={item.id}
                                            item_code={item.productCode}
                                            image={item.firstImage}
                                            title={item.title}
                                            price={item.price}
                                            tag={item.artist || item.period}
                                            artist_id={item.artistId}
                                            product_status={item.productStatus}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    <Col xs={1} sm={2} />
                </Row>
                <Row>
                    <Col xs={1} />
                    <Col xs={22}>
                        {data.length !== 0 && renderBottom()}
                    </Col>
                    <Col xs={1} />
                </Row>
            </RootContainer>
        </>
    );
}

export default SearchResult;