import { Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@components';
import {
    special_section_bg_1,
    special_section_bg_2,
} from '@assets';
import { devices } from '@styles';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_ROUTE } from '@router/route.constants';

const RootContainer = styled(Space)`
position: relative;
padding: 50px 0;
width: 100%;
justify-content: center;
align-items: center;
background-color: #f9f0f7;

.ant-space-item {
    flex: 1;
    text-align: center;
}

@media screen and (max-width: ${devices.laptopL}){
    padding: 30px 0;
}

@media screen and (max-width: ${devices.laptop}){
    padding: 25px 0;
}
`;

const Container = styled(Space).attrs({
    size: 'large',
    direction: 'vertical',
})`
width: 100%;
justify-content: center;
align-items: center;
background-color: transparent;

position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

@media screen and (max-width: ${devices.laptopL}){
    gap: 16px !important;
}

@media screen and (max-width: ${devices.tablet}){
    gap: 8px !important;
}

@media screen and (max-width: ${devices.mobileS}){
    background-color: #f9f0f7;
}
`;

const Image = styled.img.attrs({
    alt: 'img',
})`
width: auto;
max-width: ${props => props.size === 'small' ? '180px' : '210px'};
object-fit: contain;

@media screen and (max-width: ${devices.mobileS}){
    opacity: 0;
    visibility: hidden;
}
`;

const Title = styled.div`
font-size: 50px;
font-weight: bold;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: ${props => props.theme.secondaryColor};
font-family: 'Britannic';

@media screen and (max-width: ${devices.laptopL}){
    font-size: 40px;
}

@media screen and (max-width: ${devices.laptop}){
    font-size: 30px;
}

@media screen and (max-width: ${devices.tablet}){
    font-size: 25px;
}

@media screen and (max-width: ${devices.mobileM}){
    font-size: 25px;
}
`;

const SubTitle = styled.div`
font-size: 36px;
font-weight: bold;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #371A30;

@media screen and (max-width: ${devices.laptopL}){
    font-size: 25px;
}

@media screen and (max-width: ${devices.laptop}){
    font-size: 20px;
    max-width: 302px;
}

@media screen and (max-width: ${devices.tablet}){
    font-size: 20px;
}

@media screen and (max-width: ${devices.mobileM}){
    font-size: 16px;
}
`;

const Text = styled.div`
font-size: 28px;
font-weight: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #371A30;

@media screen and (max-width: ${devices.laptopL}){
    font-size: 18px;
}

@media screen and (max-width: ${devices.laptop}){
    font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}){
    font-size: 14px;
}

@media screen and (max-width: ${devices.mobileM}){
    font-size: 13px;
}
`;

const SpecialSection = () => {
    const navigate = useNavigate();

    const handleClicked = () => {
        navigate(`/${PUBLIC_ROUTE.PRIVATE_COLLECTIONS}`);
    }

    return (
        <RootContainer>
            <Image src={special_section_bg_1} />
            <Container>
                <Title>Art of Asia</Title>
                <SubTitle>
                    Ancient Beads and Jewellery of Myanmar
                </SubTitle>
                <Text>
                    Private Collections
                </Text>
                <Button onClick={handleClicked}>
                    See More
                </Button>
            </Container>
            <Image size='small' src={special_section_bg_2} />
        </RootContainer>
    );
}

export default SpecialSection;