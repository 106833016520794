import styled from "styled-components";
import { devices } from '@styles';

export const RootContainer = styled.div`
padding: 80px 0;

@media screen and (max-width: ${devices.laptopL}) {
padding: 40px 0;
}

@media screen and (max-width: ${devices.laptop}) {
padding: 30px 0;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 25px 0;
}
`;

export const Bottom = styled.div`
padding-top: 60px;
`;

export const EmptyCard = styled.div`
width: 100%;
text-align: center;
text-transform: capitalize;
`;