import React from 'react';
import {
    TitleWrapper,
    Text,
    Link,
    LinkWrapper,
    RightText,
    BottomText,
} from '../styles';
import { Logo } from '@styles';
import {
    Button,
    Form,
    Input,
    message,
} from 'antd';
import { api } from '@api';

const Login = ({
    loading,
    setLoading,
    goToRegister,
    goToForgotPassword,
    successCallback,
}) => {

    const onFinish = (values) => {
        setLoading(true);
        api
            .login(values)
            .then((response) => {
                setLoading(false);
                message.success('Successfully logged in!');
                successCallback(response);
            })
            .catch(error => {
                setLoading(false);
                message.error(error);
            });
    }

    const renderTop = () => (
        <TitleWrapper>
            <Logo />
            {/* <Title>
                A GREETING GOES HERE
            </Title> */}
        </TitleWrapper>
    );

    const renderBottom = () => (
        <BottomText>
            Login to this website, you accept our Terms of Use and Privacy Policy.
        </BottomText>
    );

    return (
        <>
            {renderTop()}
            <Form
                onFinish={onFinish}
            >
                <Form.Item
                    name='email'
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                        {
                            type: 'email',
                            message: 'Invalid email format!'
                        }
                    ]}
                >
                    <Input placeholder='Email' />
                </Form.Item>
                <Form.Item
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: '',
                        }
                    ]}
                >
                    <div>
                        <Input.Password placeholder='Password' />
                        <RightText>
                            <span onClick={goToForgotPassword}>Forgot password?</span>
                        </RightText>
                    </div>
                </Form.Item>
                <Form.Item>
                    <Button
                        type='primary'
                        size='large'
                        htmlType='submit'
                        loading={loading}
                    >
                        Sign In
                    </Button>
                    <LinkWrapper>
                        <Text>Not a member?</Text>&nbsp;
                        <Link onClick={goToRegister}>Register Now!</Link>
                    </LinkWrapper>
                </Form.Item>
            </Form>
            {renderBottom()}
        </>
    );
}

export default Login;