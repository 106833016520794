import React from 'react';
import {
    Drawer,
    AppLogo,
    Menu,
} from '../Appbar.style';

const MobileDrawer = ({
    navs,
    selectedKeys,
    open,
    setOpen,
    onClick,
    goToHome,
}) => {
    
    const handleClicked = ()=>{
        setOpen(false);
        goToHome();
    }

    return (
        <Drawer
            title={<AppLogo onClick={handleClicked} />}
            placement="right"
            onClose={() => setOpen(false)}
            open={open}
        >
            <Menu
                mode="inline"
                items={navs}
                selectedKeys={selectedKeys}
                onClick={onClick}
            />
        </Drawer>
    )
}

export default MobileDrawer;