import React from 'react';
import styled from 'styled-components';
import {
    Space,
    Select,
    Col,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { devices } from '@styles';

const FilterBox = styled(Space).attrs({
    size: 'small'
})`
  width: 100%;
  
  .ant-space-item:nth-child(2) {
    flex: 1;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const Label = styled.div`
min-width: 35px;
  font-size: 24px;
  font-weight: normal;
  color: #371A30;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: 18px;
}

@media screen and (max-width: ${devices.laptopM}) {
  font-size: 16px;}

@media screen and (max-width: ${devices.laptop}) {
  font-size: 14px;
}
`;

const Filter = ({ label, ...props }) => (
    <FilterBox>
        <Label>{label}</Label>
        <Select
            {...props}
            size='large'
            suffixIcon={<CaretDownOutlined />}
        />
    </FilterBox>
);

const Filters = ({
    sub_categories,
    periods,
    prices,
    type,
    period,
    price,
    onTypeChange,
    onPeriodChange,
    onPriceChange,
}) => {

    return (
        <>
            <Col xs={24} lg={8}>
                <Filter
                    label='Type'
                    value={type}
                    options={[
                        {
                            value: 0,
                            label: 'Select Type',
                        },
                        {
                            value: sub_categories.id,
                            label: sub_categories.name,
                        },
                    ]}
                    onChange={onTypeChange}
                />
            </Col>
            <Col xs={24} lg={8}>
                <Filter
                    label='Age'
                    value={period}
                    options={[
                        {
                            value: 0,
                            label: 'Select Age',
                        },
                        ...periods.map(p => {
                            return {
                                value: p.id,
                                label: p.name,
                            };
                        })
                    ]}
                    onChange={onPeriodChange}
                />
            </Col>
            <Col xs={24} lg={8}>
                <Filter
                    label='Price'
                    value={price}
                    options={[
                        {
                            value: 0,
                            label: 'Select Price',
                        },
                        ...prices.map(p => {
                            return {
                                value: p.id,
                                label: p.name,
                            };
                        })
                    ]}
                    onChange={onPriceChange}
                />
            </Col>
        </>
    );
}

export default Filters;