import React, { useEffect, useState } from 'react';
import { Section } from '@components';
import { Col, Row, message } from 'antd';
import styled from 'styled-components';
import { api } from '@api';
import moment from 'moment';
import Loader from './Loader/Loader';
import { devices } from '@styles';
import { PUBLIC_ROUTE } from '@router/route.constants';
import { useNavigate } from 'react-router-dom';

const Image = styled.img.attrs({
    alt: 'new-img'
})`
width: 100%;
object-fit: cover;
aspect-ratio: ${props => props.size === 'small' ? 47 / 30 : 16 / 9};
cursor: pointer;
`;

const DateText = styled.div`
font-size: 20px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #371A30;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 14px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 13px;
}
`;

const Title = styled.div`
font-size: 26px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: 1.5;
color: #000000;
text-transform: uppercase;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 18px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 14px;
}
`;

export const ItemCard = styled.div`
display: flex;
flex-direction: column;
grid-gap: 24px;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
grid-gap: 16px;
}

@media screen and (max-width: ${devices.laptop}) {
grid-gap: 8px;
}
`;

export const InfoWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
grid-gap: 24px;
justify-content: center;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
grid-gap: 16px;
}

@media screen and (max-width: ${devices.laptop}) {
grid-gap: 8px;
}
`;

const ITEM_COUNT = 3;

const dateFormat = 'DD MMMM YYYY';

const responsive = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 40,
};

const News = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getLatestNews();

        // eslint-disable-next-line
    }, []);

    const getLatestNews = () => {
        setLoading(true);
        api
            .getLatestNews(ITEM_COUNT)
            .then(response => {
                setLoading(false);
                setData(response);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const goToDetails = id => {
        navigate(`/${PUBLIC_ROUTE.NEW_DETAILS}/${id}`);
    }

    if (loading) {
        return <Loader responsive={responsive} />
    }

    return (
        <Section
            title='Latest News'
            path={`/${PUBLIC_ROUTE.NEWS}`}
        >
            <Row
                gutter={[responsive, responsive]}>
                <Col
                    xs={24}
                    md={12}
                >
                    <ItemCard
                        direction='vertical'
                        onClick={() => goToDetails(data[0]?.id)}
                    >
                        <Image src={`data:image/png;base64,${data[0]?.images}`} />
                        <DateText>
                            {moment(data[0]?.publishDate).format(dateFormat)} | {data[0]?.author}
                        </DateText>
                        <Title>{data[0]?.title}</Title>
                    </ItemCard>
                </Col>
                <Col
                    xs={24}
                    md={12}
                >
                    <Row gutter={[responsive, responsive]}>
                        <Col xs={12}>
                            <Image
                                src={`data:image/png;base64,${data[1]?.images}`}
                                size='small'
                                onClick={() => goToDetails(data[1]?.id)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InfoWrapper
                                onClick={() => goToDetails(data[1]?.id)}
                            >
                                <DateText>
                                    {moment(data[1]?.publishDate).format(dateFormat)} | {data[1]?.author}
                                </DateText>
                                <Title>{data[1]?.title}</Title>
                            </InfoWrapper>
                        </Col>
                        <Col xs={12}>
                            <Image
                                src={`data:image/png;base64,${data[2]?.images}`}
                                size='small'
                                onClick={() => goToDetails(data[2]?.id)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InfoWrapper
                                onClick={() => goToDetails(data[2]?.id)}
                            >
                                <DateText>
                                    {moment(data[2]?.publishDate).format(dateFormat)} | {data[2]?.author}
                                </DateText>
                                <Title>{data[2]?.title}</Title>
                            </InfoWrapper>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Section>
    );
}

export default News;