import React from 'react';
import { Col } from 'antd';
import {
    CardMedia,
    CardInfo,
} from '@components';
import { InfoWrapper } from '../RareFine';

const Loader = () => {
    return (
        new Array(4).fill(0).map(() => (
            <>
                <Col
                    xs={12}
                    md={6}
                >
                    <CardMedia loading={true} />
                </Col>
                <Col
                    xs={12}
                    md={6}
                >
                    <InfoWrapper>
                        <CardInfo
                            loading={true}
                            rows={4}
                        />
                    </InfoWrapper>
                </Col>
            </>
        ))
    );
}

export default Loader;