import { Space } from 'antd';
import styled from 'styled-components';
import { devices} from '@styles';

export const Container = styled.div`
padding: 100px 0;

@media screen and (max-width: ${devices.laptopL}) {
padding: 50px 0;
}

@media screen and (max-width: ${devices.laptop}) {
    padding: 40px 0;
}

@media screen and (max-width: ${devices.tablet}) {
    padding: 30px 0;
}
`;

export const Content = styled.div`
padding-top: 64px;
position: relative;

@media screen and (max-width: ${devices.laptopL}) {
padding-top: 40px;
}

@media screen and (max-width: ${devices.laptop}) {
padding-top: 30px;
}

@media screen and (max-width: ${devices.tablet}) {
padding-top: 20px;
}

@media screen and (max-width: ${devices.mobileM}) {
padding-top: 20px;
}
`;

export const TitleWrapper = styled(Space)`
width: 100%;
align-items: flex-start;

.ant-space-item:nth-child(1) {
    flex: 1;
}
`;