import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    message,
    Spin,
    Space,
    Button,
} from 'antd';
import {
    RootContainer,
    Title,
    SpinWrapper,
    Item,
    Image,
    ItemTitle,
    PriceText,
    DeleteIcon,
    QtyBox,
    Wrapper,
    TotalText,
    Bottom,
    BottomItem,
    Divider,
    RedText,
    TitleWrappr,
} from './MyCart.style';
import { Breadcrumb } from '@components';
import { api } from '@api';
import { getFormattedNumber } from '@utils';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { localStorage } from '@utils';
import { saveCartCount } from '@redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@router/route.constants';
import { ACTIVE_SK } from '@constants';

const MyCart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState({
        count: 0,
        amount: 0,
    });
    const [cartId, setCartId] = useState('');
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const { cartCount } = useSelector(state => state.auth);

    useEffect(() => {
        getCartItems();

        // eslint-disable-next-line
    }, []);

    const getCartItems = () => {
        setLoading(true);
        api
            .viewCart()
            .then(({ cartItems, cartItemCount, totalPrice, id }) => {
                setLoading(false);
                setData(cartItems || []);
                setTotal({
                    count: cartItemCount,
                    amount: totalPrice,
                });
                setCartId(id);
            })
            .catch((error) => {
                setLoading(false);
                message.error(error);
            });
    }

    const handleDeleteClicked = (item) => {
        setSelected(item);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setSelected(null);
    }

    const successCallback = ({ cartItemCount, totalPrice }) => {
        setData(prev => prev.filter(item => item.cartItemId !== selected.cartItemId));
        setTotal({
            count: cartItemCount,
            amount: totalPrice,
        });
        setOpen(false);
        setSelected(null);

        // decrease cart count from localstorage
        localStorage.setCartCount(cartCount - 1);
        // decrease cart count from redux
        dispatch(saveCartCount(cartCount - 1));
    }

    const proceedToCheckout = () => {
        navigate(`/${PRIVATE_ROUTE.PROCEED_CHECKOUT}`, {
            state: {
                data,
                total,
                cartId,
            },
        });
    }

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb
                items={[{ title: 'Your Cart' }]}
            />
        );
    };

    const Loader = () => (
        <SpinWrapper>
            <Spin />
        </SpinWrapper>
    );

    const TableTitle = () => (
        <Item>
            <div style={{ flex: 0.6 }}>
                CART ITEMS
            </div>
            <div style={{ textAlign: 'center', flex: 0.1 }}>
                PRICE
            </div>
            <div style={{ textAlign: 'center', flex: 0.15 }}>
                QUANTITY
            </div>
            <div style={{ textAlign: 'center', flex: 0.15 }}>
                TOTAL
            </div>
        </Item>
    );

    return (
        <>
            {renderBreadcrumb()}
            <RootContainer>
                {loading && <Loader />}
                {!loading &&
                    <Row>
                        <Col xs={1} sm={2} />
                        <Col xs={22} sm={20}>
                            <Title>{`YOUR CART (${total.count || data.length} ITEMS)`}</Title>
                            {data.length > 0 && <TableTitle />}
                            {data.map((item, key) => (
                                <Item key={key}>
                                    <Wrapper>
                                        <Image src={`data:image/png;base64,${item.image}`} />
                                        <TitleWrappr>
                                            {item.productStatus !== ACTIVE_SK &&
                                                <div style={{ display: 'flex' }}>
                                                    <RedText>Sold out</RedText>
                                                </div>
                                            }
                                            <ItemTitle>
                                                {item.productName}
                                            </ItemTitle>
                                        </TitleWrappr>
                                    </Wrapper>
                                    <PriceText>
                                        {`$${item.price}`}
                                    </PriceText>
                                    <QtyBox>
                                        {item.quantity}
                                    </QtyBox>
                                    <TotalText>
                                        {`$${item.price * item.quantity}`}
                                    </TotalText>
                                    <DeleteIcon onClick={() => handleDeleteClicked(item)} />
                                </Item>
                            ))}

                            {data.length > 0 &&
                                <Bottom>
                                    <Space direction='vertical'>
                                        <BottomItem>
                                            SUBTOTAL:&nbsp;&nbsp;${total.amount}
                                        </BottomItem>
                                        <Divider />
                                        <BottomItem>
                                            GRAND TOTAL:&nbsp;&nbsp;${total.amount}
                                        </BottomItem>
                                        <Button
                                            danger
                                            size='large'
                                            onClick={proceedToCheckout}
                                        >
                                            PROCEED TO CHECKOUT
                                        </Button>
                                    </Space>
                                </Bottom>
                            }
                        </Col>
                        <Col xs={1} sm={2} />
                    </Row>
                }
            </RootContainer>
            <ConfirmModal
                item={selected}
                open={open}
                handleClose={handleClose}
                successCallback={successCallback}
            />
        </>
    );
}

export default MyCart;