import React from 'react';
import { Col } from 'antd';
import { ItemCard } from '@components';
import { LIMITS } from '@constants';

const Loader = () => {
    return (
        <>
            {new Array(LIMITS.PAGE_SIZE).fill(0).map((_, key) => (
                <Col key={key} xs={12} md={8} lg={6}>
                    <ItemCard loading={true} />
                </Col>
            ))}
        </>
    );
}

export default Loader;