import React, { useState } from 'react';
import {
    Image,
    ImageWrapper,
    CodeBox,
    ActionsWrapper,
    ActionButton,
    ImageLoader,
} from '../styles';
import {
    HeartOutlined,
    ShoppingCartOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_ROUTE } from '@router';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAuthModal } from '@redux/auth/authSlice';
import { message, notification } from 'antd';
import {
    AddToCartDescription,
    AddToFavouriteDescription,
} from '@components';
import { api } from '@api';
import { localStorage } from '@utils';
import { saveCartCount, saveWishListCount } from '@redux/auth/authSlice';
import { PRIVATE_ROUTE } from '@router/route.constants';
import { SOLD_SK } from '@constants';

const CardMedia = ({
    product_id,
    auction_id,
    new_id,
    item_code,
    image,
    title,
    price,
    loading = false,
    item_type = 'product',
    product_status,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => state.auth.token);
    const [addingToCart, setAddingToCart] = useState(false);
    const [addingToWishlist, setAddingToWishlist] = useState(false);
    const { cartCount, wishListCount } = useSelector(state => state.auth);

    const handleClicked = () => {
        if (item_type === 'auction') {
            navigate(`/${PUBLIC_ROUTE.AUCTION_DETAILS}/${auction_id}`);
        }
        else if (item_type === 'news') {
            navigate(`/${PUBLIC_ROUTE.NEW_DETAILS}/${new_id}`);
        }
        else {
            navigate(`/${PUBLIC_ROUTE.PRODUCT_DETAILS}/${product_id}`);
        }
    }

    const addToCart = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!isLoggedIn) {
            dispatch(toggleAuthModal());
            return;
        }

        if (!addingToCart) {
            setAddingToCart(true);
            api
                .addToCart({
                    productId: product_id,
                    quantity: 1,
                })
                .then(() => {
                    setAddingToCart(false);
                    openNotification();

                    // increase cart count in localstorage
                    localStorage.setCartCount(cartCount + 1);
                    // increase cart count in redux
                    dispatch(saveCartCount(cartCount + 1));
                })
                .catch(error => {
                    setAddingToCart(false);
                    message.error('Add to cart Failed!');
                });
        }
    }

    const addToWishlist = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!isLoggedIn) {
            dispatch(toggleAuthModal());
            return;
        }
        if (!addingToWishlist) {
            setAddingToWishlist(true);
            api
                .addToWishlist({
                    productId: product_id,
                    quantity: 1,
                })
                .then(() => {
                    setAddingToWishlist(false);
                    openWishlistNotification();

                    // increase cart count in localstorage
                    localStorage.setWishListCount(wishListCount + 1);
                    // increase cart count in redux
                    dispatch(saveWishListCount(wishListCount + 1));
                })
                .catch(error => {
                    setAddingToWishlist(false);
                    message.error('Add to wishlist Failed!');
                });
        }
    }

    const viewCart = () => {
        navigate(`/${PRIVATE_ROUTE.MY_CART}`);
    }

    const openNotification = () => {
        notification.open({
            message: 'Add to cart!',
            description: (
                <AddToCartDescription
                    image={image}
                    title={title}
                    price={price}
                    viewCart={viewCart}
                />
            ),
            placement: 'topRight',
            className: 'add-to-cart-alert',
            duration: 3,
        });
    };

    const openWishlistNotification = () => {
        notification.open({
            message: 'Add to wishlist!',
            description: (
                <AddToFavouriteDescription
                    image={image}
                    title={title}
                />
            ),
            placement: 'topRight',
            className: 'add-to-cart-alert',
            duration: 2,
        });
    };

    if (loading) {
        return (
            <ImageLoader item_type={item_type} />
        );
    }

    return (
        <ImageWrapper onClick={handleClicked}>
            {item_type === 'product' &&
                <CodeBox>
                    {item_code}
                </CodeBox>
            }
            <Image
                src={`data:image/png;base64,${image}`}
                item_type={item_type}
            />
            {item_type === 'product' &&
                <ActionsWrapper>
                    {product_status !== SOLD_SK &&
                        <ActionButton onClick={addToCart}>
                            <ShoppingCartOutlined />
                        </ActionButton>
                    }
                    <ActionButton onClick={addToWishlist}>
                        <HeartOutlined />
                    </ActionButton>
                </ActionsWrapper>
            }
        </ImageWrapper>
    );
}

export default CardMedia;