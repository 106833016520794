import React, { useEffect, useState } from 'react';
import {
    Modal as AntModal,
    Button as AntButton,
    Col,
    Form,
    Input,
    Row,
    Space,
    message,
    Select,
} from 'antd';
import styled from 'styled-components';
import { Button } from '../ManageAddress.style';
import { api } from '@api';
import {
    getCountryNames,
    getPhoneCodes,
} from '@utils';

const Modal = styled(AntModal)`

.ant-modal-content {
    border-radius: 0;
    padding: 24px 36px;
}

.ant-modal-title {
    padding-bottom: 12px;
    font-size: 18px;
}

.ant-space {
    width: 100%;
    justify-content: right;

    .ant-btn {
        min-width: 130px;
    }

    .ant-btn-default {
        border: 1px solid #707070 !important;
        color: #000 !important;
    }
}

.ant-select-selection-item {
    font-size: 16px;
}

.ant-input-group-addon {
    min-width: 90px;
    padding: 0 !important;
    border-radius: 0 !important;
    border-color: ${props => props.theme.primaryColor};

    .ant-select {
        width: 100%;
        
        .ant-select-selector {
            border: none !important;
        }
    }
}
`;

const deliverTypes = [
    {
        label: 'DHL',
        value: 'DHL',
    },
    {
        label: 'FedEx',
        value: 'FedEx',
    },
    {
        label: 'EMS (Express Mail Service)',
        value: 'EMS (Express Mail Service)',
    },
    {
        label: 'Skynet Worldwide Express',
        value: 'Skynet Worldwide Express',
    },
    {
        label: 'CJ Logistics',
        value: 'CJ Logistics',
    },
];

const CreateEditModal = ({
    isEdit,
    item,
    open,
    handleClose,
    successCallback,
}) => {
    const [loading, setLoading] = useState(false);
    const [phone_code, setPhoneCode] = useState('+66');

    useEffect(() => {
        if (isEdit) {
            getPhoneCodes().forEach(code => {
                if (item.phoneNumber.includes(code.value)) {
                    setPhoneCode(code.value);
                }
            });
        }
        if (!open) {
            setPhoneCode('+66');
        }

        // eslint-disable-next-line
    }, [open]);

    const onFinish = (values) => {
        if (!loading) {
            setLoading(true);
            values = {
                ...values,
                phoneNumber: `${phone_code}${values.phoneNumber}`,
            };

            if (isEdit) {
                values.id = item.id;
            }

            api[isEdit
                ? 'editAddress'
                : 'createAddress'
            ](values)
                .then((response) => {
                    setLoading(false);
                    successCallback(response);
                })
                .catch(error => {
                    setLoading(false);
                    message.error(error);
                })
        }
    }

    const onCancel = () => {
        !loading && handleClose();
    }

    return (
        <Modal
            centered
            destroyOnClose
            closable={false}
            footer={false}
            width={700}
            open={open}
            onCancel={onCancel}
            title={isEdit ? 'Edit Address' : 'Add New Address'}
        >
            <Form
                layout='vertical'
                requiredMark={false}
                initialValues={{
                    ...item,
                    phoneNumber: isEdit ? item.phoneNumber.replaceAll(phone_code, '') : '',
                }}
                onFinish={onFinish}
            >
                <Row gutter={30}>
                    <Col xs={12}>
                        <Form.Item
                            name='delieverType'
                            label='Delivery Type'
                            rules={[{
                                required: true,
                                message: '',
                            }]}
                        >
                            <Select
                                size='large'
                                value={phone_code}
                                options={deliverTypes}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name='email'
                            label='Email'
                            rules={[{
                                required: true,
                                message: '',
                            }]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name='phoneNumber'
                            label='Phone Number'
                            rules={[{
                                required: true,
                                message: '',
                            }]}
                        >
                            <Input
                                size='large'
                                addonBefore={
                                    <Select
                                        showSearch
                                        value={phone_code}
                                        options={getPhoneCodes()}
                                        onChange={(v) => setPhoneCode(v)}
                                    />
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name='city'
                            label='City'
                            rules={[{
                                required: true,
                                message: '',
                            }]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name='country'
                            label='Country'
                            rules={[{
                                required: true,
                                message: '',
                            }]}
                        >
                            <Select
                                showSearch
                                size='large'
                                options={getCountryNames()}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name='postalCode'
                            label='Postal Code'
                            rules={[{
                                required: true,
                                message: '',
                            }]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item
                            name='address'
                            label='Address'
                            rules={[{
                                required: true,
                                message: '',
                            }]}
                        >
                            <Input.TextArea size='large' rows={2} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} />
                    <Col xs={12} />
                    <Col xs={12}>
                        <Space size={'middle'}>
                            <AntButton
                                type='default'
                                size='large'
                                onClick={onCancel}
                            >
                                Cancel
                            </AntButton>
                            <Button
                                danger
                                type='primary'
                                size='large'
                                htmlType='submit'
                                loading={loading}
                            >
                                {isEdit ? 'Save' : 'Add'}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default CreateEditModal;