import React, { lazy, Suspense, useEffect } from 'react';
import {
    Route,
    Navigate,
    BrowserRouter as Router,
    Routes,
    useLocation,
} from 'react-router-dom';
import {
    Loader,
} from '@components';
import {
    Page404,
} from '@pages';
import ErrorBoundary from './ErrorBoundary';
import {
    PUBLIC_ROUTE,
    PRIVATE_ROUTE,
} from '@router/route.constants';
import { Layout } from '@components';
import { useSelector } from 'react-redux';

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('@pages/Home/Home')),
    },
    {
        path: `${PUBLIC_ROUTE.PRODUCT_DETAILS}/:product_id`,
        exact: true,
        component: lazy(() => import('@pages/ProductDetails/ProductDetails')),
    },
    {
        path: PUBLIC_ROUTE.PRIVATE_COLLECTIONS,
        exact: true,
        component: lazy(() => import('@pages/PrivateCollections/PrivateCollections')),
    },
    {
        path: PUBLIC_ROUTE.RARE_FINE,
        exact: true,
        component: lazy(() => import('@pages/RareFine/RareFine')),
    },
    {
        path: `${PUBLIC_ROUTE.PRODUCTS}/:category_id`,
        exact: true,
        component: lazy(() => import('@pages/Products/Products')),
    },
    {
        path: PUBLIC_ROUTE.AUCTIONS,
        exact: true,
        component: lazy(() => import('@pages/Auctions/Auctions')),
    },
    {
        path: `${PUBLIC_ROUTE.AUCTION_DETAILS}/:auction_id`,
        exact: true,
        component: lazy(() => import('@pages/AuctionDetails/AuctionDetails')),
    },
    {
        path: PUBLIC_ROUTE.NEWS,
        exact: true,
        component: lazy(() => import('@pages/News/News')),
    },
    {
        path: `${PUBLIC_ROUTE.NEW_DETAILS}/:new_id`,
        exact: true,
        component: lazy(() => import('@pages/NewDetails/NewDetails')),
    },
    {
        path: PUBLIC_ROUTE.ABOUT_US,
        exact: true,
        component: lazy(() => import('@pages/AboutUs/AboutUs')),
    },
    {
        path: `${PUBLIC_ROUTE.ARTIST_DETAILS}/:artistId`,
        exact: true,
        component: lazy(() => import('@pages/ArtistDetails/ArtistDetails')),
    },
    {
        path: PUBLIC_ROUTE.SEARCH_RESULT,
        exact: true,
        component: lazy(() => import('@pages/SearchResult/SearchResult')),
    },
];

const privateRoutes = [
    {
        path: PRIVATE_ROUTE.MY_CART,
        exact: true,
        component: lazy(() => import('@pages/MyCart/MyCart')),
    },
    {
        path: `${PRIVATE_ROUTE.ACCOUNT_INFO}/*`,
        exact: true,
        component: lazy(() => import('@pages/MyAccount/AccountInfo/AccountInfo')),
    },
    {
        path: PRIVATE_ROUTE.PROCEED_CHECKOUT,
        exact: true,
        component: lazy(() => import('@pages/ProceedCheckout/ProceedCheckout')),
    },
];

const PrivateRoute = ({ children }) => {
    const isLoggedIn = useSelector(state => state.auth.token);
    if (!isLoggedIn) {
        return <Navigate to={PUBLIC_ROUTE.LANDING} />
    }
    return children;
}

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        // "document.documentElement.scrollTo" is the magic for React Router Dom v6
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }, [pathname]);

    return null;
}

const AppRoutes = () => {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Router>
                    <ScrollToTop />
                    <Routes>
                        {publicRoutes.map((route, index) => (
                            <Route
                                key={index}
                                exact={route.exact}
                                path={route.path}
                                element={
                                    <Layout>
                                        <route.component />
                                    </Layout>
                                }
                            />
                        ))}
                        {privateRoutes.map((route, index) => (
                            <Route
                                key={index}
                                exact={route.exact}
                                path={route.path}
                                element={
                                    <PrivateRoute>
                                        <Layout>
                                            <route.component />
                                        </Layout>
                                    </PrivateRoute>
                                }
                            />
                        ))}
                        <Route exact path={PUBLIC_ROUTE.PAGE_404} element={<Page404 />} />
                        <Route path='*' element={<Navigate to={PUBLIC_ROUTE.PAGE_404} />} />
                    </Routes>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}

export default AppRoutes;