const LOCAL_STORAGE_KEYS = {
  token: 'token',
  username: 'username',
  cartCount: 'cartCount',
  wishListCount: 'wishListCount',
};

export function setToken(value) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.token, value);
}

export function clearToken() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
}

export function getToken() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.token);
  } catch (err) {
    clearToken();
    return null;
  }
}

export function setUsername(value) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.username, value);
}

export function clearUsername() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.username);
}

export function getUsername() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.username);
  } catch (err) {
    clearUsername();
    return null;
  }
}

export function setCartCount(value) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.cartCount, value);
}

export function clearCartCount() {
  localStorage.setItem(LOCAL_STORAGE_KEYS.cartCount, 0);
}

export function getCartCount() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.cartCount);
  } catch (err) {
    clearCartCount();
    return null;
  }
}

export function setWishListCount(value) {
  localStorage.setItem(LOCAL_STORAGE_KEYS.wishListCount, value);
}

export function clearWishListCount() {
  localStorage.setItem(LOCAL_STORAGE_KEYS.wishListCount, 0);
}

export function getWishListCount() {
  try {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.wishListCount);
  } catch (err) {
    clearWishListCount();
    return null;
  }
}
