export const PUBLIC_ROUTE = {
  LANDING: '/',
  PAGE_404: '/404',
  PAGE_500: '/500',
  PRODUCT_DETAILS: 'product-details',
  PRIVATE_COLLECTIONS: 'private-collections',
  RARE_FINE: 'rare-fine',
  PRODUCTS: 'products',
  AUCTIONS: 'auctions',
  AUCTION_DETAILS: 'auction-details',
  NEWS: 'news',
  NEW_DETAILS: 'new-details',
  ABOUT_US: 'about-us',
  ARTIST_DETAILS: 'artist-details',
  SEARCH_RESULT: 'search-result',
};

export const PRIVATE_ROUTE = {
  MY_CART: 'my-cart',
  WISHLIST: 'wishlist',
  ACCOUNT_INFO: 'account-info',
  PERSONAL_INFO: 'personal-info',
  MANAGE_ADDRESS: 'manage-address',
  MY_ORDERS: 'my-orders',
  PROCEED_CHECKOUT: 'proceed-checkout',
};
