import React from 'react';
import styled from 'styled-components';
import {
    Menu as AntMenu,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { devices } from '@styles';
import { PRIVATE_ROUTE } from '@router/route.constants';

const Menu = styled(AntMenu)`
border: none !important;

.ant-menu-item {
    height: auto;
    padding: 5px 20px !important;
    margin-bottom: 16px;

    font-size: 22px;
    font-weight: normal;
    color: #000000;

    background: #F9F8EB;
    border-radius: 0;
}

.ant-menu-item-active {
    font-weight: 500;
    background: ${props => props.theme.secondaryColor} !important;
}

.ant-menu-item-selected {
    font-weight: 500;
    background: ${props => props.theme.secondaryColor} !important;

    ::after {
        width: 35px;
        height: 100%;
        background-color: #371A30;

        clip-path: polygon(100% 100%, 100% 0, 0 50%);
    }
}

@media screen and (max-width: ${devices.laptopL}) {

    .ant-menu-item {
        height: auto;
        padding: 0 10px !important;
        margin-bottom: 12px;

        font-size: 16px;
    }

    .ant-menu-item-selected {
    
        ::after {
            width: 30px;
        }
    }
}
`;

const Sider = ({
    items,
    logout,
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    let selectedKey = pathname.replace(`/${PRIVATE_ROUTE.ACCOUNT_INFO}/`, '');

    const handleChange = ({ key }) => {
        if (key === 'logout') {
            logout();
            return;
        }

        navigate(`/${PRIVATE_ROUTE.ACCOUNT_INFO}/${key}`, {
            replace: true,
        });
    }

    return (
        <Menu
            mode='inline'
            selectedKeys={[(selectedKey === PRIVATE_ROUTE.MANAGE_ADDRESS) || (selectedKey.includes('edit')) ? PRIVATE_ROUTE.PERSONAL_INFO : selectedKey]}
            items={items}
            onClick={handleChange}
        />
    );
}

export default Sider;