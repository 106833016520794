import React from 'react';
import styled from 'styled-components';
import {
    Col,
    Row,
    Breadcrumb as AntBreadcrumb,
} from 'antd';
import { devices } from '@styles';
import { HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
background-color: #F1F1F1;
padding: 20px 0;

.ant-breadcrumb {
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    color: #371A30;
}

@media screen and (max-width: ${devices.laptopL}) {
    padding: 16px 0;

    .ant-breadcrumb {
        font-size: 16px;
    }
}

@media screen and (max-width: ${devices.laptop}) {
    padding: 14px 0;

    .ant-breadcrumb {
        font-size: 14px;
    }
}

@media screen and (max-width: ${devices.tablet}) {
    padding: 12px 0;

    .ant-breadcrumb {
        font-size: 12px;
    }
}

@media screen and (max-width: ${devices.mobileM}) {
    padding: 10px 0;

    .ant-breadcrumb {
        font-size: 12px;
    }
}
`;

const Nav = styled.div`
display: flex;
gap: 4px;
align-items: center;
justify-content: center;
cursor: pointer;

color: ${props => props.theme.secondaryColor};
`;

const Breadcrumb = ({
    items
}) => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    }

    const HomeLink = () => (
        <Nav onClick={goToHome}>
            <HomeOutlined />
            <div>Home</div>
        </Nav>
    );

    items = [
        {
            title: <HomeLink />
        },
        ...items
    ];

    return (
        <Container>
            <Row>
                <Col
                    xs={1}
                    sm={2}
                />
                <Col
                    xs={22}
                    sm={20}
                >
                    <AntBreadcrumb
                        separator="/"
                        items={items}
                    />
                </Col>
                <Col
                    xs={1}
                    sm={2}
                />
            </Row>
        </Container>
    );
}

export default Breadcrumb;