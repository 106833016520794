import styled from "styled-components";
import { devices } from '@styles';
import { Space, Button as AntButton } from "antd";
import { new_address_icon } from '@assets';

export const RootContainer = styled.div`
padding: 80px 0;

@media screen and (max-width: ${devices.laptopL}) {
padding: 40px 0;
}

@media screen and (max-width: ${devices.laptop}) {
padding: 30px 0;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 25px 0;
}
`;

export const SpinWrapper = styled.div`
text-align: center;
`;

export const Title = styled.div`
padding-bottom: 40px;
font-size: 20px;
font-weight: 600;
color: #000000;

@media screen and (max-width: ${devices.laptopL}) {
padding-bottom: 20px;
font-size: 18px;
}
`;


export const ItemCard = styled.div`
border: ${props => props.status === 'active' ? '1px solid #f1ae56' : '1px solid #B5B5B5'};
padding: 26px 32px;
position: relative;
cursor: pointer;

.anticon {
    position: absolute;
    top: 16px;
    right: 20px;

    font-size: 20px;
    color: ${props => props.status === 'active' ? '#f1ae56' : 'gray'};
}

@media screen and (max-width: ${devices.laptopL}) {
    padding: 20px 22px;
}
`;

export const ItemTitle = styled.div`
padding-bottom: 12px;
font-size: 18px;
font-weight: 500;
color: #5A5A5A;
text-transform: uppercase;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

export const Text = styled.div`
padding-bottom: 6px;
font-size: 18px;
color: #000;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

export const Button = styled(AntButton).attrs({
  type: 'primary',
  danger: true,
})`
  background-color: #F1AE56 !important;
  font-weight: normal;
  color: #000 !important;
  
  :hover {
  background-color: #F1AE56 !important;
  color: #000 !important;
  }
  `;

export const CancelButton = styled(AntButton).attrs({
  type: 'default',
  size: 'large'
})`
  width: 128px;
  border-color: #F1AE56 !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: normal;
  color: #000 !important;
  text-transform: uppercase;

  :hover {
    border-color: #F1AE56 !important;
    color: #000 !important;
  }
`;

export const ConfirmButton = styled(AntButton).attrs({
  type: 'primary',
  size: 'large',
  danger: true,
})`
  width: 128px;
  background-color: #F1AE56 !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: normal;
  color: #000 !important;
  text-transform: uppercase;

  :hover {
    background-color: #F1AE56 !important;
    color: #000 !important;
  }

  :disabled, :disabled:hover {
    background-color: #F1AE56 !important;
    color: gray !important;
  }
`;

export const AddNewCard = styled.div`
  border: 1px dashed #B5B5B5;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 22px;
  cursor: pointer;

  font-size: 20px;
  font-weight: bold;

@media screen and (max-width: ${devices.laptopL}) {
    font-size: 16px;
}
`;

export const NewIcon = styled.img.attrs({
  alt: 'icon',
  src: new_address_icon,
})`
width: 60px;
height: auto;
`;

export const Actions = styled(Space).attrs({
  size: 'middle'
})`
margin-top: 30px;
width: 100%;
justify-content: flex-end;
`;

export const Box = styled.div`
min-height: 220px;
padding: 20px;
border: 1px solid lightgray;

.ant-row:first-child {
  padding-bottom: 12px;
}

.ant-row {
  padding-bottom: 8px;
}

@media screen and (max-width: ${devices.tablet}) {
  margin-top: 30px;
}
`;

export const TotalText = styled.div`
text-align: right;
font-size: 18px;
font-weight: bold;
color: #000000;
  
  @media screen and (max-width: ${devices.laptopL}) {
  font-size: 16px;
  }
`;

export const TotalWrapper = styled.div`
display: flex;
width: 100%;

div:first-child {
  flex: 1;
  text-align: left;
}
`;