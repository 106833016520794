import styled, { createGlobalStyle } from "styled-components";
import { logo } from "@assets";
import { devices } from "./devices";

const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  font-family: 'Golos Text';
}

body {
  background-color: ${(props) => props.theme.backgroundColor};   
}

.ant-layout {
  background-color: ${(props) => props.theme.backgroundColor};
}

.ant-layout-content {
  min-height: 65vh;
}

.ant-select-selection-item, .ant-select-item-option-content {
  font-size: 18px;
  font-weight: 500 !important;
  color: #371A30;
}

.ant-input:not(.ant-input-status-error) {
  border: 1px solid #686868;
  border-radius: 0;

  :focus {
    box-shadow: none !important;
  }
}

.ant-input-status-error,
.ant-input-affix-wrapper-status-error {
  border-radius: 0;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-status-error) {
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 0;

  :hover {
    border: 1px solid ${(props) => props.theme.primaryColor};
  }

  :focus {
    box-shadow: none !important;
  }
}

.ant-picker {
  border: 1px solid ${(props) => props.theme.primaryColor};

  :hover {
    border: 1px solid ${(props) => props.theme.primaryColor};
  }

  :focus {
    box-shadow: none !important;
  }
}

.ant-select:not(.ant-select-status-error) {
  .ant-select-selector {
    border: 1px solid ${(props) => props.theme.primaryColor} !important;

    :hover {
      border: 1px solid ${(props) => props.theme.primaryColor} !important;
    }
  }
}

.ant-select-selector {
  border-radius: 0 !important;
  height: 52px !important;

  :focus {
    box-shadow: none !important;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: ${devices.laptopL}) {
    height: 40px !important;

    .ant-select-selection-search-input {
      height: 40px !important;
    }

    .ant-select-selection-item {
      font-size: 18px;
    }
  }

  @media screen and (max-width: ${devices.laptopM}) {
    height: 36px !important;

    .ant-select-selection-search-input {
      height: 36px !important;
    }

    .ant-select-selection-item {
      font-size: 16px;
    }
  }

  @media screen and (max-width: ${devices.laptop}) {
    .ant-select-selection-item {
      font-size: 14px;
    }
  }
}

.ant-select .ant-select-arrow {
  font-size: 18px !important;
  color: #000;
}

.ant-select-dropdown {
  width: auto !important;
}

.ant-spin-dot i {
  background-color: ${(props) => props.theme.primaryColor} !important;
}

.ant-btn:disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;

  :hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: none !important;
  }
}

.ant-btn-primary:not(.ant-btn-dangerous) {
background-color: ${(props) => props.theme.primaryColor};
box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

  :hover {
      background-color: ${(props) => props.theme.primaryRgb('86%')};
  }
}

.ant-btn-default {
border-color: ${(props) => props.theme.primaryColor} !important;
color: ${(props) => props.theme.primaryColor} !important;

  :hover {
    border-color: ${(props) => props.theme.primaryColor} !important;
    color: ${(props) => props.theme.primaryColor} !important;
  }
}

.ant-btn {
  border-radius: 0 !important;
}

.ant-badge .ant-badge-count {
  background: #FFBB00;
}

.ant-skeleton-image {
  border-radius: 0 !important;
}

.ant-menu-submenu-popup {
  position: fixed;
  border-radius: 0;
  z-index: 200;

  ul {
    border-radius: 0 !important;
  }

  .ant-menu-item {
    height: 55px !important;
    border-radius: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    background-color: #F9F8EB;
    border: 1px solid #F1AE56;
    padding-right: 0 !important;
    transition: all 0.5s;

    .ant-space:not(.small-item) {
      min-width: 260px;
    }

    .small-item {
      min-width: 200px;
    }

    span {
      display: inherit;
      width: 100%;
      height: 100%;

      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #000000;
    }
    
    .ant-space {
      width: 100%;
      height: 100%;

      .ant-space-item:nth-child(1) {
        flex: 1;
      }
    }

    :hover {
      background-color: ${props => props.theme.secondaryColor} !important;
      border-right: none !important;

      .ant-space-item:nth-child(2) {
        width: 30px;
        height: 53px;
        display: inherit;
        background-color: #371A30;
        clip-path: polygon(100% 100%, 100% 0, 0 50%);  
      }
    }
  }

  .add-to-cart-alert {
    display: none !important;
  }

  @media screen and (max-width: ${devices.laptopL}) {
    
    span {
      font-size: 16px !important;
    }

  }

  @media screen and (max-width: ${devices.laptop}) {
    
    .ant-menu-item {
      height: 45px !important;
    }

    .ant-space:not(.small-item) {
      min-width: 220px !important;
    }

    .small-item {
      min-width: 180px !important;
    }

    span {
      font-size: 14px !important;
    }

    :hover {
      .ant-space-item:nth-child(2) {
        width: 30px !important;
        height: 43px !important;
      }
    }
  }
}

.add-to-cart-alert {
width: 450px;
margin-top: 30px;
padding: 20px 0 !important;
border: 1px solid #000000;
border-radius: 0 !important;

  .ant-notification-notice-icon {
    display: none;
  }

  .ant-notification-notice-message {
    margin: 0 !important;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E1E1E1;

    font-size: 20px !important;
    font-weight: normal;
    color: #371A30;
  }

  .ant-notification-notice-description {
    margin: 0 !important;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.ant-select-selection-item, .ant-select-item-option-content {
  @media screen and (max-width: ${devices.laptopL}) {
    font-size: 16px;
  }

  @media screen and (max-width: ${devices.laptopM}) {
    font-size: 16px;
  }

  @media screen and (max-width: ${devices.laptop}) {
    font-size: 14px;
  }
}

.ant-select-item {
  display: flex;
  align-items: center;

  @media screen and (max-width: ${devices.laptop}) {
    height: 32px;
  }
}

.search-modal {
  margin: 0 !important;
  width: 100vw !important;
  max-width: 100vw !important;
  top: 0;
  padding: 0 !important;

  .ant-modal-content {
    min-height: 100vh;
    padding: 0;
  }
}

.ant-modal-wrap {
  overflow-x: hidden !important;
}
`;

export const Logo = styled.img.attrs({
  alt: 'logo-img',
  src: logo,
})`
object-fit: cover;
cursor: pointer;
`;


export const Title = styled.div`
font-size: 36px;
font-weight: 600;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #371A30;

position: relative;
padding-bottom: 24px;

::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${props => props.size === 'small' ? "60px" : "100px"};
    border-bottom: 6px solid ${(props) => props.theme.secondaryColor};
    content: "";
}

@media screen and (max-width: ${devices.laptopL}) {
font-size: 30px;
padding-bottom: 20px;

  ::after {
    width: ${props => props.size === 'small' ? "50px" : "90px"};
  }
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 28px;
padding-bottom: ${props => props.size === 'small' ? "15px" : "20px"};
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 24px;
padding-bottom: ${props => props.size === 'small' ? "12px" : "16px"};

  ::after {
    width: ${props => props.size === 'small' ? "40px" : "70px"};
  }
}

@media screen and (max-width: ${devices.mobileM}) {
font-size: 20px;
padding-bottom: ${props => props.size === 'small' ? "12px" : "14px"};

  ::after {
    width: ${props => props.size === 'small' ? "30px" : "60px"};
  }
}
`;

export default GlobalStyles;