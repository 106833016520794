import styled from 'styled-components';
import { devices } from '@styles';

export const RootContainer = styled.div`
padding: 30px 0;

@media screen and (max-width: ${devices.laptopL}) {
padding: 25px 0;
}

@media screen and (max-width: ${devices.laptop}) {
padding: 25px 0;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 25px 0;
}

@media screen and (max-width: ${devices.mobileS}) {
padding: 14px 0;
}
`;

export const Text = styled.div`
padding-top: 20px;
padding-bottom: 30px;
font-size: 18px;
font-weight: 500;
color: #000;
text-align: center;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

export const Bottom = styled.div`
padding-top: 60px;
`;