import React from 'react';
import {
    LightText,
    Title,
    Text,
    Container,
    Description,
    RedText,
    TagWrapper,
} from '../styles';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_ROUTE } from '@router';
import { getFormattedNumber } from '@utils';
import { Skeleton } from 'antd';
import { SOLD_SK } from '@constants';

const CardInfo = ({
    product_id,
    auction_id,
    new_id,
    artist_id,
    tag,
    title,
    price,
    date,
    description,
    loading = false,
    rows = 2,
    item_type = 'product',
    product_status,
}) => {
    const navigate = useNavigate();

    const handleClicked = () => {
        if (item_type === 'auction') {
            navigate(`/${PUBLIC_ROUTE.AUCTION_DETAILS}/${auction_id}`);
        }
        else if (item_type === 'news') {
            navigate(`/${PUBLIC_ROUTE.NEW_DETAILS}/${new_id}`);
        }
        else {
            navigate(`/${PUBLIC_ROUTE.PRODUCT_DETAILS}/${product_id}`);
        }
    }

    const handleTagClicked = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!artist_id) {
            return;
        }
        navigate(`/${PUBLIC_ROUTE.ARTIST_DETAILS}/${artist_id}`);
    }

    if (loading) {
        return <Skeleton paragraph={{ rows: item_type === 'news' ? 1 : rows }} />
    }

    return (
        <Container onClick={handleClicked}>
            <TagWrapper>
                {tag &&
                    <LightText onClick={handleTagClicked}>
                        {tag}
                    </LightText>
                }
                {item_type === 'product' && product_status === SOLD_SK &&
                    <RedText>{` Sold Out`}</RedText>
                }
            </TagWrapper>
            <Title>{title}</Title>
            {item_type !== 'product' && <Text>{date}</Text>}
            {description &&
                <Description>
                    {description}
                </Description>
            }
        </Container>
    );
}

export default CardInfo;