import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '@components';
import { Col, Divider, Row, Spin, message } from 'antd';
import {
    Actions,
    AddNewCard,
    Box,
    Button,
    CancelButton,
    ConfirmButton,
    ItemCard,
    ItemTitle,
    NewIcon,
    RootContainer,
    SpinWrapper,
    Text,
    Title,
    TotalText,
    TotalWrapper,
} from './ProceedCheckout.style';
import { api } from '@api';
import CreateEditModal from '../MyAccount/ManageAddress/CreateEditModal/CreateEditModal';
import { CheckCircleFilled } from '@ant-design/icons';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import { PRIVATE_ROUTE } from '@router/route.constants';
import SuccessModal from './SuccessModal/SuccessModal';
import { useDispatch } from 'react-redux';
import { localStorage } from '@utils';
import { clearCartCount } from '@redux/auth/authSlice';

const ProceedCheckout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state: cartData } = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    useEffect(() => {
        getAllAddresses();

        // eslint-disable-next-line
    }, []);

    const getAllAddresses = () => {
        setLoading(true);
        api
            .getAllAddresses()
            .then(response => {
                setLoading(false);
                setData(response);
                response.length > 0 && setSelected(response[0]);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const successCallback = (item) => {
        setData(prev => [...prev, item]);
        setSelected(item);
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleNewClicked = () => {
        setOpen(true);
    }

    const handleConfirmClose = () => {
        setOpenConfirm(false);
    }

    const handlePlaceOrderClicked = () => {
        setOpenConfirm(true);
    }

    const orderSuccessCallback = () => {
        setOpenConfirm(false);
        setOpenSuccess(true);

        // increase cart count in localstorage
        localStorage.clearCartCount();
        // increase cart count in redux
        dispatch(clearCartCount());
    }

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb
                items={[
                    {
                        title: 'Your Cart'
                    },
                    {
                        title: 'Checkout'
                    },
                ]}
            />
        );
    };

    const Loader = () => (
        <SpinWrapper>
            <Spin />
        </SpinWrapper>
    );

    if (!cartData) {
        return <Navigate to={`/${PRIVATE_ROUTE.MY_CART}`} />
    }

    return (
        <>
            {renderBreadcrumb()}
            <RootContainer>
                {loading && <Loader />}
                {!loading &&
                    <Row>
                        <Col xs={1} sm={2} />
                        <Col xs={22} sm={20}>
                            <Title>Choose Your Address</Title>
                            <Row>
                                <Col xs={24} lg={10}>
                                    <Row gutter={[32, 32]}>
                                        {data.map((item, key) => (
                                            <Col key={key} xs={24}>
                                                <ItemCard
                                                    status={selected.id === item.id ? 'active' : 'default'}
                                                    onClick={() => setSelected(item)}
                                                >
                                                    <ItemTitle>
                                                        {item.delieverType}
                                                    </ItemTitle>
                                                    <Text>
                                                        {item.email}
                                                    </Text>
                                                    <Text>
                                                        {item.phoneNumber}
                                                    </Text>
                                                    <Text>
                                                        {item.address}
                                                    </Text>
                                                    <Text>
                                                        {`City: ${item.city}`}
                                                    </Text>
                                                    <Text>
                                                        {`Country: ${item.country}`}
                                                    </Text>
                                                    <Text>
                                                        {`Postal Code: ${item.postalCode}`}
                                                    </Text>
                                                    <CheckCircleFilled />
                                                </ItemCard>
                                            </Col>
                                        ))}
                                        <Col xs={24}>
                                            <AddNewCard onClick={handleNewClicked}>
                                                <NewIcon />
                                                <div>Add New Address</div>
                                                <Button>Add</Button>
                                            </AddNewCard>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={2} />
                                <Col xs={24} lg={12}>
                                    <Box>
                                        <Row>
                                            <Col xs={12} style={{ textAlign: 'left' }}>
                                                <Text><u>ITEMS</u></Text>
                                            </Col>
                                            <Col xs={6} style={{ textAlign: 'center' }}>
                                                <Text><u>QTY</u></Text>
                                            </Col>
                                            <Col xs={6} style={{ textAlign: 'right' }}>
                                                <Text><u>TOTAL</u></Text>
                                            </Col>
                                        </Row>
                                        {cartData.data.map((item, key) => (
                                            <Row key={key}>
                                                <Col xs={12} style={{ textAlign: 'left' }}>
                                                    <Text>{item.productName}</Text>
                                                </Col>
                                                <Col xs={6} style={{ textAlign: 'center' }}>
                                                    <Text>{item.quantity}</Text>
                                                </Col>
                                                <Col xs={6} style={{ textAlign: 'right' }}>
                                                    <Text>{`$${item.price}`}</Text>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Divider />
                                        <TotalWrapper>
                                            <TotalText>
                                                SUBTOTAL
                                            </TotalText>
                                            <TotalText>
                                                {`$${cartData.total.amount}`}
                                            </TotalText>
                                        </TotalWrapper>
                                        <Divider />
                                        <TotalWrapper>
                                            <TotalText>
                                                GRAND TOTAL
                                            </TotalText>
                                            <TotalText>
                                                {`$${cartData.total.amount}`}
                                            </TotalText>
                                        </TotalWrapper>
                                    </Box>
                                    <Actions>
                                        <CancelButton onClick={() => navigate(-1)}>
                                            Cancel
                                        </CancelButton>
                                        <ConfirmButton
                                            disabled={!selected.id}
                                            onClick={handlePlaceOrderClicked}
                                        >
                                            Place Order
                                        </ConfirmButton>
                                    </Actions>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1} sm={2} />
                    </Row>
                }
            </RootContainer >

            <CreateEditModal
                open={open}
                handleClose={handleClose}
                successCallback={successCallback}
            />
            <ConfirmModal
                cartId={cartData.cartId}
                shippingInfoId={selected.id}
                open={openConfirm}
                handleClose={handleConfirmClose}
                successCallback={orderSuccessCallback}
            />
            <SuccessModal
                open={openSuccess}
                handleClose={() => setOpenSuccess(false)}
            />
        </>
    );
}

export default ProceedCheckout;