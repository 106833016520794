import React from 'react';
import Section from '../Section/Section';
import {
  GrayTextSmall,
  ShippingInfoContainer,
  VerticalSteps,
} from '../styles';
import {
  QuestionCircleOutlined,
} from '@ant-design/icons';

const items = [
  {
    icon: <QuestionCircleOutlined />,
    title: 'Apr 2 ( Order Placed )',
    description: 'After you place your order, uddiyanaart will take 1-2 business days to prepare it for shipment.',
  },
  {
    icon: <QuestionCircleOutlined />,
    title: 'Apr 3-4 ( Order Ships )',
    description: 'uddiyanaart puts your order in the mail.',
  },
  {
    icon: <QuestionCircleOutlined />,
    title: 'Apr 10-11 ( Delivered! )',
    description: 'Estimated to arrive at your doorstep Apr 10-11!',
  },
];

const ShippingInfo = () => {
  
  const content = (
    <ShippingInfoContainer>
      <GrayTextSmall>
        Arrives by Apr 10-11 if you order today.
      </GrayTextSmall>
      <VerticalSteps
        items={items}
        direction="vertical"
        current={-1}
      />
    </ShippingInfoContainer>
  );

  return (
    <Section
      title={'International Shipping'}
      content={content}
    />
  );
}

export default ShippingInfo;