import Request from './Request';

class Api {
  constructor() {
    this.request = new Request();
  }

  /**
   * @param {function} sessionExpiredAction - A redux action which will be dispatch if session is expired
   */
  init({ sessionExpiredAction }) {
    this.request.init({
      sessionExpiredAction,
    });
  }

  // ================================ APIs =================================

  register(data) {
    return this.request.post('/auth/register/user', null, data);
  }

  getOtp(data) {
    return this.request.post('/otp', null, data);
  }

  login(data) {
    return this.request.post('/auth/login', null, data);
  }

  logout() {
    return this.request.post('/auth/logout', null, null);
  }

  getCategories() {
    return this.request.get('/categories', null, null);
  }

  getSubCategories(categoryId) {
    return this.request.get(`/subcategories/byCategoryId/${categoryId}`, null, null);
  }

  getHomePrivateCollections() {
    return this.request.get('/products/home/private_collection', null, null);
  }

  getHomeRareFine() {
    return this.request.get('/products/home/rare_find', null, null);
  }

  getHomeAuctions() {
    return this.request.get('/auction/homePageAuctions', null, null);
  }

  getLatestNews(count) {
    return this.request.get('/news/latest', { count }, null);
  }

  getProductDetails(id) {
    return this.request.get(`/products/${id}`, null, null);
  }

  getRelatedProducts(productId) {
    return this.request.get('/products/similar/byCategory', { productId }, null);
  }

  getProductsByCategory(data) {
    return this.request.get('/products/byCategory', data, null);
  }

  getAllAuctions(data) {
    return this.request.get('/auction/getAllAuctions', data, null);
  }

  getAuctionDetails(auction_id) {
    return this.request.get(`/auction/${auction_id}`, null, null);
  }

  getAllNews(data) {
    return this.request.get('/news/getAllNews', data, null);
  }

  getNewDetails(new_id) {
    return this.request.get(`/news/${new_id}`, null, null);
  }

  addToCart(data) {
    return this.request.post('/cart/add', null, data);
  }

  removeFromCart(cartItemId) {
    return this.request.delete(`/cart/remove/${cartItemId}`, null, null);
  }

  viewCart() {
    return this.request.get('/cart/view', null, null);
  }

  getWishlist() {
    return this.request.get('/wishlist/view', null, null);
  }

  addToWishlist(data) {
    return this.request.post('/wishlist/add', null, data);
  }

  removeFromWishlist(productId) {
    return this.request.delete(`/wishlist/remove/${productId}`, null, null);
  }

  moveToCart(data) {
    return this.request.post('/wishlist/move-to-cart', null, data);
  }

  getArtistDetails(artistId) {
    return this.request.get(`/artist/${artistId}`, null, null);
  }

  getUserInfo() {
    return this.request.get('/auth/view-user-profile', null, null);
  }

  editUserInfo(data) {
    return this.request.post('/auth/profile/edit', null, data);
  }

  getSearchResult(data) {
    return this.request.post('/products/search', null, data);
  }

  getAllAddresses() {
    return this.request.get('/shipping-info', null, null);
  }

  createAddress(data) {
    return this.request.post('/shipping-info/create', null, data);
  }

  editAddress(data) {
    return this.request.put('/shipping-info/edit', null, data);
  }

  deleteAddress(id) {
    return this.request.get(`/shipping-info/${id}`, null, null);
  }

  placeOrder(data) {
    return this.request.post('/orders/place', null, data);
  }

  viewOrders() {
    return this.request.get('/orders/history', null, null);
  }

  filterProducts(data) {
    return this.request.post('/products/filter', null, data);
  }

  getPeriods() {
    return this.request.get('/periods/all', null, null);
  }

  getPrices() {
    return this.request.get('/price-filters/all', null, null);
  }

  /**
   * After a successful login, save session id into
   * request object, which will be send in Headers
   */
  setSessionToken(token) {
    this.request.setSessionToken(token);
  }
}

const api = new Api();

export default api;
