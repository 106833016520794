import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Row,
    Col,
    message,
} from 'antd';
import {
    CardInfo,
    CardMedia,
    Section,
} from '@components';
import Loader from './Loader/Loader';
import { api } from '@api';

const Container = styled.div`
background: transparent linear-gradient(121deg, #FFFFFF 0%, #FEF5F0 47%, #F3FEED 100%) 0% 0% no-repeat padding-box;
`;

export const InfoWrapper = styled.div`
height: 100%;
display: flex;
align-items: center;
`;

const responsive = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 60,
};

const RareFine = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getHomeRareFine();

        // eslint-disable-next-line
    }, []);

    const getHomeRareFine = () => {
        setLoading(true);
        api
            .getHomeRareFine()
            .then((response) => {
                setLoading(false);
                setData(response);
            })
            .catch(error => {
                message.error(error);
            });
    }

    return (
        <Container>
            <Row>
                <Col xs={1} sm={2} />
                <Col xs={22} sm={20}>
                    <Section
                        title='Museum Collection'
                        path={`/rare-fine`}
                    >
                        <Row gutter={[responsive, responsive]}>
                            {loading && <Loader />}
                            {!loading &&
                                data.map((item, key) => (
                                    <>
                                        <Col
                                            key={key}
                                            xs={12}
                                            md={6}
                                        >
                                            <CardMedia
                                                product_id={item.id}
                                                item_code={item.productCode}
                                                image={item.firstImage}
                                                title={item.title}
                                                price={item.price}
                                                product_status={item.productStatus}
                                            />
                                        </Col>
                                        <Col
                                            xs={12}
                                            md={6}
                                        >
                                            <InfoWrapper>
                                                <CardInfo
                                                    product_id={item.id}
                                                    title={item.title}
                                                    price={item.price}
                                                    tag={item.period}
                                                    description={item.description}
                                                    product_status={item.productStatus}
                                                />
                                            </InfoWrapper>
                                        </Col>
                                    </>
                                ))
                            }
                        </Row>
                    </Section>
                </Col>
                <Col xs={1} sm={2} />
            </Row>
        </Container>
    );
}

export default RareFine;