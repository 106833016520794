import React, { useEffect, useState } from 'react';
import {
    ItemCard,
    Section,
    Carousel,
} from '@components';
import Loader from './Loader/Loader';
import { message } from 'antd';
import { api } from '@api';
import moment from 'moment';
import { PUBLIC_ROUTE } from '@router/route.constants';
import styled from 'styled-components';
import { devices } from '@styles';
import { img_empty_auction } from '@assets';

const EmptyCardContainer = styled.div`
cursor: auto !important;
height: auto !important;
position: relative;
`;

const EmptyImage = styled.img`
width: 100%;
height: auto;
object-fit: cover;
aspect-ratio: 1 / 1.05;
`;

const Text = styled.div`
width: 100%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-style: normal;
font-weight: 700;
font-size: 20px;
color: #FFFFFF;
text-align: center;
padding-right: 15px;

@media ${devices.laptop} {
  font-size: 14px;
}

@media ${devices.tablet} {
  font-size: 12px;
}
`;

const EmptyCard = () => {
    return (
        <EmptyCardContainer>
            <EmptyImage src={img_empty_auction} alt='eimg' />
            <Text>Coming Soon</Text>
        </EmptyCardContainer>
    );
}

const Auctions = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getHomeAuctions();

        // eslint-disable-next-line
    }, []);

    const getHomeAuctions = () => {
        setLoading(true);
        api
            .getHomeAuctions()
            .then((list) => {
                setLoading(false);
                // if list is less than 4, show empty design cards
                while (list.length < 4) {
                    list.push(null);
                }
                setData(list);
            })
            .catch(error => {
                message.error(error);
            });
    }

    return (
        <Section
            title='Upcoming Auctions'
            path={`/${PUBLIC_ROUTE.AUCTIONS}`}
        >
            {loading && <Loader />}
            {!loading &&
                <Carousel loading={loading}>
                    {data.map((item, key) => (
                        !item
                            ? <EmptyCard key={key} />
                            : <ItemCard
                                key={key}
                                auction_id={item.id}
                                image={item.image}
                                title={item.title}
                                date={moment(item.auctionHeldDate).format('DD MMMM YYYY hh:mm A')}
                                tag={item.address}
                                item_type='auction'
                            />
                    ))}
                </Carousel>
            }
        </Section>
    );
}

export default Auctions;