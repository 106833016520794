import React from 'react';
import { Button } from '@components';
import {
    Container,
    Content,
    TitleWrapper,
} from './Section.style';
import { Title } from '@styles';
import { useNavigate } from 'react-router-dom';

const Section = ({
    title,
    path,
    showMore = true,
    size = 'normal',
    children,
}) => {
    const navigate = useNavigate();

    const seeMore = () => {
        path && navigate(path);
    }

    return (
        <Container>
            <TitleWrapper>
                <Title size={size}>
                    {title}
                </Title>
                {showMore &&
                    <Button onClick={seeMore}>
                        See More
                    </Button>
                }
            </TitleWrapper>
            <Content>
                {children}
            </Content>
        </Container>
    );
}

export default Section;