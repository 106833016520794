import React, { useEffect, useState } from 'react';
import {
    Modal,
} from '../styles';
import { CloseCircleFilled } from '@ant-design/icons';
import Login from '../Login/Login';
import Register from '../Register/Register';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import OTP from '../OTP/OTP';
import ChangePassword from '../ChangePassword/ChangePassword';
import Success from '../Success/Success';
import { localStorage } from '@utils';
import {
    saveToken,
    saveUsername,
    saveCartCount,
    saveWishListCount,
} from '@redux/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAuthModal } from '@redux/auth/authSlice';
import { api } from '@api';

const AuthModal = () => {
    const dispatch = useDispatch();
    const openAuthModal = useSelector(state => state.auth.openAuthModal);
    const [path, setPath] = useState('login');
    const [otpType, setOtpType] = useState('');
    const [registerData, setRegisterData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !openAuthModal && setPath('login');

    }, [openAuthModal]);

    const handleClose = () => {
        dispatch(toggleAuthModal());
    }

    const successCallback = ({ token, userName, cartCount, wishListCount }) => {
        // save token and info to localstorage
        localStorage.setToken(token);
        localStorage.setUsername(userName);
        localStorage.setCartCount(cartCount);
        localStorage.setWishListCount(wishListCount);

        // save token and info to redux
        dispatch(saveToken(token));
        dispatch(saveUsername(userName));
        dispatch(saveCartCount(cartCount));
        dispatch(saveWishListCount(wishListCount));

        // update token in request obj
        api.setSessionToken(token);

        handleClose();
    }

    const goToLogin = () => {
        setPath('login');
    }

    const goToRegister = () => {
        setPath('register');
    }

    const goToForgotPassword = () => {
        setPath('forgot-password');
    }

    const goToOtp = (otpType) => {
        setPath('otp');
        setOtpType(otpType);
    }

    const goToChangePassword = () => {
        setPath('change-password');
    }

    const goToSuccess = () => {
        setPath('success');
    }

    const onCancel = () => {
        !loading && handleClose();
    }

    return (
        <Modal
            centered
            destroyOnClose
            footer={false}
            width={600}
            open={openAuthModal}
            onCancel={onCancel}
            closeIcon={<CloseCircleFilled />}
        >
            {path === 'login' &&
                <Login
                    loading={loading}
                    setLoading={setLoading}
                    goToRegister={goToRegister}
                    goToForgotPassword={goToForgotPassword}
                    successCallback={successCallback}
                />
            }
            {path === 'register' &&
                <Register
                    loading={loading}
                    setLoading={setLoading}
                    goToLogin={goToLogin}
                    goToOtp={goToOtp}
                    setRegisterData={setRegisterData}
                />
            }
            {path === 'forgot-password' &&
                <ForgotPassword
                    loading={loading}
                    setLoading={setLoading}
                    goToOtp={goToOtp}
                    goToLogin={goToLogin}
                />
            }
            {path === 'otp' &&
                <OTP
                    otpType={otpType}
                    registerData={registerData}
                    loading={loading}
                    setLoading={setLoading}
                    goToChangePassword={goToChangePassword}
                    goToForgotPassword={goToForgotPassword}
                    goToRegister={goToRegister}
                    successCallback={successCallback}
                />
            }
            {path === 'change-password' &&
                <ChangePassword
                    loading={loading}
                    setLoading={setLoading}
                    goToSuccess={goToSuccess}
                />
            }
            {path === 'success' &&
                <Success
                    handleClose={handleClose}
                />
            }
        </Modal>
    );
}

export default AuthModal;