import styled from "styled-components";
import { devices } from '@styles';
import { Space, Button as AntButton } from "antd";
import { new_address_icon } from '@assets';

export const RootContainer = styled.div`
width: 100%;
`;

export const Title = styled.div`
padding-bottom: 40px;
font-size: 20px;
font-weight: 500;
color: #000000;

@media screen and (max-width: ${devices.laptopL}) {
padding-bottom: 20px;
font-size: 18px;
}
`;

export const ItemCard = styled.div`
border: 1px solid #B5B5B5;
border-bottom: none;
padding: 26px 32px;

@media screen and (max-width: ${devices.laptopL}) {
    padding: 20px 22px;
}
`;

export const ItemTitle = styled.div`
padding-bottom: 12px;
font-size: 18px;
font-weight: 500;
color: #5A5A5A;
text-transform: uppercase;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

export const Text = styled.div`
padding-bottom: 6px;
font-size: 18px;
color: #000;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

export const CardActionBar = styled(Space)`
padding: 12px;
gap: 22px !important;
width: 100%;
background-color: #E1E1E1;
text-align: center;

.ant-space-item {
    flex: 0.5;

    :first-child {
        text-align: right;
    }

    :last-child {
        text-align: left;
    }
}

.ant-space {
    cursor: pointer;
    font-size: 18px;
    color: #000000;
}

.anticon {
    font-size: 26px;
}

@media screen and (max-width: ${devices.laptopL}) {
.ant-space {
    font-size: 14px;
}

.anticon {
    font-size: 18px;
}
}
`;

export const Button = styled(AntButton).attrs({
    type: 'primary',
    danger: true,
})`
  background-color: #F1AE56 !important;
  font-weight: normal;
  color: #000 !important;
  
  :hover {
  background-color: #F1AE56 !important;
  color: #000 !important;
  }
  `;

export const AddNewCard = styled.div`
  border: 1px dashed #B5B5B5;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 22px;
  cursor: pointer;

  font-size: 20px;
  font-weight: bold;

@media screen and (max-width: ${devices.laptopL}) {
    font-size: 16px;
}
`;

export const NewIcon = styled.img.attrs({
    alt: 'icon',
    src: new_address_icon,
})`
width: 60px;
height: auto;
`;

export const Loader = styled.div`
width: 100%;
padding-top: 20px;
text-align: center;
`;