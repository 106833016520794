import { Button, Form, Input, Space, message } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { devices } from '@styles';
import api from '../../../api/api';
import { useLocation, useNavigate } from 'react-router-dom';

const RootContainer = styled.div`
width: 100%;

.ant-form-item .ant-form-item-label >label {
min-width: 145px;
font-size: 20px;
font-weight: 500;
color: #000000;

    ::after {
        display: none;
    }

    @media screen and (max-width: ${devices.laptopL}) {
        min-width: 125px;
        font-size: 16px;
    }
}

.ant-space {
    width: 100%;
    justify-content: center;

    .ant-btn {
        min-width: 78px;
    }
}
`;

const EditPersonalInfo = () => {
    const navigate = useNavigate();
    const { state: user } = useLocation();
    const [loading, setLoading] = useState(false);
    console.log(user)
    const onFinish = (values) => {
        if (!loading) {
            setLoading(true);
            api
                .editUserInfo(values)
                .then(() => {
                    setLoading(false);
                    message.success('Updated Successfully.');
                    navigate(-1);
                })
                .catch((error) => {
                    setLoading(false);
                    message.error(error);
                });
        }
    }

    const handleCancel = () => {
        !loading && navigate(-1);
    }

    return (
        <RootContainer>
            <Form
                requiredMark={false}
                initialValues={{ ...user }}
                onFinish={onFinish}
            >
                <Form.Item
                    name='firstname'
                    label='First Name'
                    rules={[{
                        required: true,
                        message: '',
                    }]}
                >
                    <Input size='large' />
                </Form.Item>
                <Form.Item
                    name='lastname'
                    label='Last Name'
                    rules={[{
                        required: true,
                        message: '',
                    }]}
                >
                    <Input size='large' />
                </Form.Item>
                <Form.Item
                    name='phoneNumber'
                    label='Phone Number'
                >
                    <Input size='large' />
                </Form.Item>
                <Form.Item
                    name='address'
                    label='Address'
                >
                    <Input.TextArea size='large' />
                </Form.Item>
                <Form.Item>
                    <Space size={'large'}>
                        <Button
                            type='default'
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                        >
                            Save
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </RootContainer>
    );
}

export default EditPersonalInfo;