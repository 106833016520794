import React, { useEffect, useState } from 'react';
import {
    Breadcrumb,
} from '@components';
import {
    RootContainer,
    Title,
    Image,
    DateText,
    Text,
    SmallDateText,
    SmallTitle,
    InfoWrapper,
    RightBox,
    Divider,
    AddressText,
} from './AuctionDetails.style';
import {
    Row,
    Col,
    message,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '@api';
import { Title as RightTitle } from '@styles';
import moment from 'moment';
import RightLoader from './RightLoader/RightLoader';
import LeftLoader from './LeftLoader/LeftLoader';
import { PUBLIC_ROUTE } from '@router/route.constants';

const responsive = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 50,
};

const responsive_sm = {
    xs: 10,
    sm: 20,
    md: 20,
    lg: 30,
    xl: 30,
    xxl: 40,
};

const AuctionDetails = () => {
    const navigate = useNavigate();
    const { auction_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [auction, setAuction] = useState({});
    const [upcomings, setUpcomings] = useState([]);
    const [rightLoading, setRightLoading] = useState(true);

    useEffect(() => {
        getAuctionDetails();
        getUpcomingAuctions();

        // eslint-disable-next-line
    }, [auction_id]);

    const getAuctionDetails = () => {
        setLoading(true);
        api
            .getAuctionDetails(auction_id)
            .then((response) => {
                setLoading(false);
                setAuction(response);
            })
            .catch(error => {
                setLoading(false);
                message.error(error);
            });
    }

    const getUpcomingAuctions = () => {
        setRightLoading(true);
        api
            .getHomeAuctions()
            .then((response) => {
                setRightLoading(false);
                setUpcomings(response.filter(item => item.id !== parseInt(auction_id)));
            })
            .catch(error => {
                message.error(error);
            });
    }

    const handleItemClicked = id => {
        navigate(`/${PUBLIC_ROUTE.AUCTION_DETAILS}/${id}`);
    }

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb
                items={[{
                    title: 'Auction',
                },
                {
                    title: 'Information',
                }]}
            />
        );
    };

    const Details = () => (
        <>
            <Title>{auction.title}</Title>
            <Image src={`data:image/png;base64,${auction.image}`} />
            <AddressText>{auction.address}</AddressText>
            <DateText>
                {moment(auction.auctionHeldDate).format('DD MMMM YYYY hh:mm A')}
            </DateText>
            <Text>
                {auction.description}
            </Text>
        </>
    );

    const Right = () => (
        <>
            <RightTitle size='small'>
                Upcoming Auctions
            </RightTitle>
            <RightBox gutter={responsive_sm}>
                {upcomings.map((item) => (
                    <>
                        <Col xs={12}>
                            <Image
                                size='small'
                                src={`data:image/png;base64,${item.image}`}
                                onClick={() => handleItemClicked(item.id)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InfoWrapper onClick={() => handleItemClicked(item.id)}>
                                <AddressText>
                                    {item.address}
                                </AddressText>
                                <SmallDateText>
                                    {moment(item.auctionHeldDate).format('DD MMMM YYYY hh:mm A')}
                                </SmallDateText>
                                <SmallTitle>
                                    {item.title}
                                </SmallTitle>
                            </InfoWrapper>
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                    </>
                ))}
            </RightBox>
        </>
    )

    return (
        <>
            {renderBreadcrumb()}
            <RootContainer>
                <Row>
                    <Col xs={1} sm={2} />
                    <Col xs={22} sm={20}>
                        <Row gutter={[responsive, responsive]}>
                            <Col xs={24} lg={15} xl={14}>
                                {loading && <LeftLoader />}
                                {!loading && <Details />}
                            </Col>
                            <Col xs={24} lg={9} xl={10}>
                                {rightLoading && <RightLoader responsive={responsive_sm} />}
                                {!rightLoading && <Right />}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1} sm={2} />
                </Row>
            </RootContainer>
        </>
    );
}

export default AuctionDetails;