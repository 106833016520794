import React from 'react';
import styled from 'styled-components';
import { IMG_BANNER } from '@assets';
import { devices } from '@styles';

const Container = styled.div``;

const Image = styled.img.attrs({
  alt: 'banner-img',
  src: IMG_BANNER,
})`
width: 100%;
height: auto;
object-fit: cover;
aspect-ratio: 12 / 5;

@media screen and (max-width: ${devices.mobileS}) {
  /* aspect-ratio: 7 / 5; */
}
`;

const Banner = () => {
  return (
    <Container>
      <Image />
    </Container>
  );
}

export default Banner;