import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
display: flex;
align-items: center;
gap: 16px;
`;

const Image = styled.img.attrs({
    alt: 'img'
})`
width: 80px;
height: 80px;
aspect-ratio: 1;
object-fit: cover;
`;

const Title = styled.div`
flex: 1;
font-size: 18px;
font-weight: normal;
font-style: normal;
color: #000000;
`;

const AddToFavouriteDescription = ({
    image,
    title,
}) => {
    return (
        <Container>
            <Image src={`data:image/png;base64,${image}`} />
            <Title>{title}</Title>
        </Container>
    );
}

export default AddToFavouriteDescription;