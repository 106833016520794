import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Col,
  Space,
  message,
} from 'antd';
import {
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  SearchOutlined,
} from '@assets';
import {
  Row,
  Topbar,
  Mainbar,
  Box,
  AppLogo,
  Menu,
  TopMenu,
  SubMenu,
  SearchInput,
  ShoppingCartIcon,
  FavouriteIcon,
  ActionsWrapper,
  SearchIcon,
  MobileMenuIcon,
  Right,
} from './Appbar.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthModal } from '@components';
import MobileDrawer from './MobileDrawer/MobileDrawer';
import { api } from '@api';
import { toggleAuthModal } from '@redux/auth/authSlice';
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '@router/route.constants';
import SearchModal from './SearchModal/SearchModal';

const SubMenuItem = ({ children, id }) => (
  <Space className={id}>
    <span>{children}</span>
    <span />
  </Space>
);

const categories = [
  {
    key: 'products/3',
    label: "Ancient Beads"
  },
  {
    key: 'products/2',
    label: "Gems & Jewellery Arts"
  },
  {
    key: 'products/7',
    label: "Private Collections"
  },
  {
    key: 'products/4',
    label: "Decorative Arts & Objects"
  },
  {
    key: 'products/1',
    label: "Painting Arts"
  },
  {
    key: 'products/8',
    label: "Handicraft Arts"
  },
  {
    key: 'products/5',
    label: "Handbags & Accessories"
  },
  {
    key: 'products/6',
    label: "Books & Manuscripts"
  },
];

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Appbar = ({
  resetSessionState,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchQuery = useQuery().get('search');
  const { pathname } = useLocation();
  const isLoggedIn = useSelector(state => state.auth.token);
  const { cartCount, wishListCount } = useSelector(state => state.auth);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    setSelectedKeys([pathname.replace('/', '')]);
    setKeyword(searchQuery);

    // eslint-disable-next-line
  }, [pathname]);

  const goToHome = () => {
    navigate('/');
  }

  const logout = (e) => {
    if (!loading) {
      setLoading(true);
      api
        .logout()
        .then(() => {
          message.success('Successfully Logged out!');
          setLoading(false);
          resetSessionState();
          setOpen(false);
        })
        .catch(error => {
          if (!error) {
            setLoading(false);
            message.success('Successfully Logged out!');
            resetSessionState();
            setOpen(false);
          }
          else {
            setLoading(false);
            message.error(error);
          }
        });
    }
  }

  const handleMenuClicked = ({ key }) => {
    setSelectedKeys([key]);

    if (key === 'logout') {
      logout();
      return;
    }

    let isPrivate = key === 'my-account';
    if (!isPrivate) {
      navigate(`/${key}`);
    }
    else {
      !isLoggedIn && dispatch(toggleAuthModal());
    }

    setOpen(false);
  }

  const goToCart = () => {
    if (!isLoggedIn) {
      dispatch(toggleAuthModal());
      return;
    }
    navigate(`/${PRIVATE_ROUTE.MY_CART}`);
  }

  const goToWishlist = () => {
    if (!isLoggedIn) {
      dispatch(toggleAuthModal());
      return;
    }
    navigate(`/${PRIVATE_ROUTE.ACCOUNT_INFO}/${PRIVATE_ROUTE.WISHLIST}`);
  }

  const goToSearch = () => {
    if (!keyword?.trim()) {
      navigate(`${PUBLIC_ROUTE.LANDING}`);
      return;
    }

    navigate(`/${PUBLIC_ROUTE.SEARCH_RESULT}?search=${keyword?.trim()}`);
  }

  const handleInputChange = e => {
    setKeyword(e.target.value);
  }

  const dynamicItems = isLoggedIn ? {
    children: [
      {
        key: `${PRIVATE_ROUTE.ACCOUNT_INFO}/${PRIVATE_ROUTE.PERSONAL_INFO}`,
        label: <SubMenuItem id='small-item'>Account Information</SubMenuItem>,
      },
      {
        key: `${PRIVATE_ROUTE.ACCOUNT_INFO}/${PRIVATE_ROUTE.MANAGE_ADDRESS}`,
        label: <SubMenuItem id='small-item'>Change Address</SubMenuItem>,
      },
      {
        key: `${PRIVATE_ROUTE.ACCOUNT_INFO}/${PRIVATE_ROUTE.MY_ORDERS}`,
        label: <SubMenuItem id='small-item'>My Orders</SubMenuItem>,
      },
      {
        key: 'logout',
        label: (
          <SubMenuItem
            id='small-item'
          >
            Log out
          </SubMenuItem>
        ),
      },
    ]
  } : {};

  const items = [
    {
      key: 'categories',
      label: (
        <SubMenu>
          <>Our Categories</>
          <CaretDownOutlined />
        </SubMenu>
      ),
      children: categories.map(menu => {
        return {
          ...menu,
          label: (
            <SubMenuItem>
              {menu.label}
            </SubMenuItem>
          ),
        }
      }),
    },
    {
      key: 'private-collections',
      label: 'Private Collections',
    },
    {
      key: 'rare-fine',
      label: 'Museum Collection',
    },
    {
      key: 'auctions',
      label: 'Auction',
    },
    {
      key: 'news',
      label: 'News',
    },
    {
      label: 'About Us',
      key: 'about-us',
    },
    {
      key: 'my-account',
      label: (
        <SubMenu>
          {isLoggedIn ? 'My Account' : 'Log in'}
          {isLoggedIn && <CaretDownOutlined id='smallicon' />}
        </SubMenu>
      ),
      ...dynamicItems
    },
  ];

  const renderTopbar = () => (
    <Topbar>
      Discover Timeless Treasures
    </Topbar>
  );

  const SearchBox = (
    <>
      <SearchInput
        size='large'
        suffix={<SearchOutlined />}
        placeholder='Search by keywords'
        value={keyword}
        onChange={handleInputChange}
        onPressEnter={goToSearch}
      />
      <SearchIcon onClick={() => setOpenSearch(true)} />
    </>
  );

  return (
    <>
      {renderTopbar()}
      <Row>
        <Col xs={1} sm={2} />
        <Col xs={22} sm={20}>
          <Mainbar>
            <AppLogo onClick={goToHome} />
            <Right>
              <TopMenu
                selectedKeys={selectedKeys}
                mode='horizontal'
                items={items.slice(-2)}
                onClick={handleMenuClicked}
              />
              <Box>
                <Menu
                  selectedKeys={selectedKeys}
                  mode='horizontal'
                  items={items.slice(0, 5)}
                  onClick={handleMenuClicked}
                />
                <ActionsWrapper>
                  {SearchBox}
                  <Badge
                    showZero
                    count={cartCount}
                    onClick={goToCart}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                  <Badge
                    showZero
                    count={wishListCount}
                    onClick={goToWishlist}
                  >
                    <FavouriteIcon />
                  </Badge>
                </ActionsWrapper>
              </Box>
            </Right>
            <MobileMenuIcon
              onClick={() => setOpen(true)}
            />
          </Mainbar>
        </Col>
        <Col xs={1} sm={2} />
      </Row>
      <MobileDrawer
        navs={items}
        selectedKeys={selectedKeys}
        open={open}
        setOpen={setOpen}
        onClick={handleMenuClicked}
        goToHome={goToHome}
      />
      <AuthModal />
      <SearchModal
        open={openSearch}
        handleClose={() => setOpenSearch(false)}
      />
    </>
  );
}

export default Appbar;