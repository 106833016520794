import styled from "styled-components";
import { Space } from "antd";
import {
    fb_icon,
    messenger_icon,
    viber_icon,
    footer_logo,
} from '@assets';
import { devices } from "@styles";

export const RootContainer = styled.div`
background: ${props => props.theme.primaryColor};
`;

export const Logo = styled.img.attrs({
    alt: 'logo-img',
    src: footer_logo,
})`
width: 100%;
max-width: 300px;
object-fit: cover;
`;

export const LeftBox = styled.div`
padding: 40px;
background: #371A30;
height: 100%;
text-align: center;

@media screen and (max-width: ${devices.laptopL}) {
padding: 30px;
}

@media screen and (max-width: ${devices.laptop}) {
padding: 20px;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 10px;
}
`;

export const Title = styled.div`
padding-top: 50px;
padding-bottom: 30px;

font-size: 24px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: 2;
color: #fff;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 20px;
padding-top: 35px;
padding-bottom: 20px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 18px;
padding-top: 30px;
padding-bottom: 15px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 16px;
padding-top: 30px;
padding-bottom: 18px;
}

@media screen and (max-width: ${devices.mobileM}) {
font-size: 15px;
padding-top: 20px;
padding-bottom: 13px;
}
`;

export const Text = styled.div`
font-size: 16px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: 1.5;
color: #fff;
text-align: left;

.ant-space {
    font-size: 16px;
}

@media screen and (max-width: ${devices.laptopL}) {
font-size: 14px;

.ant-space {
    font-size: 14px;
}
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 13px;

.ant-space {
    font-size: 13px;
}
}
`;

export const RightBox = styled.div`
padding-top: 15px;
`;

export const Container = styled.div`
padding: 0 40px 40px 40px;

@media screen and (max-width: ${devices.laptopL}) {
    padding: 0 30px 30px 30px;
}

@media screen and (max-width: ${devices.tablet}) {
    padding: 0 60px 60px 60px;
}

@media screen and (max-width: ${devices.mobileS}) {
    padding: 0 25px 25px 25px;
}
`;

export const BottomText = styled.div`
padding: 20px 40px;
border-top: 2px solid ${props => props.theme.secondaryColor};

font-size: 16px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: 1.5;
color: #F9F8EB;

@media screen and (max-width: ${devices.laptopL}) {
padding: 20px 30px;
font-size: 12px;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 20px;
}

@media screen and (max-width: ${devices.mobileM}) {
text-align: center;
}
`;

export const U = styled.span`
color: ${props => props.theme.secondaryColor};
cursor: pointer;
`;

export const Socials = styled(Space).attrs({
    size: 'large',
})`
height: 52px;

.ant-space-item {
    display: flex;
}

@media screen and (max-width: ${devices.laptopL}) {
    gap: 16px !important;
}

@media screen and (max-width: ${devices.laptop}) {
    height: auto;
}

@media screen and (max-width: ${devices.tablet}) {
    gap: 8px !important;
}
`;

const Icon = styled.img`
width: 40px;
height: auto;
object-fit: contain;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
width: 35px;
}
`;

export const FbIcon = styled(Icon).attrs({
    alt: 'fb-icon',
    src: fb_icon,
})``;

export const MessengerIcon = styled(Icon).attrs({
    alt: 'messenger-icon',
    src: messenger_icon,
})``;

export const ViberIcon = styled(Icon).attrs({
    alt: 'viber-icon',
    src: viber_icon,
})``;

export const Link = styled.a`
color: #fff;
cursor: pointer;
transition: all 0.5s;

:hover {
    color: ${props => props.theme.secondaryColor};
}
`;

export const Form = styled(Space).attrs({
    size: 'large',
})`
width: 100%;

@media screen and (max-width: ${devices.tablet}) {
    gap: 16px !important;
}

@media screen and (max-width: ${devices.mobileS}) {
    gap: 8px !important;
}

.ant-space-item:nth-child(1) {
    flex: 1;
}

.ant-input {
  height: 52px;
  font-size: 16px !important;

    @media screen and (max-width: ${devices.laptopL}) {
        font-size: 14px !important;
    }

    @media screen and (max-width: ${devices.laptop}) {
        height: 45px;
    }

    @media screen and (max-width: ${devices.mobileS}) {
        height: 37px;
    }
}

.ant-btn {
    height: 52px;
    padding: 15px 50px;
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.secondaryColor};

    color: #000;
    font-size: 18px;

    :hover {
        background-color: ${props => props.theme.secondaryColor} !important;
        color: #000;
    }

    @media screen and (max-width: ${devices.laptopL}) {
        font-size: 16px;
    }

    @media screen and (max-width: ${devices.laptop}) {
        height: 45px;
        font-size: 14px;
    }

    @media screen and (max-width: ${devices.mobileS}) {
        height: 37px;
        padding: 5px 15px;
    }
}
`;