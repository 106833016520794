import React from 'react';
import { Breadcrumb } from '@components';

const Topbar = ({
    title,
}) => {
    return (
        <Breadcrumb items={[{ title }]} />
    );
}

export default Topbar;