import { createSlice } from '@reduxjs/toolkit';
import { Status } from '@api';

const initialState = {
  /**
   * If sessionStatus === Status.DEFAULT  => User is not logged in
   * If sessionStatus === Status.SUCCESS  => User is logged in
   * If sessionStatus === Status.ERROR    => session token of logged in user has expired
   */
  sessionStatus: Status.DEFAULT,
  token: '',
  username: '',
  cartCount: 0,
  wishListCount: 0,
  apiStatus: Status.DEFAULT,
  categories: [],
  openAuthModal: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveToken: (state, action) => {
      state.token = action.payload;
      state.sessionStatus = Status.SUCCESS;
    },
    clearToken: (state, action) => {
      state.token = '';
      state.sessionStatus = Status.DEFAULT;
    },
    saveUsername: (state, action) => {
      state.username = action.payload;
      state.sessionStatus = Status.SUCCESS;
    },
    clearUsername: (state, action) => {
      state.username = '';
    },
    saveCartCount: (state, action) => {
      state.cartCount = action.payload;
    },
    clearCartCount: (state, action) => {
      state.cartCount = 0;
    },
    saveWishListCount: (state, action) => {
      state.wishListCount = action.payload;
    },
    clearWishListCount: (state, action) => {
      state.wishListCount = 0;
    },
    setApiStatus: (state, action) => {
      state.apiStatus = action.payload;
    },
    saveCategories: (state, action) => {
      state.categories = action.payload;
    },
    toggleAuthModal: (state, action) => {
      state.openAuthModal = !state.openAuthModal;
    },
    sessionExpired: (state, action) => {
      state.sessionStatus = Status.ERROR;
    },
  },
});

export const {
  saveToken,
  clearToken,
  saveUsername,
  clearUsername,
  saveCartCount,
  clearCartCount,
  saveWishListCount,
  clearWishListCount,
  setApiStatus,
  saveCategories,
  toggleAuthModal,
  sessionExpired,
} = authSlice.actions;

export default authSlice.reducer;
