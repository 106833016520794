import { CloseOutlined } from '@ant-design/icons';
import { Input, Modal as AntModal, Row, Col } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PUBLIC_ROUTE } from '@router/route.constants';
import { HomeCategories } from '@components/Home/Categories/Categories';
import { devices } from '@styles';

const Modal = styled(AntModal)`

.ant-modal-content {
    border-radius: 0;
    padding-bottom: 60px;
}
`;

const Container = styled.div`
text-align: center;

.anticon {
    position: absolute;
    top: 70px;
    right: 5%;
    font-size: 34px;
    color: #aeaeae;

    @media screen and (max-width: ${devices.mobileS}) {
        top: 15px;
    }
}

.ant-input {
    margin: 60px 0 40px;
    max-width: 70%;
    background-color: rgba(0,0,0,.05) !important;
    border: none !important;
    padding: 12px 12px 12px 24px;
    font-family: Futura,sans-serif;
    color: #787a7c !important;

    @media screen and (max-width: ${devices.mobileS}) {
        max-width: 90%;
    }
}
`;

const Title = styled.div`
padding-bottom: 50px;
font-size: 23px;
font-weight: normal;
color: #171717;
font-family: "New York",Iowan Old Style,Apple Garamond,Baskerville,Times New Roman,Droid Serif,Times,Source Serif Pro,serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;

@media screen and (max-width: ${devices.mobileS}) {
    padding-bottom: 30px;
}
`;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const SearchModal = ({
    open,
    handleClose,
}) => {
    const searchQuery = useQuery().get('search')
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        if (open) {
            setKeyword(searchQuery);
        }

        // eslint-disable-next-line
    }, [open]);

    const goToSearch = () => {
        if (!keyword?.trim()) {
            handleClose();
            navigate(`${PUBLIC_ROUTE.LANDING}`);
            return;
        }
        handleClose();
        navigate(`/${PUBLIC_ROUTE.SEARCH_RESULT}?search=${keyword?.trim()}`);
    }

    return (
        <Modal
            destroyOnClose
            closable={false}
            footer={false}
            open={open}
            className='search-modal'
        >
            <Container>
                <CloseOutlined onClick={handleClose} />
                <Input
                    autoFocus
                    size='large'
                    placeholder='Search...'
                    value={keyword}
                    onChange={e => setKeyword(e.target.value)}
                    onPressEnter={goToSearch}
                />
                <Title>Our Categories</Title>
                <Row>
                    <Col xs={1} lg={2} />
                    <Col xs={22} lg={20}>
                        <HomeCategories
                            mode='search'
                            handleExtraAction={handleClose}
                        />
                    </Col>
                    <Col xs={1} lg={2} />
                </Row>
            </Container>
        </Modal>
    );
}

export default SearchModal;