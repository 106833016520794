import React from 'react';
import { Section } from '@components';
import {
    Row,
    Col,
    Skeleton,
} from 'antd';
import styled from 'styled-components';

export const CardWrapper = styled.div`
display: flex;
flex-direction: column;
grid-gap: 24px;
`;

export const InfoWrapper = styled.div`
height: 100%;
display: flex;
align-items: center;
`;

export const ImageLoader = styled(Skeleton.Image).attrs({
    active: true,
})`
width: 100% !important;
height: 100% !important;
aspect-ratio: ${props => props.size === 'small' ? 47 / 30 : 16 / 9};

    svg {
        display: none;
    }

    .ant-skeleton-image {
        width: 100% !important;
        height: 100% !important;
        aspect-ratio: ${props => props.size === 'small' ? 47 / 30 : 16 / 9};
    }
`;

const Card = () => {
    return (
        <CardWrapper>
            <ImageLoader />
            <Skeleton paragraph={{ rows: 1 }} />
        </CardWrapper>
    );
};

const Loader = ({
    responsive,
}) => {
    return (
        <Section title='Latest News'>
            <Row
                gutter={[responsive, responsive]}
            >
                <Col
                    xs={24}
                    md={12}
                >
                    <Card />
                </Col>
                <Col
                    xs={24}
                    md={12}
                >
                    <Row gutter={[responsive, responsive]}>
                        <Col xs={12}>
                            <ImageLoader size='small' />
                        </Col>
                        <Col xs={12}>
                            <InfoWrapper>
                                <Skeleton paragraph={{ rows: 2 }} />
                            </InfoWrapper>
                        </Col>
                        <Col xs={12}>
                            <ImageLoader size='small' />
                        </Col>
                        <Col xs={12}>
                            <InfoWrapper>
                                <Skeleton paragraph={{ rows: 2 }} />
                            </InfoWrapper>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Section>
    );
}

export default Loader;