/**
 * Constants
 */
const DEV = "DEV";
const PRODUCTION = "PRODUCTION";
const TESTING = "TESTING";

const devBaseURL = "https://api.asiatelismanicera.com";
const productionBaseURL = "https://api.asiatelismanicera.com";
const testingBaseURL = "https://api.asiatelismanicera.com";

const devBasePath = "/api/v1";
const productionBasePath = "/api/v1";
const testingBasePath = "/api/v1";

const devMediaBaseURL = "http://46.101.129.95:8080";
const productionMediaBaseURL = "http://46.101.129.95:8080";
const testingMediaBaseURL = "http://46.101.129.95:8080";

const devApiTimeOverAllowance = 50 * 1000; // 50 seconds timeout
const productionApiTimeOverAllowance = 50 * 1000;
const testingApiTimeOverAllowance = 50 * 1000;

// Change this value to test different environment
export const ENVIRONMENT = DEV;

export const getBuildConfig = () => {
    switch (ENVIRONMENT) {
        case DEV:
            return {
                baseUrl: devBaseURL,
                basePath: devBasePath,
                apiTimeOverAllowance: devApiTimeOverAllowance,
                mediaBaseURL: devMediaBaseURL,
            }
        case PRODUCTION:
            return {
                baseUrl: productionBaseURL,
                basePath: productionBasePath,
                apiTimeOverAllowance: productionApiTimeOverAllowance,
                mediaBaseURL: productionMediaBaseURL,
            }
        case TESTING:
            return {
                baseUrl: testingBaseURL,
                basePath: testingBasePath,
                apiTimeOverAllowance: testingApiTimeOverAllowance,
                mediaBaseURL: testingMediaBaseURL,
            }
        default:
            throw new Error("Unsupported Environment");
    }
}