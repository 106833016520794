import React from 'react';
import {
    SuccessText,
    Title,
    TitleWrapper,
} from '../styles';
import { Logo } from '@styles';
import {
    Button,
} from 'antd';

const Success = ({
    handleClose,
}) => {

    return (
        <>
            <TitleWrapper>
                <Logo />
                <Title>
                    Success!
                </Title>
                <SuccessText>
                    Your password was reset successfully.
                </SuccessText>
            </TitleWrapper>
            <Button
                type='primary'
                size='large'
                onClick={handleClose}
            >
                OK
            </Button>
        </>
    );
}

export default Success;