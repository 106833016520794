export const REVIEW_SK = 'REVIEW';
export const FAIR_SK = 'FAIR';
export const GOOD_SK = 'GOOD';
export const VERY_GOOD_SK = 'VERY_GOOD';
export const LIKE_NEW_SK = 'LIKE_NEW';
export const NEW_SK = 'NEW';
//==========================================
//============== PRODUCT STATUS ============
//==========================================
export const ACTIVE_SK = 'active';
export const PENDING_SK = 'pending';
export const SOLD_SK = 'sold';