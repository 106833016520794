import styled from "styled-components";
import { devices } from '@styles';
import { Row, Divider as AntDivider, Skeleton } from "antd";

export const RootContainer = styled.div`
padding: 80px 0;

@media screen and (max-width: ${devices.laptopL}) {
padding: 40px 0;
}

@media screen and (max-width: ${devices.laptop}) {
padding: 30px 0;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 25px 0;
}

@media screen and (max-width: ${devices.mobileS}) {
padding: 14px 0;
}
`;

export const Title = styled.div`
padding-bottom: 52px;
font-size: 36px;
font-weight: 500;
color: #000;
text-transform: uppercase;
text-align: left;

@media screen and (max-width: ${devices.laptopL}){
    padding-bottom: 30px;
    font-size: 25px;
}

@media screen and (max-width: ${devices.laptop}){
    font-size: 20px;
}

@media screen and (max-width: ${devices.tablet}){
    font-size: 20px;
}

@media screen and (max-width: ${devices.mobileM}){
    font-size: 16px;
}
`;

export const Image = styled.img.attrs({
    alt: 'new-img'
})`
width: 100%;
object-fit: cover;
aspect-ratio: ${props => props.size === 'small' ? 47 / 30 : 16 / 9};
cursor: pointer;
`;

export const DateText = styled.div`
padding: 24px 0;
font-size: 20px;
font-weight: 500;
color: #FFA024;

@media screen and (max-width: ${devices.laptopL}){
    padding: 20px 0;
    font-size: 16px;
}

@media screen and (max-width: ${devices.laptop}){
    padding: 18px 0;
    font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}){
    font-size: 14px;
}

@media screen and (max-width: ${devices.mobileM}){
    font-size: 13px;
}
`;

export const Text = styled.div`
font-size: 20px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: 2;
white-space: pre-wrap;

@media screen and (max-width: ${devices.laptopL}) {
  font-size: 18px;
}

@media screen and (max-width: ${devices.laptop}) {
  font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
  font-size: 14px;
}

@media screen and (max-width: ${devices.mobileM}) {
  font-size: 14px;
}
`;

export const InfoWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
grid-gap: 24px;
justify-content: center;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
grid-gap: 16px;
}

@media screen and (max-width: ${devices.laptop}) {
grid-gap: 8px;
}
`;

export const SmallDateText = styled.div`
font-size: 20px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #FFA024;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 18px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 15px;
}
`;

export const SmallTitle = styled.div`
font-size: 24px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
line-height: 1.5;
color: #000000;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 20px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 18px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 17px;
}
`;

export const RightBox = styled(Row)`
margin-top: 30px;

.ant-col:last-child {
    display: none !important;
}
`;

export const Divider = styled(AntDivider)`
border-block-start: 1px solid #C4C4C4 !important;
`;

export const AddressText = styled.div`
padding-top: 24px;
font-size: 20px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #8f8f8f;

@media screen and (max-width: ${devices.laptopL}) {
padding-top: 20px;
font-size: 18px;
}

@media screen and (max-width: ${devices.laptop}) {
padding-top: 18px;
font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 15px;
}
`;

export const ImageLoader = styled(Skeleton.Image).attrs({
    active: true,
})`
width: 100% !important;
height: 100% !important;
aspect-ratio: ${props => props.size === 'small' ? 47 / 30 : 16 / 9};

    svg {
        display: none;
    }

    .ant-skeleton-image {
        width: 100% !important;
        height: 100% !important;
        aspect-ratio: ${props => props.size === 'small' ? 47 / 30 : 16 / 9};
    }
`;

export const CardWrapper = styled.div`
display: flex;
flex-direction: column;
grid-gap: 24px;
`;