import React from 'react';
import CardMedia from '../CardMedia/CardMedia';
import CardInfo from '../CardInfo/CardInfo';
import { Container } from '../styles';

const ItemCard = ({
    product_id,
    auction_id,
    new_id,
    artist_id,
    item_code,
    image,
    title,
    price,
    date,
    tag,
    loading = false,
    item_type = 'product',
    product_status,
}) => {

    return (
        <Container>
            <CardMedia
                product_id={product_id}
                auction_id={auction_id}
                new_id={new_id}
                item_code={item_code}
                image={image}
                loading={loading}
                item_type={item_type}
                title={title}
                price={price}
                product_status={product_status}
            />
            <CardInfo
                product_id={product_id}
                auction_id={auction_id}
                new_id={new_id}
                artist_id={artist_id}
                title={title}
                price={price}
                date={date}
                tag={tag}
                loading={loading}
                item_type={item_type}
                product_status={product_status}
            />
        </Container>
    );
}

export default ItemCard;