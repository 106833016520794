import React, { useState } from 'react';
import { Button, message, notification } from 'antd';
import {
    InfoBox,
    LightText,
    BoldText,
    GrayText,
    RedText,
} from '../styles';
import { getFormattedNumber } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAuthModal } from '@redux/auth/authSlice';
import { api } from '@api';
import {
    AddToCartDescription,
    AddToFavouriteDescription,
} from '@components';
import { localStorage } from '@utils';
import { saveCartCount, saveWishListCount } from '@redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@router/route.constants';
import { SOLD_SK } from '@constants';

const ProductInfo = ({
    product_code,
    price,
    short_description,
    sub_category,
    product_id,
    image,
    title,
    product_status,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => state.auth.token);
    const [addingToCart, setAddingToCart] = useState(false);
    const [addingToWishlist, setAddingToWishlist] = useState(false);
    const { cartCount, wishListCount } = useSelector(state => state.auth);

    const addToCart = () => {
        if (!isLoggedIn) {
            dispatch(toggleAuthModal());
            return;
        }

        if (!addingToCart) {
            setAddingToCart(true);
            api
                .addToCart({
                    productId: product_id,
                    quantity: 1,
                })
                .then(() => {
                    setAddingToCart(false);
                    openNotification();

                    // increase cart count in localstorage
                    localStorage.setCartCount(cartCount + 1);
                    // increase cart count in redux
                    dispatch(saveCartCount(cartCount + 1));
                })
                .catch(error => {
                    setAddingToCart(false);
                    message.error('Add to cart Failed!');
                });
        }
    }

    const viewCart = () => {
        navigate(`/${PRIVATE_ROUTE.MY_CART}`);
    }

    const openNotification = () => {
        notification.open({
            message: 'Add to cart!',
            description: (
                <AddToCartDescription
                    image={image}
                    title={title}
                    price={price}
                    viewCart={viewCart}
                />
            ),
            placement: 'topRight',
            className: 'add-to-cart-alert',
            duration: 3,
        });
    };

    const addToWishlist = () => {
        if (!isLoggedIn) {
            dispatch(toggleAuthModal());
            return;
        }

        if (!addingToWishlist) {
            setAddingToWishlist(true);
            api
                .addToWishlist({
                    productId: product_id,
                    quantity: 1,
                })
                .then(() => {
                    setAddingToWishlist(false);
                    openWishlistNotification();

                    // increase cart count in localstorage
                    localStorage.setWishListCount(wishListCount + 1);
                    // increase cart count in redux
                    dispatch(saveWishListCount(wishListCount + 1));
                })
                .catch(error => {
                    setAddingToWishlist(false);
                    message.error('Add to wishlist Failed!');
                });
        }
    }

    const openWishlistNotification = () => {
        notification.open({
            message: 'Add to wishlist!',
            description: (
                <AddToFavouriteDescription
                    image={image}
                    title={title}
                />
            ),
            placement: 'topRight',
            className: 'add-to-cart-alert',
            duration: 2,
        });
    };

    return (
        <InfoBox >
            {product_status === SOLD_SK &&
                <div style={{ display: 'flex' }}>
                    <RedText>Sold out</RedText>
                </div>
            }
            <LightText>{`Product Code - ${product_code}`}</LightText>
             <BoldText>{`Thai Baht: ${price} / USD ${(price / 34.81).toFixed(2)}`}</BoldText>
           	  <GrayText>
                {short_description}
            </GrayText>
            <GrayText style={{ fontWeight: '600' }}>
                {sub_category}
            </GrayText>
            {product_status !== SOLD_SK &&
                <Button
                    type='primary'
                    size='large'
                    onClick={addToCart}
                >
                    Add To Cart
                </Button>
            }
            <Button
                type='default'
                size='large'
                onClick={addToWishlist}
            >
                Wish List
            </Button>
        </InfoBox>
    );
}

export default ProductInfo;