import React, { useEffect, useState } from 'react';
import { ProductList } from '@components';
import { message } from 'antd';
import { api } from '@api';
import { LIMITS } from '@constants';

const {
    DEFAULT_PAGE,
    PAGE_SIZE,
} = LIMITS;

const PrivateCollections = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getProductsByCategory(page);

        // eslint-disable-next-line
    }, [page]);

    const getProductsByCategory = (page) => {
        setLoading(true);
        api
            .getProductsByCategory({
                categoryId: 7,
                page,
                size: PAGE_SIZE,
            })
            .then(({ content, totalElements }) => {
                setLoading(false);
                setData(content);
                setTotal(totalElements);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const handlePageChange = page => {
        setPage(page);
    }

    return (
        <ProductList
            title='Private Collections'
            data={data}
            loading={loading}
            pagination={{
                current: page,
                pageSize: PAGE_SIZE,
                total,
                onChange: handlePageChange,
            }}
        />
    );
}

export default PrivateCollections;