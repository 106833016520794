import React from 'react';
import {
  BottomText,
  Container,
  LeftBox,
  RightBox,
  RootContainer,
  Text,
  Title,
  U,
  Socials,
  FbIcon,
  MessengerIcon,
  ViberIcon,
  Link,
  Form,
  Logo,
} from './Footer.style';
import {
  Row,
  Col,
  Space,
  Input,
  Button,
} from 'antd';

const Footer = () => {

  const FeedbackForm = () => (
    <Form>
      <Input
        size='large'
        placeholder='Enter your feedback'
      />
      <Button
        type='primary'
        size='large'
      >
        Submit
      </Button>
    </Form>
  );

  const renderLeft = () => (
    <LeftBox>
      <Logo />
      <Text>
        We are dedicated to preserving and promoting the beauty and value of Burmese antiques, and we look forward to serving our customers with the utmost professionalism and integrity.
      </Text>
    </LeftBox>
  );

  const renderRight = () => (
    <RightBox>
      <Container>
        <Row>
          <Col
            xs={24}
            xl={6}
            xxl={8}
          >
            <Row>
              <Col xs={24} sm={12} xl={24}>
                <Title>
                  Follow Us
                </Title>
                <Socials>
                  <FbIcon />
                  <ViberIcon />
                  <MessengerIcon />
                </Socials>
              </Col>
              <Col xs={24} sm={12} xl={24}>
                <Title>
                  Information
                </Title>
                <Text>
                  <Space direction='vertical'>
                    <Link>Ordering & Payments</Link>
                    <Link>Shipping Policy</Link>
                    <Link>Guarantee & Returns</Link>
                    <Link>Privacy & Security</Link>
                    <Link>Terms & Conditions</Link>
                  </Space>
                </Text>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            xl={17}
            xxl={12}
          >
            <Title>
              Got Suggestions? We would love to hear your feedback.
            </Title>
            <FeedbackForm />
            <Row gutter={10}>
              <Col xs={24} sm={12} xl={12}>
                <Title>
                  Address
                </Title>
                <Text>
                  SIRI Square Silom <br />
                  No.1041/2, Soi 23 Silom Road, Silom Subdistrict, Bangrak, Bangkok 10500, Thailand.
                </Text>
                <br />
                <Text>
                  5 Avenue Cumba, 64210 Bidart, France
                </Text>
              </Col>
              <Col xs={0} xl={4} />
              <Col xs={24} sm={12} xl={6}>
                <Title>
                  Contact Us
                </Title>
                <Space direction='vertical' size={'small'}>
                  <Text>+66 080 848 1232</Text>
                  <Text>info@asiatelismanicera.com</Text>
                  <Text>contact@asiatelismanicera.com</Text>
                  <Text>
                    <Link href='http://www.asiatelismanicera.com' target='_blank' rel='noreferrer noopener'>
                      www.asiatelismanicera.com
                    </Link>
                  </Text>
                  <Text>
                    <Link href='https://www.telismanicera.com' target='_blank' rel='noreferrer noopener'>
                      www.telismanicera.com
                    </Link>
                  </Text>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            xl={1}
            xxl={4}
          />
        </Row>
      </Container>
      <BottomText>
        Copyright © 2023. All Rights Reserved. Design By <U>Telismanic Era</U>.
      </BottomText>
    </RightBox>
  );

  return (
    <RootContainer>
      <Row>
        <Col
          xs={0}
          md={2}
          xl={2}
        />
        <Col
          xs={0}
          md={8}
          xl={5}
        >
          {renderLeft()}
        </Col>
        <Col
          xs={24}
          md={14}
          xl={17}
        >
          {renderRight()}
        </Col>
      </Row>
    </RootContainer>
  );
}

export default Footer;