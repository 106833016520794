import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { devices } from '@styles';
import { Title } from '../AccountInfo/AccountInfo.style';
import { getFormattedNumber } from '@utils';
import { DeleteOutlined } from '@assets';
import { Button as AntButton, Spin, message, notification } from 'antd';
import { Pagination } from '@components';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import { AddToCartDescription } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { localStorage } from '@utils';
import { saveCartCount, saveWishListCount } from '@redux/auth/authSlice';
import { PRIVATE_ROUTE } from '@router/route.constants';
import { api } from '@api';
import { ACTIVE_SK } from '@constants';

const RootContainer = styled.div`
width: 100%;
`;

const Item = styled.div`
padding: 32px;
margin-top: 16px;
width: 100%;
display: flex;
align-items: center;
gap: 24px;
border: 1px solid #B5B5B5;

@media screen and (max-width: ${devices.laptopL}) {
  padding: 20px;
  margin-top: 12px;
  gap: 20px;
}
`;

const Image = styled.img.attrs({
  alt: 'img'
})`
width: 80px;
height: 80px;
object-fit: cover;
`;


export const TitleWrappr = styled.div`
flex: 0.6;
display: flex;
flex-direction: column;
gap: 8px;
`;

export const RedText = styled.div`
padding: 3px 6px;

background-color: red;
font-size: 14px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #fff;
text-transform: uppercase;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 13px;
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 13px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 12px;
}
`;

const ItemTitle = styled.div`
font-size: 18px;
font-weight: normal;
color: #5A5A5A;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

const PriceText = styled.div`
flex: 0.4;
font-size: 18px;
font-weight: bold;
color: #000000;
text-align: center;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

const DeleteIcon = styled(DeleteOutlined)`
width: 26px;
margin-right: 20px;
cursor: pointer;

@media screen and (max-width: ${devices.laptopL}) {
width: 20px;
}
`;

const Button = styled(AntButton).attrs({
  type: 'primary',
  size: 'large',
  danger: true,
})`
background-color: #F1AE56 !important;
font-weight: normal;
color: #000 !important;

:hover {
  background-color: #F1AE56 !important;
  color: #000 !important;
}
`;

const Bottom = styled.div`
padding-top: 60px;

@media screen and (max-width: ${devices.laptopL}) {
padding-top: 30px;
}
`;

const SpinWrapper = styled.div`
padding-top: 25px;
text-align: center;
`;

const Wishlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [addingToCart, setAddingToCart] = useState(false);
  const { cartCount, wishListCount } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getWishlist();

    // eslint-disable-next-line
  }, []);

  const getWishlist = () => {
    setLoading(true);
    api
      .getWishlist()
      .then(({ wishLists }) => {
        setLoading(false);
        setData(wishLists);
      })
      .catch(error => {
        setLoading(false);
        message.error(error);
      });
  }

  const handleDeleteClicked = (item) => {
    setSelected(item);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  }

  const addToCart = (item) => {
    if (!addingToCart) {
      setAddingToCart(true);
      api
        .moveToCart({
          productId: item.productId,
          // quantity: 1,
        })
        .then(() => {
          setAddingToCart(false);
          openNotification(item);
          successCallback(item.productId);

          // increase cart count in localstorage
          localStorage.setCartCount(cartCount + 1);
          // increase cart count in redux
          dispatch(saveCartCount(cartCount + 1));

        })
        .catch(error => {
          setAddingToCart(false);
          message.error('Add to cart Failed!');
        });
    }
  }

  const viewCart = () => {
    navigate(`/${PRIVATE_ROUTE.MY_CART}`);
  }

  const openNotification = (item) => {
    notification.open({
      message: 'Add to cart!',
      description: (
        <AddToCartDescription
          image={item.productImage}
          title={item.productTitle}
          price={item.productPrice}
          viewCart={viewCart}
        />
      ),
      placement: 'topRight',
      className: 'add-to-cart-alert',
      duration: 3,
    });
  };

  const successCallback = (productId) => {
    setData(prev => prev.filter(item => item.productId !== productId));
    setOpen(false);
    setSelected(null);

    // decrease cart count from localstorage
    localStorage.setWishListCount(wishListCount - 1);
    // decrease cart count from redux
    dispatch(saveWishListCount(wishListCount - 1));
  }

  const Loader = () => (
    <SpinWrapper>
      <Spin />
    </SpinWrapper>
  );

  const EmptyBox = () => (
    <SpinWrapper>No Item.</SpinWrapper>
  );

  return (
    <>
      <RootContainer>
        <Title>Wishlist</Title>
        {loading && <Loader />}
        {!loading && data.length === 0 && <EmptyBox />}
        {!loading && (
          <>
            {data.map((item, key) => (
              <Item key={key}>
                <Image src={`data:image/png;base64,${item.productImage}`} />
                <TitleWrappr>
                  {item.productStatus !== ACTIVE_SK &&
                    <div style={{ display: 'flex' }}>
                      <RedText>Sold out</RedText>
                    </div>
                  }
                  <ItemTitle>
                    {item.productTitle}
                  </ItemTitle>
                </TitleWrappr>
                <PriceText>
                  {`$${item.productPrice}`}
                </PriceText>
                <DeleteIcon onClick={() => handleDeleteClicked(item)} />
                <Button onClick={() => addToCart(item)}>
                  Add To Cart
                </Button>
              </Item>
            ))}
            {/* <Bottom>
              <Pagination
                size='small'
                current={1}
                pageSize={10}
                total={10}
                onChange={() => { }}
              />
            </Bottom> */}
          </>
        )}
      </RootContainer>
      <ConfirmModal
        item={selected}
        open={open}
        handleClose={handleClose}
        successCallback={successCallback}
      />
    </>
  );
}

export default Wishlist;