import styled from 'styled-components';
import { devices } from '@styles';
import { Image as AntImage, } from 'antd';

export const RootContainer = styled.div`
padding: 30px 0;

@media screen and (max-width: ${devices.laptopL}) {
padding: 25px 0;
}

@media screen and (max-width: ${devices.laptop}) {
padding: 25px 0;
}

@media screen and (max-width: ${devices.tablet}) {
padding: 25px 0;
}

@media screen and (max-width: ${devices.mobileS}) {
padding: 14px 0;
}
`;

export const Text = styled.div`
padding-top: 20px;
padding-bottom: 30px;
font-size: 18px;
font-weight: 500;
color: #000;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

export const Paragraph = styled.div`
padding-top: 20px;
padding-bottom: 30px;
font-size: 18px;
font-weight: normal;
color: #000;
text-align: justify;
line-height: 1.5;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

export const Image = styled(AntImage).attrs({
    alt: 'img',
    preview: {
        mask: false,
    }
})`
width: 100%;
max-width: 450px;
margin-bottom: 24px;
object-fit: cover;
aspect-ratio: 1;
cursor: pointer;
`;

export const SpinWrapper = styled.div`
text-align: center;
`;

export const Title = styled.div`
font-size: 20px;
font-weight: 600;
font-style: normal;
letter-spacing: normal;
line-height: normal;
color: #371A30;

position: relative;
padding-bottom: 14px;

::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    border-bottom: 4px solid ${(props) => props.theme.secondaryColor};
    content: "";
}

@media screen and (max-width: ${devices.laptopL}) {
font-size: 18px;

  ::after {
    width: 40px;
  }
}

@media screen and (max-width: ${devices.laptop}) {
font-size: 16px;
}

@media screen and (max-width: ${devices.tablet}) {
font-size: 16px;
padding-bottom: 12px;

  ::after {
    width: 40px;
  }
}

@media screen and (max-width: ${devices.mobileM}) {
font-size: 16px;

  ::after {
    width: 30px;
  }
}
`;