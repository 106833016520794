import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { devices } from '@styles';
import { Title } from '../AccountInfo/AccountInfo.style';
import { getFormattedNumber } from '@utils';
import { Button as AntButton, Spin, message } from 'antd';
import { Pagination } from '@components';
import { api } from '@api';
import moment from 'moment';
import DetailsModal from './DetailsModal/DetailsModal';

const RootContainer = styled.div`
width: 100%;
`;

const ItemBox = styled.div`
padding: 32px;
margin-top: 16px;
width: 100%;
border: 1px solid #B5B5B5;
display: flex;
flex-direction: column;
gap: 15px;

@media screen and (max-width: ${devices.laptopL}) {
  padding: 20px;
  margin-top: 12px;
  gap: 20px;
}
`;

const ItemTop = styled.div`
display: flex;
align-items: center;
`;

const InfoText = styled.div`
flex: 1;
font-size: 18px;
font-weight: normal;
color: #371A30;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

const Button = styled(AntButton).attrs({
    type: 'primary',
    danger: true,
})`
background-color: #F1AE56 !important;
font-weight: normal;
color: #000 !important;

:hover {
  background-color: #F1AE56 !important;
  color: #000 !important;
}
`;

const Label = styled.span`
color: #F1AE56;
`;

const Item = styled.div`
display: flex;
align-items: center;
gap: 24px;
`;

const TitleWrapper = styled.div`
flex: 0.6;
display: flex;
align-items: center;
gap: 24px;

@media screen and (max-width: ${devices.laptopL}) {
  gap: 20px;
}

@media screen and (max-width: ${devices.tablet}) {
    gap: 14px;
    flex-direction: column;
    align-items: flex-start;
}
`;

const Image = styled.img.attrs({
    alt: 'img'
})`
width: 80px;
height: 80px;
object-fit: cover;
`;

const ItemTitle = styled.div`
font-size: 18px;
font-weight: normal;
color: #5A5A5A;

@media screen and (max-width: ${devices.laptopL}) {
font-size: 16px;
}
`;

const Text = styled(ItemTitle)`
color: #000000;
`;

const Bottom = styled.div`
padding-top: 60px;

@media screen and (max-width: ${devices.laptopL}) {
padding-top: 30px;
}
`;

const SpinWrapper = styled.div`
padding-top: 25px;
text-align: center;
`;

const MyOrders = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        viewOrders();

        // eslint-disable-next-line
    }, []);

    const viewOrders = () => {
        setLoading(true);
        api
            .viewOrders()
            .then((response) => {
                setLoading(false);
                setData(response);
            })
            .catch(error => {
                message.error(error);
            });
    }

    const handleDetailsClicked = (item) => {
        setOpen(true);
        setSelected(item);
    }

    const handleClose = () => {
        setOpen(false);
        setSelected({});
    }

    const Loader = () => (
        <SpinWrapper>
            <Spin />
        </SpinWrapper>
    );

    const EmptyBox = () => (
        <SpinWrapper>You have no order yet.</SpinWrapper>
    );

    return (
        <>
            <RootContainer>
                <Title>My Orders</Title>
                {loading && <Loader />}
                {!loading && data.length === 0 && <EmptyBox />}
                {data.map((item, key) => (
                    <ItemBox key={key}>
                        <ItemTop>
                            <InfoText>
                                <Label>Order Date :</Label> {moment(item.orderDate).format('DD MMM, YYYY hh:mm A')}
                            </InfoText>
                            <Button onClick={() => handleDetailsClicked(item)}>
                                View Details
                            </Button>
                        </ItemTop>
                        {item.cartDTO.cartItems.map((product, key) => (
                            <Item key={key}>
                                <TitleWrapper>
                                    <Image src={`data:image/png;base64,${product.image}`} />
                                    <ItemTitle>
                                        {product.productName}
                                    </ItemTitle>
                                </TitleWrapper>
                                <Text style={{ flex: 0.4 }}>
                                    {`x ${product.quantity}`}
                                </Text>
                                <Text>
                                    {`$${product.price}`}
                                </Text>
                            </Item>
                        ))}
                    </ItemBox>
                ))}
                <Bottom>
                    <Pagination
                        size='small'
                        current={1}
                        pageSize={5}
                        total={5}
                        onChange={() => { }}
                    />
                </Bottom>
            </RootContainer>
            <DetailsModal
                item={selected}
                open={open}
                handleClose={handleClose}
            />
        </>
    );
}

export default MyOrders;