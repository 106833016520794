import React from 'react';
import {
    Carousel,
    ItemCard,
} from '@components';

const Loader = () => {
    return (
        <Carousel loading={true}>
            <ItemCard loading={true} />
            <ItemCard loading={true} />
            <ItemCard loading={true} />
            <ItemCard loading={true} />
        </Carousel>
    );
}

export default Loader;