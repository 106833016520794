import React from 'react';
import { Col, Row } from 'antd';
import {
    Title,
    RootContainer,
    Paragraph,
} from './AboutUs.style';


const AboutUs = () => {
    return (
        <RootContainer>
            <Row>
                <Col xs={1} sm={2} />
                <Col xs={22} sm={20}>
                    <Title>About Us</Title>
                    <Paragraph>
                        Talismanic era Co., Ltd. is a newly established company based in Thailand, founded in October 2022 by a collector and entrepreneur who has been in the antique business for over 20 years. Our main focus is on selling Burmese antiques, including private collections, Ancent beads, gems & jewelry arts, handmade arts, decorative arts & objects, handbags & accessories, and books & manuscripts. We also have a company with the same name in France.
                    </Paragraph>
                    <Paragraph>
                        Talismanic era Co., Ltd. is registered as a limited liability company in Thailand and operates in the Asia and European markets. Our founder has a wealth of experience and expertise in the antique business, and our team consists of highly skilled and knowledgeable professionals who are passionate about antiques.
                    </Paragraph>
                    <Paragraph>
                        We are committed to providing our customers with high-quality products and exceptional customer service. We plan to market our products through social media advertising, email marketing, and participation in trade fairs and exhibitions. Our sales process involves direct sales through our website and physical showroom, as well as partnerships with interior decorators and antique dealers.
                    </Paragraph>
                    <Paragraph>
                        We are dedicated to preserving and promoting the beauty and value of Burmese antiques, and we look forward to serving our customers with the utmost professionalism and integrity.
                    </Paragraph>
                    <br />
                    <br />
                    <Title>Our Mission</Title>
                    <Paragraph>
                        "Our mission at Telismanic era Co., Ltd is to preserve and promote the beauty and cultural significance of Burmese antiques through our high-quality products and exceptional service. We aim to provide our customers with a unique and enriching experience that celebrates the rich heritage of Southeast Asia."
                    </Paragraph>
                    <br />
                    <br />
                    <Title>Philosophy</Title>
                    <Paragraph>
                        At Telismanic era Co., Ltd., we are committed to preserving the cultural heritage of Southeast Asia by providing high-quality antique products and exceptional customer service. We believe in honesty, authenticity, and sustainability in all aspects of our business. We strive to build long-lasting relationships with our customers, partners, and employees based on mutual respect, trust, and integrity.
                    </Paragraph>
                    <br />
                    <br />
                    <Title>Vision</Title>
                    <Paragraph>
                        Our vision is to become the leading provider of Burmese antique products in Asia and Europe, while promoting cultural understanding and social responsibility. We aim to expand our market share, diversify our product portfolio, and enhance our brand reputation through innovation, creativity, and continuous improvement. We aspire to create a legacy of excellence and positive impact on our stakeholders and society.
                    </Paragraph>
                </Col>
            </Row>
        </RootContainer>
    );
}

export default AboutUs;